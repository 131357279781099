import { call, put } from 'redux-saga/effects';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import axios from "axios";

export function* getCsSubComponentData() {
    let { data } = yield call(getCsSubComponentDataApi)
    yield put({
        type: "STORE_CS_SUBCOMPONENTS",
        payload: data
    })
}

async function getCsSubComponentDataApi() {
    console.log("getSubComponentdataCalledApi")

    return true
}


export function* getCsGridDatas(params) {
    let { data } = yield call(getcsGridDataApi, params)
    yield put({
        type: "STORE_CS_GRID_DATA",
        payload: data
    })
}

async function getcsGridDataApi(params) {
    console.log("paramsGetGrid", params)
    if (params.params == "Stations") {
        const data = await API.graphql({ query: queries['listChargingStations'] });
        return data
    }
    if (params.params == "Payments") {
        const data = await API.graphql({ query: queries.listCSPayments });
        return data
    }
    if (params.params == "UserManagement") {
        const data = await API.graphql({ query: queries.listUserDetails });
        return data
    }
    if (params.params == "OCPPTags") {
        // const data = axios.request({
        //     "method": "GET",
        //     "url": "http://ocpp.connectm.ai:8090/connectm/manager/ocppTags",
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //     },
        // })
        const data = axios.get("http://ocpp.connectm.ai:8090/connectm/manager/ocppTags", {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        })
        return data
    }
}

export function* getCsGridColumns() {
    let { data } = yield call(getcsGridColumnApi)
    yield put({
        type: "STORE_CS_GRID_COLUMN",
        payload: data
    })
}

async function getcsGridColumnApi() {
    // const data = await API.graphql({ query: queries.listChargingStationGridColumns });
    return true
}


export function* getcsLocations() {
    let { data } = yield call(getCsLocationsApi)
    yield put({
        type: "STORE_CS_LOCATION_DROPDOWN",
        payload: data
    })
}

async function getCsLocationsApi() {
    // const data = await API.graphql({ query: queries.listLocations });
    return true
}

export function* insertNewSations(params) {
    try {
        yield call(insertNewStationApi, params)
        yield put({
            type: "STORE_INSERT_NEW_STATION",
            payload: true
        })
    } catch (error) {
        yield put({
            type: "STORE_INSERT_NEW_STATION",
            payload: false
        })
    }
}

async function insertNewStationApi(params) {
    const data = await API.graphql({
        query: mutations.createChargingStations, variables: {
            input: params.payload
        }
    });
    return data
}



export function* updateChargingStation(params) {
    try {
        yield call(updateChargingStationApi, params)
        yield put({
            type: "STORE_UPDATE_CHARGING_STATION",
            payload: true
        })
    } catch (error) {
        console.log("UpdateError", error, params)
    }
}

async function updateChargingStationApi(params) {
    const data = await API.graphql({
        query: mutations.updateChargingStations, variables: {
            input: params.payload
        }
    });
    return data
}

export function* getChargingStationForm() {
    let { data } = yield call(getChargingStationFormApi)
    yield put({
        type: "STORE_GET_STATION_ADD_FORM",
        payload: data
    })
}

async function getChargingStationFormApi() {
    const data = await API.graphql({ query: queries.listAddStationForms });
    console.log("getStationAddForm", data)
    return data
}

export function* filterchargingStation(params) {
    let { data } = yield call(filterchargingStationApi, params)
    yield put({
        type: "STORE_FILTER_GRID_DATA",
        payload: data
    })
}





async function filterchargingStationApi(params) {
    return true
}


export function* getCsModuleData() {
    let { data } = yield call(getCsModuleDataApi)
    yield put({
        type: "STORE_GET_CS_MODULE",
        payload: data
    })
}

async function getCsModuleDataApi() {
    const data = await API.graphql({ query: queries.listModules });
    return data
}

export function* getCsModuleDataChange(params) {
    let { data } = yield call(getCsModuleDataChangeApi, params)
    yield put({
        type: "STORE_GET_CS_MODULE_CHANGE",
        payload: data
    })
}

async function getCsModuleDataChangeApi(params) {
    const data = API.graphql(graphqlOperation(queries.listSubModules, {
        filter: {
            module_id: {
                eq: params.payload
            }
        }
    }))
    return data
}

//getCsGridDataSource
export function* getCsGridDataSource(params) {
    let { data } = yield call(getCsGridDataSourceApi, params)
    yield put({
        type: "STORE_GET_CS_GRID_DATA_SOURCE",
        payload: data
    })
}

async function getCsGridDataSourceApi(params) {
    if (params.payload.fromAmplify) {
        const data = await API.graphql({ query: queries[params.payload.data_source] });
        return data
    }
}

export function* getCsWebComponentData(params) {
    let { data } = yield call(getCsWebComponentDataApi, params)
    yield put({
        type: "STORE_CS_WEB_COMPONENTS",
        payload: data
    })
}

async function getCsWebComponentDataApi(params) {
    const data = API.graphql(graphqlOperation(queries.listWebComponents, {
        filter: {
            module_id: {
                eq: params.payload
            }
        }
    }))
    return data
}