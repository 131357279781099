/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOCPPTag = /* GraphQL */ `
  query GetOCPPTag($id: ID!) {
    getOCPPTag(id: $id) {
      id
      idTag
      parentIdTag
      expiryDateTime
      inTransaction
      isBlocked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOCPPTags = /* GraphQL */ `
  query ListOCPPTags(
    $filter: ModelOCPPTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOCPPTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idTag
        parentIdTag
        expiryDateTime
        inTransaction
        isBlocked
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOCPPTags = /* GraphQL */ `
  query SyncOCPPTags(
    $filter: ModelOCPPTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOCPPTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        idTag
        parentIdTag
        expiryDateTime
        inTransaction
        isBlocked
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($id: ID!) {
    getCustomers(id: $id) {
      id
      customer_name
      customer_id
      mobile_number
      email_id
      date_created
      end_of_contract
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer_name
        customer_id
        mobile_number
        email_id
        date_created
        end_of_contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customer_name
        customer_id
        mobile_number
        email_id
        date_created
        end_of_contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWebComponents = /* GraphQL */ `
  query GetWebComponents($id: ID!) {
    getWebComponents(id: $id) {
      id
      comp_name
      comp_type
      comp_id
      parent_id
      comp_image
      display_name
      trigger_command
      module_id
      data_source
      data_key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWebComponents = /* GraphQL */ `
  query ListWebComponents(
    $filter: ModelWebComponentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comp_name
        comp_type
        comp_id
        parent_id
        comp_image
        display_name
        trigger_command
        module_id
        data_source
        data_key
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWebComponents = /* GraphQL */ `
  query SyncWebComponents(
    $filter: ModelWebComponentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWebComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        comp_name
        comp_type
        comp_id
        parent_id
        comp_image
        display_name
        trigger_command
        module_id
        data_source
        data_key
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubModule = /* GraphQL */ `
  query GetSubModule($id: ID!) {
    getSubModule(id: $id) {
      id
      module_id
      comp_type
      data_source
      onClick
      isRequired
      ignoreColumns
      comp_order
      fromAmplify
      ignoreDataSource
      label
      comp_name
      editable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubModules = /* GraphQL */ `
  query ListSubModules(
    $filter: ModelSubModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        module_id
        comp_type
        data_source
        onClick
        isRequired
        ignoreColumns
        comp_order
        fromAmplify
        ignoreDataSource
        label
        comp_name
        editable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubModules = /* GraphQL */ `
  query SyncSubModules(
    $filter: ModelSubModuleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubModules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        module_id
        comp_type
        data_source
        onClick
        isRequired
        ignoreColumns
        comp_order
        fromAmplify
        ignoreDataSource
        label
        comp_name
        editable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getModule = /* GraphQL */ `
  query GetModule($id: ID!) {
    getModule(id: $id) {
      id
      module_name
      module_id
      module_icon
      comp_order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listModules = /* GraphQL */ `
  query ListModules(
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        module_name
        module_id
        module_icon
        comp_order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncModules = /* GraphQL */ `
  query SyncModules(
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncModules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        module_name
        module_id
        module_icon
        comp_order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAddStationForm = /* GraphQL */ `
  query GetAddStationForm($id: ID!) {
    getAddStationForm(id: $id) {
      id
      comp_label
      comp_name
      comp_align
      comp_type
      display_name
      editable
      required
      is_child
      parent_name
      comp_order
      data_event
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAddStationForms = /* GraphQL */ `
  query ListAddStationForms(
    $filter: ModelAddStationFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddStationForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comp_label
        comp_name
        comp_align
        comp_type
        display_name
        editable
        required
        is_child
        parent_name
        comp_order
        data_event
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAddStationForms = /* GraphQL */ `
  query SyncAddStationForms(
    $filter: ModelAddStationFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddStationForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        comp_label
        comp_name
        comp_align
        comp_type
        display_name
        editable
        required
        is_child
        parent_name
        comp_order
        data_event
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCSPayment = /* GraphQL */ `
  query GetCSPayment($id: ID!) {
    getCSPayment(id: $id) {
      id
      cognito_user_id
      chargind_station_id
      socket_id
      reservation_id
      payment_amount
      payment_status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCSPayments = /* GraphQL */ `
  query ListCSPayments(
    $filter: ModelCSPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCSPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognito_user_id
        chargind_station_id
        socket_id
        reservation_id
        payment_amount
        payment_status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCSPayments = /* GraphQL */ `
  query SyncCSPayments(
    $filter: ModelCSPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCSPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cognito_user_id
        chargind_station_id
        socket_id
        reservation_id
        payment_amount
        payment_status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChargingSockets = /* GraphQL */ `
  query GetChargingSockets($id: ID!) {
    getChargingSockets(id: $id) {
      id
      scoket_type
      is_available
      chargingstationsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChargingSockets = /* GraphQL */ `
  query ListChargingSockets(
    $filter: ModelChargingSocketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChargingSockets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        scoket_type
        is_available
        chargingstationsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChargingSockets = /* GraphQL */ `
  query SyncChargingSockets(
    $filter: ModelChargingSocketsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChargingSockets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        scoket_type
        is_available
        chargingstationsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chargingSocketsByChargingstationsID = /* GraphQL */ `
  query ChargingSocketsByChargingstationsID(
    $chargingstationsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChargingSocketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chargingSocketsByChargingstationsID(
      chargingstationsID: $chargingstationsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scoket_type
        is_available
        chargingstationsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserVehicle = /* GraphQL */ `
  query GetUserVehicle($id: ID!) {
    getUserVehicle(id: $id) {
      id
      vehicle_manufacturer
      vehicle_modal_name
      vehicle_type
      socket_type
      charger_capacity
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserVehicles = /* GraphQL */ `
  query ListUserVehicles(
    $filter: ModelUserVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vehicle_manufacturer
        vehicle_modal_name
        vehicle_type
        socket_type
        charger_capacity
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserVehicles = /* GraphQL */ `
  query SyncUserVehicles(
    $filter: ModelUserVehicleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserVehicles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicle_manufacturer
        vehicle_modal_name
        vehicle_type
        socket_type
        charger_capacity
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserWallet = /* GraphQL */ `
  query GetUserWallet($id: ID!) {
    getUserWallet(id: $id) {
      id
      balance_amount
      status
      last_transaction
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserWallets = /* GraphQL */ `
  query ListUserWallets(
    $filter: ModelUserWalletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserWallets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        balance_amount
        status
        last_transaction
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserWallets = /* GraphQL */ `
  query SyncUserWallets(
    $filter: ModelUserWalletFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserWallets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        balance_amount
        status
        last_transaction
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      start_time
      end_time
      reserve_time
      status
      user_cognito_id
      charging_station_id
      cs_socket_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        start_time
        end_time
        reserve_time
        status
        user_cognito_id
        charging_station_id
        cs_socket_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReservations = /* GraphQL */ `
  query SyncReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReservations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        start_time
        end_time
        reserve_time
        status
        user_cognito_id
        charging_station_id
        cs_socket_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserDetails = /* GraphQL */ `
  query GetUserDetails($id: ID!) {
    getUserDetails(id: $id) {
      id
      user_name
      user_email
      user_mobile
      UserWallet {
        id
        balance_amount
        status
        last_transaction
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userDetailsUserWalletId
    }
  }
`;
export const listUserDetails = /* GraphQL */ `
  query ListUserDetails(
    $filter: ModelUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_name
        user_email
        user_mobile
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userDetailsUserWalletId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserDetails = /* GraphQL */ `
  query SyncUserDetails(
    $filter: ModelUserDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_name
        user_email
        user_mobile
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userDetailsUserWalletId
      }
      nextToken
      startedAt
    }
  }
`;
export const getChargingStations = /* GraphQL */ `
  query GetChargingStations($id: ID!) {
    getChargingStations(id: $id) {
      id
      cs_name
      cs_location {
        latitude
        longitude
        address
        pin
        city
      }
      cs_info {
        type
        communication
        is_available
        next_availability
      }
      qr_code
      mac_address
      ChargingSockets {
        nextToken
        startedAt
      }
      organizationsID
      cs_vehicle_type
      price_per_unit
      station_status
      customer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChargingStations = /* GraphQL */ `
  query ListChargingStations(
    $filter: ModelChargingStationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChargingStations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cs_name
        qr_code
        mac_address
        cs_location {
          latitude
          longitude
          address
          pin
          city
        }
        cs_info {
          type
          communication
          is_available
          next_availability
        }
        organizationsID
        cs_vehicle_type
        price_per_unit
        station_status
        customer
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChargingStations = /* GraphQL */ `
  query SyncChargingStations(
    $filter: ModelChargingStationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChargingStations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cs_name
        qr_code
        mac_address
        organizationsID
        cs_vehicle_type
        price_per_unit
        station_status
        customer
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chargingStationsByOrganizationsID = /* GraphQL */ `
  query ChargingStationsByOrganizationsID(
    $organizationsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChargingStationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chargingStationsByOrganizationsID(
      organizationsID: $organizationsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cs_name
        qr_code
        mac_address
        organizationsID
        cs_vehicle_type
        price_per_unit
        station_status
        customer
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganizations = /* GraphQL */ `
  query GetOrganizations($id: ID!) {
    getOrganizations(id: $id) {
      id
      org_name
      org_type
      ChargingStations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        org_name
        org_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        org_name
        org_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
