import "./index.scss";
import React, { PureComponent, Suspense, lazy } from "react";
// import Login from "./views/login";
import { message } from "antd";
import ForgotPassword from "./views/forgotPassword";
import {
  getToken,
  getUser,
  signIn,
  signout,
} from "./connectm-client/authentication";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import { RoleBasedMainRoutes } from "./connectm-client/roles/role-access";
import { connect } from "react-redux";
import {
  ReduxUserAction,
  ReduxUserState,
  mapDispatchToProps,
  mapStateToProps,
} from "./connectm-client/actions/user";
// import Content from "./component/rnd-home-page"
// import Home from "./views/home";
import { Auth } from "aws-amplify";
import { getServices } from "./component/content";
import { userTheme } from "./UserTheme";
// import multilogin from "./views/multilogin/multilogin";
import axios from "axios";
import { refvar } from "./helperFunction";
import CreatePassword from "./views/createPassword";

const Login = lazy(() => import("./views/login"));
const Home = lazy(() => import("./views/home"));
const multilogin = lazy(() => import("./views/multilogin/multilogin"));

const MainRoutes = {
  HOME: "/",
  LOGIN: "/login",
  RESETPASSWORD: "/reset-password",
  CREATEPASSWORD: "/create-password",
};

interface AppProp
  extends RouteComponentProps,
    ReduxUserAction,
    ReduxUserState {}
interface AppState {
  authenticated: boolean | null;
  user: any;
  userRole: string;
  isIdeal: boolean;
  orgId: string;
  apires: any;
  currentTheme: any;
  loading: boolean;
  currentTitle: any;
}

const RestrictedRoute = (props: {
  authenticated: boolean | null;
  path: string;
  component: any;
  role: string;
  orgId: string;
  history: any;
}) => {
  // When the user has logged in and still visiting login page.
  if (props.path === MainRoutes.LOGIN && props.authenticated) {
    // getServices(props.role,props.orgId).then((res) => {
    //   if(res?.length){
    //     let path='/';
    //     switch (res[0].module_name) {
    //       case 'mis_7':
    //         path+='mis'
    //         break;
    //         case 'mis_6':
    //         path+='mis'
    //         break;
    //         case 'alert_3':
    //         path+='alert'
    //         break;
    //         case 'alert_4':
    //         path+='alert'
    //         break;
    //         case 'customer_care':
    //           path+="service/"+res[0].module_id;
    //           break;
    //       default:
    //         path+=res[0].module_name;
    //         break;
    //     }
    //     return props.history.push(path );
    //   }else{
    return <Redirect to={RoleBasedMainRoutes(props.role)} />;
    //   }
    // });
  }
  // When the user is not logged in.
  if (props.path === MainRoutes.LOGIN && props.authenticated === false) {
    return <Route path={MainRoutes.LOGIN} component={props.component} />;
  }
  if (props.path !== MainRoutes.LOGIN && props.authenticated)
    return <Route path={props.path} component={props.component} />;
  return null;
};
class App extends PureComponent<AppProp, AppState> {
  constructor(props: AppProp) {
    super(props);
    this.state = {
      authenticated: null,
      user: {},
      userRole: "",
      isIdeal: true,
      orgId: "",
      apires: [],
      currentTheme: "",
      loading: true,
      currentTitle: "",
    };
  }

  async componentDidMount() {
    localStorage.removeItem("loggedInDateTime");
    if (refvar === "hopev") {
      const root = document.documentElement;
      root?.style.setProperty("--APP_BACKGROUND", "#0A0A0A");
    }
    const tokenRes = await getToken();
    axios
      .get(
        `${process.env.REACT_APP_WEBAPPAPI}/getorganization?domain=${refvar}`,
        { headers: { "Content-Type": "application/json" } }
      )

      .then((response) => {
        const posts = response.data.data;
        this.setState({
          ...this.state,
          currentTheme: posts?.length ? posts[0].theme_name : "T001",
          loading: false,
          currentTitle: posts?.length ? posts[0].org_name : "Motovolt",
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          currentTheme: "T001",
          loading: false,
        });
      });

    getUser().then(async (userObject) => {
      if (userObject.success) {
        this.props.usersAction({
          type: "UPDATE_USER",
          payload: {
            authenticated: true,
            user: userObject.user,
          },
        });
        const root = document.documentElement;
        // const userOrgId=userObject?.user?.attributes ? userObject?.user?.attributes['custom:orgId'] :'';
        const tokenRes = await getToken();
        axios
          .get(
            `${process.env.REACT_APP_WEBAPPAPI}/getorganization?domain=${refvar}`,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            const posts = response.data.data;
            this.setState({ apires: posts });
            const userExistingTheme = localStorage.getItem(
              "userCurrentTheme" + refvar
            );
            if (userExistingTheme) {
              Object.keys(userTheme[userExistingTheme]).forEach((key) => {
                root?.style.setProperty(key, userTheme[userExistingTheme][key]);
              });
            } else if (userObject?.user?.attributes) {
              // const currentOrgObj=posts?.find((x:any)=>x.org_id?.toString()===userObject?.user?.attributes["custom:orgId"]?.toString()) ?? {};
              // currentOrgObj.theme_name=currentOrgObj?.theme_name??'T001'
              const currentOrgObj = posts?.length ? posts[0] : {};
              currentOrgObj.theme_name =
                currentOrgObj?.theme_name ??
                (refvar === "keto" ? "T003" : "T001");
              localStorage.setItem(
                "userCurrentTheme" + refvar,
                currentOrgObj.theme_name
              );

              Object.keys(userTheme[currentOrgObj?.theme_name]).forEach(
                (key) => {
                  root?.style.setProperty(
                    key,
                    userTheme[currentOrgObj?.theme_name][key]
                  );
                }
              );
            }
          });
      } else {
        console.log("dhdhaa");
        this.setState({
          authenticated: false,
        });
        if (
          this.props.history.location.pathname !== MainRoutes.RESETPASSWORD &&
          this.props.history.location.pathname !== MainRoutes.CREATEPASSWORD
        )
          this.props.history.push("/login");
      }
    });
    // setInterval(async () => {
    //         try {
    //           if(await Auth.currentSession()){
    //             if(this.state.isIdeal){
    //               signout()
    //               .then(() => {
    //                 this.props.usersAction({
    //                   type: "UPDATE_USER",
    //                   payload: {
    //                     authenticated: false,
    //                     user: null,
    //                   },
    //                 });

    //                 this.props.history.push("/login");
    //                 localStorage.clear()
    //               })
    //               .catch(() => {
    //                 console.log("log out error");
    //               });
    //               localStorage.clear();
    //               // window.location.reload();
    //               message.warning("Session Timeout! Please login again")
    //             } else {
    //             this.setState({
    //               ...this.state,
    //               isIdeal: true
    //             })
    //             }
    //           }
    //         } catch (error) {
    //           console.log("error",error)
    //         }
    //       }, 2.5*60*1000);

    //       window.addEventListener("beforeunload", () =>
    //       {
    //         signout();
    //       })
  }
  getAppTitle = () => {
    switch (refvar?.toString().toLowerCase()) {
      case "hopev":
        return " - Hop";
      case "arada":
        return " - Arada";
      case "montra":
        return " - Montra";
      case "segev":
        return " - Seg";
      case "ev":
        return " - Motovolt";
      case "evqa":
        return "";
      default:
        return "Motovolt";
    }
  };

  static getDerivedStateFromProps(props: AppProp, state: AppState) {
    state.authenticated = props.user.authenticated;
    if (props.user?.user) {
      state.authenticated = props.user.authenticated;
      state.user = props.user.user;
      state.userRole = props.user.user.attributes["custom:role"];
      state.orgId = props.user.user.attributes["custom:orgId"];
    }
    return state;
  }

  render() {
    if (this.state.currentTheme === "T002") {
      document.body.classList.add("imgBody");
    } else {
      document.body.classList.remove("imgBody");
    }
    // this.state.posts && this.state.posts.length === 1 ? this.state.posts[0]?.org_name : "Motovolt"
    return (
      <>
        <title>
          Command Center -{" "}
          {refvar === "ev"
            ? "Motovolt"
            : refvar.charAt(0).toUpperCase() + refvar.slice(1)}
          {/* {this.state.currentTitle === "Shared Mobility-Motovolt" ? "Motovolt" : this.state.currentTitle} */}
          {/* this.state.apires &&
          this.state.apires.length === 1 ? this.state.currentTitle : "Motovolt"  */}
        </title>
        {this.props.history.location.pathname === MainRoutes.RESETPASSWORD &&
        !this.state.authenticated ? (
          <ForgotPassword />
        ) : this.props.history.location.pathname ===
            MainRoutes.CREATEPASSWORD && !this.state.authenticated ? (
          <CreatePassword />
        ) : (
          <div
            onClick={() => {
              this.setState({ ...this.state, isIdeal: false });
            }}
          >
            {this.state.loading ? (
              "loading"
            ) : (
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <RestrictedRoute
                    authenticated={this.state.authenticated}
                    path={MainRoutes.LOGIN}
                    role={this.state.userRole}
                    component={
                      this.state.currentTheme === "T002" ? multilogin : Login
                    }
                    orgId={this.state.orgId}
                    history={this.props.history}
                  />
                  <RestrictedRoute
                    authenticated={this.state.authenticated}
                    path={MainRoutes.HOME}
                    role={this.state.userRole}
                    component={Home}
                    orgId={this.state.orgId}
                    history={this.props.history}
                  />
                </Switch>
              </Suspense>
            )}
          </div>
        )}
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
