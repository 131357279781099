export function getSwappingStationGrid(data: any) {
    let mdata: any = {
        data: { data_source: [] },
        totalNoOfElements: 0
    }
    mdata.data.data_source = data
    mdata.totalNoOfElements = mdata.data.data_source.length
    return mdata;
}

export function plainModifier(data: any) {
    return { data: data }
}

export function getOverviewUsageCharts(data: any) {
    const counts = data.Items.reduce((acc:any, item:any) => {
        const date = item.created_at.substring(0, 10);
        acc[date] = (acc[date] || 0) + 1;
        return acc;
    }, {})
    return counts
}
