import * as AWS from 'aws-sdk'
import { IGetBatteryListBYStationId, IGetBookingDetailsByStationId, IGetOverviewCountBatteries, IGetOverviewCountConsumers, IGetOverviewCountStations, IGetOverviewCountSwaps, IGetRidersList, IGetSlotDistribution, IGetStationDetailsById, IGetSwapHistoryByStationId, IGetSwapHistoryByUserId, IGetSwapUsageFor7days, IGetSwappingStationLists, IGetUsageOverview, TABLE_CONSUMER_DETAILS, TABLE_RIDER_DETAILS, TABLE_SWAPPING_BATTERY, TABLE_SWAPPING_BOOKING, TABLE_SWAPPING_MASTER, TABLE_SWAPPING_SWAPS } from '../../../actions/swappingStation/swapping-station'
import { getOverviewUsageCharts, getSwappingStationGrid, plainModifier } from '../helpers/objectmodifier'
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import { message } from 'antd';

const getDocClient = () => {
    const configuration = {
      region: "us-east-1",
      secretAccessKey: process.env.REACT_APP_SA,
      accessKeyId: process.env.REACT_APP_A,
    };
    AWS.config.update(configuration);
    const docClient = new AWS.DynamoDB.DocumentClient();
    return docClient;
  };

export async function getTotalStationsCountFromApi(query: IGetOverviewCountStations, userdata: any) {

    if (parseInt(userdata?.user?.attributes["custom:customerId"]) == 0) {
        const conditionExpression = 'deleted = :deleted';
        const expressionAttributeValues = {
            ':deleted': false
        };

        let params = {
            TableName: TABLE_SWAPPING_MASTER,
            Select: "COUNT",
            FilterExpression: conditionExpression,
            ExpressionAttributeValues: expressionAttributeValues
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise())
        let mdata = plainModifier(data)
        return mdata
    } else {
        const conditionExpression = 'deleted = :deleted AND customer_id = :customer_id';
        const expressionAttributeValues = {
            ':deleted': false,
            ':customer_id': userdata?.user?.attributes["custom:customerId"]
        };

        let params = {
            TableName: TABLE_SWAPPING_MASTER,
            Select: "COUNT",
            FilterExpression: conditionExpression,
            ExpressionAttributeValues: expressionAttributeValues
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise())
        let mdata = plainModifier(data)
        return mdata
    }
}

export async function getTotalBatteriesCountFromApi(query: IGetOverviewCountBatteries) {
    var params = {
        TableName: TABLE_SWAPPING_BATTERY,
        Select: "COUNT"
    }
    const docClient = await getDocClient()
    const data = await (await docClient.scan(params).promise())
    let mdata = plainModifier(data)
    return mdata
}
export async function getTotalConsumersCountFromApi(query: IGetOverviewCountConsumers, userdata: any) {


    if (parseInt(userdata?.user?.attributes["custom:customerId"]) == 0) {
        let params = {
            TableName: TABLE_CONSUMER_DETAILS,
            Select: "COUNT"
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise())
        let mdata = plainModifier(data)
        return mdata
    } else {
        const conditionExpression = 'customer_id = :customer_id';
        const expressionAttributeValues = {
            ':customer_id': userdata?.user?.attributes["custom:customerId"]
        };
        let params = {
            TableName: TABLE_CONSUMER_DETAILS,
            Select: "COUNT",
            FilterExpression: conditionExpression,
            ExpressionAttributeValues: expressionAttributeValues
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise())
        let mdata = plainModifier(data)
        return mdata
    }
}

export async function getTotalSwapsCountFromApi(query: IGetOverviewCountSwaps) {

    var params1 = {
        TableName: TABLE_SWAPPING_SWAPS
    }
    const docClient = await getDocClient()
    const data1 = await (await docClient.scan(params1).promise()).Items
    let mdata1 = getSwappingStationGrid(data1)
    return mdata1
}

export async function getSwappingStationsListFromApi(query: IGetSwappingStationLists, userdata: any) {
    console.log("getSwapUser", userdata)
    if (parseInt(userdata?.user?.attributes["custom:customerId"]) == 0) {
        const conditionExpression = 'deleted = :deleted';
        const expressionAttributeValues = {
            ':deleted': false
        };
        let params = {
            TableName: TABLE_SWAPPING_MASTER,
            FilterExpression: conditionExpression,
            ExpressionAttributeValues: expressionAttributeValues
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        return mdata
    } else {
        const conditionExpression = 'deleted = :deleted AND customer_id = :customer_id';
        const expressionAttributeValues = {
            ':deleted': false,
            ':customer_id': userdata?.user?.attributes["custom:customerId"]
        }
        let params = {
            TableName: TABLE_SWAPPING_MASTER,
            FilterExpression: conditionExpression,
            ExpressionAttributeValues: expressionAttributeValues
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        return mdata
    }

}

export async function deleteStation(expNames: any, expValues: any, keyId: any, tableName: any, updateExp: any, version: any) {
    expNames["#UpdatedAt"] = "updatedAt"
    expValues[":updatedAt"] = moment.utc().format().toString()
    expNames["#_Version"] = "_version"
    expValues[":_version"] = version
    expNames["#_LastChangedAt"] = "_lastChangedAt"
    expValues[":_lastChangedAt"] = moment.utc().valueOf()
    var params = {
        ExpressionAttributeNames: expNames,
        ExpressionAttributeValues: expValues,
        Key: {
            id: keyId
        },
        TableName: tableName,
        UpdateExpression: updateExp + ",#UpdatedAt = :updatedAt,#_Version = :_version,#_LastChangedAt = :_lastChangedAt"
    };
    const docClient = await getDocClient()
    return await docClient.update(params).promise()
}

export async function getSwappingStationsListByNameFromApi(query: any, userdata: any) {
    if (parseInt(userdata?.user?.attributes["custom:customerId"]) == 0) {
        let params = {
            TableName: TABLE_SWAPPING_MASTER,
            FilterExpression: 'contains (#station_name, :station_name) AND #deleted = :deleted',
            ExpressionAttributeNames: {
                '#station_name': 'station_name',
                "#deleted": "deleted"
            },
            ExpressionAttributeValues: {
                ':station_name': query.payload.keyword,
                ':deleted': false
            }
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        if (mdata?.data?.data_source.length == 0) {
            message.info("No records found")
        }
        return mdata
    } else {
        let params = {
            TableName: TABLE_SWAPPING_MASTER,
            FilterExpression: 'contains (#station_name, :station_name) AND #deleted = :deleted AND #customer_id = :customer_id',
            ExpressionAttributeNames: {
                '#station_name': 'station_name',
                "#deleted": "deleted",
                "#customer_id": "customer_id"
            },
            ExpressionAttributeValues: {
                ':station_name': query.payload.keyword,
                ':deleted': false,
                ':customer_id': userdata?.user?.attributes["custom:customerId"]
            }
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        if (mdata?.data?.data_source.length == 0) {
            message.info("No records found")
        }
        return mdata
    }

}


export async function getRidersListByNameFromApi(query: any, userdata: any) {
    if (parseInt(userdata?.user?.attributes["custom:customerId"]) == 0) {
        let params = {
            TableName: TABLE_RIDER_DETAILS,
            FilterExpression: 'contains (#username, :username)',
            ExpressionAttributeNames: {
                '#username': 'username'
            },
            ExpressionAttributeValues: {
                ':username': query.payload.keyword
            }
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        if (mdata?.data?.data_source.length == 0) {
            message.info("No records found")
        }
        return mdata
    } else {
        let params = {
            TableName: TABLE_RIDER_DETAILS,
            FilterExpression: 'contains (#username, :username) AND #customer_id = :customer_id',
            ExpressionAttributeNames: {
                '#username': 'username',
                "#customer_id": "customer_id"
            },
            ExpressionAttributeValues: {
                ':username': query.payload.keyword,
                ':customer_id': userdata?.user?.attributes["custom:customerId"]
            }
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        if (mdata?.data?.data_source.length == 0) {
            message.info("No records found")
        }
        return mdata
    }
}


export async function getUsageOverviewFromApi(query: IGetUsageOverview) {
    var params = {
        TableName: TABLE_SWAPPING_SWAPS
    }
    const docClient = await getDocClient()
    const data = await (await docClient.scan(params).promise()).Items
    let mdata = getSwappingStationGrid(data)
    return mdata
}


export async function getSlotDistributionOverviewFromApi(query: IGetSlotDistribution) {
    var params = {
        TableName: TABLE_SWAPPING_BATTERY
    }
    const docClient = await getDocClient()
    const data = await (await docClient.scan(params).promise()).Items
    let mdata = getSwappingStationGrid(data)
    return mdata
}

export async function insertNewSwappingStation(values: any) {
    values["updatedAt"] = moment.utc().format().toString()
    values["createdAt"] = moment.utc().format().toString()
    values["_version"] = 1
    values["__typename"] = TABLE_SWAPPING_MASTER.split("-")[0]
    values["_lastChangedAt"] = moment.utc().valueOf()
    values["id"] = uuidv4().toString()
    var params = {
        Item: values,
        TableName: TABLE_SWAPPING_MASTER
    };
    const docClient = await getDocClient()
    return await docClient.put(params).promise()
}

export async function editSwappingStation(expNames: any, expValues: any, keyId: any, tableName: any, updateExp: any, version: any) {
    expNames["#UpdatedAt"] = "updatedAt"
    expValues[":updatedAt"] = moment.utc().format().toString()
    expNames["#_Version"] = "_version"
    expValues[":_version"] = version
    expNames["#_LastChangedAt"] = "_lastChangedAt"
    expValues[":_lastChangedAt"] = moment.utc().valueOf()
    var params = {
        ExpressionAttributeNames: expNames,
        ExpressionAttributeValues: expValues,
        Key: {
            id: keyId
        },
        TableName: tableName,
        UpdateExpression: updateExp + ",#UpdatedAt = :updatedAt,#_Version = :_version,#_LastChangedAt = :_lastChangedAt"
    };
    const docClient = await getDocClient()
    return await docClient.update(params).promise()
}


export async function getStationDetailsByIdFromDynamo(query: IGetStationDetailsById) {
    var params = {
        TableName: TABLE_SWAPPING_MASTER,
        ExpressionAttributeNames: {
            "#id": "id"
        },
        ExpressionAttributeValues: {
            ":id_value": query.payload.id,
        },
        KeyConditionExpression: "#id = :id_value",
    }
    const docClient = await getDocClient()
    const data = await (await docClient.query(params).promise()).Items
    let mdata = getSwappingStationGrid(data)
    return mdata
}

export async function getBatteryListByStationIdFromDynamo(query: IGetBatteryListBYStationId) {
    var params = {
        TableName: TABLE_SWAPPING_BATTERY,
        IndexName: 'findByStationId',
        ExpressionAttributeNames: {
            "#station_id": "station_id"
        },
        ExpressionAttributeValues: {
            ":station_id_value": query.payload.station_id,
        },
        KeyConditionExpression: "#station_id = :station_id_value",
    }
    const docClient = await getDocClient()
    const data = await (await docClient.query(params).promise()).Items
    let mdata = getSwappingStationGrid(data)
    return mdata
}

export async function getSwapHistoryByStationIdFromDynamo(query: IGetSwapHistoryByStationId) {
    var params = {
        TableName: TABLE_SWAPPING_SWAPS,
        IndexName: 'findByStationId',
        ExpressionAttributeNames: {
            "#station_id": "station_id"
        },
        ExpressionAttributeValues: {
            ":station_id_value": query.payload.station_id,
        },
        KeyConditionExpression: "#station_id = :station_id_value",
    }
    const docClient = await getDocClient()
    const data = await (await docClient.query(params).promise()).Items
    let mdata = getSwappingStationGrid(data)
    return mdata
}


export async function getSwapHistoryByUserIdFromDynamo(query: IGetSwapHistoryByUserId | any) {
    var params = {
        TableName: TABLE_SWAPPING_SWAPS,
        IndexName: 'findByUserID',
        FilterExpression: 'contains (#updatedAt, :updatedAt_value)',
        KeyConditionExpression: "#user_id = :user_id_value",
        ExpressionAttributeNames: {
            "#user_id": "user_id",
            "#updatedAt":"updatedAt"
        },
        ExpressionAttributeValues: {
            ":user_id_value": query.payload.user_id,
            ":updatedAt_value":query?.payload.filterDate
        }
    }
    const docClient = await getDocClient()
    const data = await (await docClient.query(params).promise()).Items
    let mdata = getSwappingStationGrid(data)
    return mdata
}

export async function getBookingDetailsByStationIdFromDynamo(query: IGetBookingDetailsByStationId) {
    var params = {
        TableName: TABLE_SWAPPING_BOOKING,
        IndexName: 'findByStationId',
        ExpressionAttributeNames: {
            "#station_id": "station_id"
        },
        ExpressionAttributeValues: {
            ":station_id_value": query.payload.station_id,
        },
        KeyConditionExpression: "#station_id = :station_id_value",
    }
    const docClient = await getDocClient()
    const data = await (await docClient.query(params).promise()).Items
    let mdata = getSwappingStationGrid(data)
    return mdata
}

export async function getRidersListFromApi(query: IGetRidersList, userdata: any) {
    if (parseInt(userdata?.user?.attributes["custom:customerId"]) == 0) {
        let params = {
            TableName: TABLE_RIDER_DETAILS
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        return mdata
    } else {
        let params = {
            TableName: TABLE_RIDER_DETAILS,
            FilterExpression: '#customer_id = :customer_id',
            ExpressionAttributeNames: {
                "#customer_id": "customer_id"
            },
            ExpressionAttributeValues: {
                ':customer_id': userdata?.user?.attributes["custom:customerId"]
            }
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        if (mdata?.data?.data_source.length == 0) {
            message.info("No records found")
        }
        return mdata
    }
}

function getDynamoExpression(name:string){
    return `contains (#${name},:${name})`.toString()
}
function getDynamoAttributes(name:string){
    let a: any = {}
    a[`#${name}`] = `${name}`
    return a;
    
}
function getDynamoAttributeValues(name:string,value:string){
    let a: any = {}
    a[`:${name}`] = `${value}`
    return a;
}

export async function getSwapHistory(query: any) {
    console.log("querySwapHistory",query)
    if (Object.keys(query).length == 0) {
        let params = {
            TableName: TABLE_SWAPPING_SWAPS
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        return mdata
    } else {
        let params: any = {
            TableName: TABLE_SWAPPING_SWAPS,
            FilterExpression: getDynamoExpression(query.name),
            ExpressionAttributeNames: getDynamoAttributes(query.name),
            ExpressionAttributeValues: getDynamoAttributeValues(query.name,query.keyword)
        }
        const docClient = await getDocClient()
        const data = await (await docClient.scan(params).promise()).Items
        let mdata = getSwappingStationGrid(data)
        if (mdata?.data?.data_source.length == 0) {
            message.info("No records found")
        }
        return mdata
    }
}


export async function insertNewBatterySlot(values: any, station_id: string) {
    values["evswappingmasterID"] = station_id
    values["station_id"] = station_id
    values["updatedAt"] = moment.utc().format().toString()
    values["createdAt"] = moment.utc().format().toString()
    values["_version"] = 1
    values["__typename"] = TABLE_SWAPPING_MASTER.split("-")[0]
    values["_lastChangedAt"] = moment.utc().valueOf()
    values["id"] = uuidv4().toString()
    values["battery_name"] = ""
    values["charging_time"] = "100"
    values["soc"] = "100%"
    values["battery_available"] = true
    values["slot_status"] = "CHARGING"
    var params = {
        Item: values,
        TableName: TABLE_SWAPPING_BATTERY
    };
    const docClient = await getDocClient()
    return await docClient.put(params).promise()
}



export async function insertNewRider(values: any) {
    values["updatedAt"] = moment.utc().format().toString()
    values["createdAt"] = moment.utc().format().toString()
    values["_version"] = 1
    values["__typename"] = TABLE_RIDER_DETAILS.split("-")[0]
    values["_lastChangedAt"] = moment.utc().valueOf()
    values["id"] = uuidv4().toString()
    var params = {
        Item: values,
        TableName: TABLE_RIDER_DETAILS
    };
    const docClient = await getDocClient()
    return await docClient.put(params).promise()
}

export async function editRider(expNames: any, expValues: any, keyId: any, tableName: any, updateExp: any, version: any) {
    expNames["#UpdatedAt"] = "updatedAt"
    expValues[":updatedAt"] = moment.utc().format().toString()
    expNames["#_Version"] = "_version"
    expValues[":_version"] = version
    expNames["#_LastChangedAt"] = "_lastChangedAt"
    expValues[":_lastChangedAt"] = moment.utc().valueOf()
    var params = {
        ExpressionAttributeNames: expNames,
        ExpressionAttributeValues: expValues,
        Key: {
            id: keyId
        },
        TableName: tableName,
        UpdateExpression: updateExp + ",#UpdatedAt = :updatedAt,#_Version = :_version,#_LastChangedAt = :_lastChangedAt"
    };
    const docClient = await getDocClient()
    return await docClient.update(params).promise()
}

export async function editSlot(expNames: any, expValues: any, keyId: any, tableName: any, updateExp: any, version: any) {
    expNames["#UpdatedAt"] = "updatedAt"
    expValues[":updatedAt"] = moment.utc().format().toString()
    expNames["#_Version"] = "_version"
    expValues[":_version"] = version
    expNames["#_LastChangedAt"] = "_lastChangedAt"
    expValues[":_lastChangedAt"] = moment.utc().valueOf()
    var params = {
        ExpressionAttributeNames: expNames,
        ExpressionAttributeValues: expValues,
        Key: {
            id: keyId
        },
        TableName: tableName,
        UpdateExpression: updateExp + ",#UpdatedAt = :updatedAt,#_Version = :_version,#_LastChangedAt = :_lastChangedAt"
    };
    const docClient = await getDocClient()
    return await docClient.update(params).promise()
}


// export async function getSwapUsageForlast7daysFromDynamo(query: IGetSwapUsageFor7days) {
//     const sevenDaysAgo = new Date();
//     sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
//     var params = {
//         TableName: TABLE_SWAPPING_SWAPS,
//         IndexName: 'findByUpdatedTime',
//         ExpressionAttributeNames: {
//             "#updatedAt": "updatedAt"
//         },
//         ExpressionAttributeValues: {
//             ":updatedAt_value": sevenDaysAgo.toISOString(),
//         },
//         KeyConditionExpression: "#updatedAt < :updatedAt_value",
//     }
//     const data = await (await docClient.query(params).promise()).Items
//     let mdata = getSwappingStationGrid(data)
//     return mdata
// }