import axios from "axios"
import { call, put } from "redux-saga/effects"
import { IDashboardAction } from "../actions/dashboard"
import { getToken } from "../authentication"
import { TDashboardList } from "../redux/models"
import { message } from "antd"

export type Store_DashboardList = {
  type: "STORE_DASHBOARDLIST",
  payload: {
    dashboardList: TDashboardList[]
  }
}
export type Clear_DashboardList = {
  type: "CLEAR_DASHBOARDLIST"
}

export type TAlertsTrendData = {
  searchOptions: string
}

export function* getDashboardList(params: IDashboardAction) {
  try {
    const data: TDashboardList[] = yield call(getDashboard, params)
    yield put({
      type: "STORE_DASHBOARDLIST",
      payload: {
        dashboardList: data
      }
    } as Store_DashboardList)
  } catch (error) {
    console.log("get dashboardlist error", error)
  }
}

export function* clearDashboardList() {
  try {
    yield put({
      type: "CLEAR_DASHBOARDLIST"
    } as Clear_DashboardList)
  } catch (error) {
    console.log("clear dashboardlist error", error)
  }
}


export async function getDashboard(params: IDashboardAction) {
  const tokenRes = await getToken();
  const response = await axios.get(process.env.REACT_APP_WEBAPPAPI+ '/getdashboard?org_id='+params.payload.org_id+'&customer_id='+params.payload.customer_id,
  {headers: {
    "Content-Type": "application/json",
    Authorization: tokenRes.token!,
  }, }
  )  
  // if(response?.data?.data?.length === 0){
  //   message.info("No dashboards configured for this user!")
  // }
  return response.data.data as TDashboardList[]
}
