import { userTheme } from "./UserTheme";
  
  export const resetTheme = () => {
      const root = document.documentElement;
  
      Object.keys(userTheme.T001).forEach((key) => {
          root?.style.setProperty(
              key,
              userTheme.T001[key]
          );
      })
  }


//export const  refvar : any = 'hopev';
 export const  refvar : any = window.location.href.toString().replace("https://","").replace("http://","").split(".")[0]