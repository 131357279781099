import React, { PureComponent } from 'react';
import 'antd/dist/antd.css';
import { Form, Input, Button, message, Spin } from 'antd';
import { ReactComponent as ReactLogo } from "../../assets/motovolt_logo_for_splash_screen.svg"
import Cross from '../../assets/png/cross-vector.png'
import MotovoltLogo from '../../assets/motovolt_text.png'
import { signIn, initiateForgotPassword, forgotPasswordSubmit, signout } from "../../connectm-client/authentication"
import { withRouter, RouteComponentProps } from "react-router"
import { ReduxUserAction, ReduxUserState, mapDispatchToProps, mapStateToProps } from "../../connectm-client/actions/user"
import * as QueryString from "query-string"
import { connect } from 'react-redux'
import './index.scss'
import WebContent from "../webContentText.json"
import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
interface ForgotPasswordProps extends RouteComponentProps, ReduxUserAction, ReduxUserState { }
interface ForgotPasswordStates {
    formValid: string,
    valid: boolean,
    message: any,
    firstPassword: string,
    secondPassword: string,
    showLoader: boolean,
    showPasswordInitial:boolean,
    showPasswordFinal:boolean,
}
class ForgotPassword extends PureComponent<ForgotPasswordProps, ForgotPasswordStates> {
    constructor(props: ForgotPasswordProps) {
        super(props)
        this.state = {
            formValid: '',
            valid: false,
            message: '',
            firstPassword: '',
            secondPassword: '',
            showLoader: false,
            showPasswordInitial:false,
            showPasswordFinal:false
        }
    }

    componentDidMount() {
        console.log("hdj")
        // Logout existing user when the component mounts
        signout()
        .then(() => {
          this.props.usersAction({
            type: "UPDATE_USER",
            payload: {
              authenticated: false,
              user: null,
            },
          });
  
        //   this.props.history.push("/create-");
          localStorage.clear()
        })
    }
    validatePassword = (password: string) => {
        
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*&])[A-Za-z\d@$!%*&]{8,}$/;
       

        return regex.test(password);
   
    };
    onFinish = (values: any) => { 
        console.log("values")
        const params = QueryString.parse(this.props.location.search);
        if (!this.validatePassword(this.state.secondPassword) ) {
          
            this.setState({
                formValid: 'error',
                valid: !this.state.valid,
                message: <span> <img src={Cross} height="20px" alt="cross" /> &nbsp;Invalid password format.</span>
            });

            setTimeout(() => {
                // Clear the error message
                this.setState({
                    formValid: "",
                    valid:false,
                    message:""
                });
            }, 3000);
      
        }
    
        else if (this.state.secondPassword !== this.state.firstPassword) {
           
                this.setState({
                    formValid: 'error',
                    valid: !this.state.valid,
                    message: <span> <img src={Cross} height="20px" alt="cross" /> &nbsp;Passwords do not match.</span>
                })

                setTimeout(() => {
                    // Clear the error message
                    this.setState({
                        formValid: "",
                        valid:false,
                        message:""
                    });
                }, 3000);
          
           
        } else {
            this.setState({
                // showSuccessMessage: true, // Display success message
                showLoader: true, // Show the loader
            });
            forgotPasswordSubmit(String(params.user_name), String(params.confirmation_code), this.state.secondPassword)
                .then((forgotPasswordObj) => {
                    if (forgotPasswordObj.success) {
                        console.log(forgotPasswordObj , "forgo")
                        this.props.usersAction({
                            type: "UPDATE_USER",
                            payload: {
                                authenticated: false,
                                user: null
                            }
                        })
                        // this.setState({
                        //     formValid: 'success',
                        //     valid: !this.state.valid,
                        //     message: <span> <img src={Cross} height="20px" alt="cross" /> &nbsp;You can Login with your new password.</span>
                        // })
                      
                        message.success("Your login password has been reset. You will be redirected to login screen!...")
                        setTimeout(() => {
                            this.setState({
                                showLoader: false, // Set the state variable to false to hide the loader
                            });

                            // Redirect to the login page
                            this.props.history.push("/login");
                        },1000);
                        setTimeout(() => {
                            // Clear the error message
                            this.setState({
                                formValid: "",
                                valid:false,
                                message:""
                            });
                        }, 3000);
                      
                    }
                    else if(forgotPasswordObj.message === "Invalid code provided, please request a code again."){
                        setTimeout(() => {
                            this.setState({
                                showLoader: false, // Set the state variable to false to hide the loader
                            });

                            // Redirect to the login page
                            // this.props.history.push("/login");
                        }, 1000);
                        setTimeout(()=>{
                            this.setState({
                                formValid: 'error',
                                valid: !this.state.valid,
                                message: <span> <img src={Cross} height="20px" alt="cross" /> &nbsp;Reset link expired. Please try again.</span>
                            })
                        },1000)
                       
                       
                    }
                    else {
                           setTimeout(() => {
                            this.setState({
                                showLoader: false, // Set the state variable to false to hide the loader
                            });

                            // Redirect to the login page
                            // this.props.history.push("/login");
                        }, 1000);
                        setTimeout(()=>{
                            this.setState({
                                formValid: 'error',
                                valid: !this.state.valid,
                                message: <span> <img src={Cross} height="20px" alt="cross" /> &nbsp;Could not Reset Password.</span>
                            })
                        },1000)

                     
                      
                    }
                })
                setTimeout(() => {
                    // Clear the error message
                    this.setState({
                        formValid: "",
                        valid:false,
                        message:""
                    });
                }, 3000);
        }
    };
    updateFirstPassword = (event: any) => {
        this.setState({
            firstPassword: event.target.value
        })
    }

    updateSecondPassword = (event: any) => {
        this.setState({
            secondPassword: event.target.value
        })
    }

    render() {
        return (
            <div className="connectm-forgot">
                <div className="forgot-password-container">
                    <div className="login-image">
                        <div className="logo-title">
                            <div className="command-center"><span style={{ fontSize: "40px" }}>Forgot Password</span></div>
                            <div className="command-center"><span>Command Center</span></div>
                        </div>
                    </div>
                    <div className="login-form">
                        <div className={`notification-toast ${this.state.formValid}`}>
                            {this.state.message}
                        </div>
                        <div className={"form-body"}>
                            <div className="logo">
                            </div>
                            { this.state.showLoader === false  ? <div className="forgot-password-label">Enter a new password. The password should be at least 8 characters, a mix of capital, lowercase letters, number, and a special symbol. </div> : <div className="forgot-password-label" />}
                            {this.state.showLoader ?  (
                                <div
                                    style={{
                                        height: "40vh",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
       
                                        
                                        
                                    }}
                                >
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                className="loading"
                                                style={{ fontSize: 24 }}
                                                spin
                                            />
                                        }
                                    />

                                    <h1 className="loading-text" style={{ margin: "10px" }}>Loading.....</h1>
                                </div>
                            ) : (
                                <Form
                                    name="normal_login"
                                    className="ant-login-form"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={this.onFinish}>
                                   
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}>
                                        <Input
                                        type={this.state.showPasswordInitial? "text" : "password"}
                                        addonAfter={
                                            <Button
                                              type="link"
                                              onClick={() => this.setState({ showPasswordInitial: !this.state.showPasswordInitial })}
                                            >
                                              {this.state.showPasswordInitial ?  <EyeOutlined /> : <EyeInvisibleOutlined /> }
                                            </Button>
                                          }
                                            placeholder="New Password"
                                            value={this.state.firstPassword}
                                            onChange={this.updateFirstPassword} />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirmPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}>
                                        <Input
                                             type={this.state.showPasswordFinal ? "text" : "password"}
                                             addonAfter={
                                                 <Button
                                                   type="link"
                                                   onClick={() => this.setState({ showPasswordFinal: !this.state.showPasswordFinal })}
                                                 >
                                                   {this.state.showPasswordFinal ?  <EyeOutlined /> : <EyeInvisibleOutlined /> }
                                                 </Button>
                                               }
                                            placeholder="Confirm Password"
                                            value={this.state.secondPassword}
                                            onChange={this.updateSecondPassword}
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-form-button" style={{ color: "white" }} >
                                            Continue
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </div>
                    </div>
                </div>

                <div className="forgot-footer">
                    <div>
                        Copyright © {new Date().getFullYear()}. All rights reserved.
                    </div>
                    <div>
                        {WebContent.version}
                    </div>
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
