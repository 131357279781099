export const userTheme: { [key: string]: any } = {
  T001: {
    "--APP_BACKGROUND": "#2A3258",
    "--NAV_BAR_BACKGROUND": "#3C4888",
    "--NAV_BAR_AVATAR": "#acb4e0",
    "--LEFT_PANEL_BACKGROUND": "#303964",
    "--ALERT_TABLE_BACKGROUND": "#3b4473",
    "--LEFT_PANEL_BACKGROUND_HOVER": "#5767b8",
    "--LEFT_PANEL_BUTTON_BACKGROUND": "#303964",
    "--LEFT_PANEL_BUTTON_HOVER_COLOR": "#3c4888",
    "--ALERT_DETAIL_SINGLE_BACKGROUND": "#3b477a",
    "--TABLE_ROW_LIGHT": "#3c4473",
    "--TABLE_ROW_DARK": "#2d3456",
    "--TEXT_COLOR": "#fff",
    "--TREND_GRAPH": "#2d3456",
    "--TREND_GRAPH_BACKGROUND": "#2d3456",
    "--ACTIVE": "#fcc84a",
    "--APPLY_BUTTON": "#3fb3df",
    "--APPLY_BUTTON_TEXT": "#fff",
    "--RESET_BUTTON_TEXT": "#fff",
    "--GRAPH_HEADER_BACKGROUND": "#2d3456",
    "--GRAPH_BACKGROUND": "#131731",
    "--CRITICAL": "#b73e3e",
    "--MAJOR": "#b73e3e",
    "--MINOR": "#1890ff",
    "--MEDIUM": "#ebda05",
    "--LEFT_PANEL_WIDTH": "96px",
    "--TOP_HEADER_HEIGHT": "40px",
   
    "--TABLE_BACKGROUND": "#3c4473",
    "--DROPDOWN_BACKGROUND_COLOR": "#383e5f",
    "--ROW_HOVER": "#8699ff",
    "--MIS_BACKGROUND": "#2b3254",
    "--MIS_CONTAINER_BACKGROUND": "#363e69",
    "--MIS_ANALYSER_BUTTON": "#4d5fc1",
    "--MIS_CARD_BACKGROUND": "#00000033",
    "--LOGIN_FORM": "#FFFFFF",
    "--ADDITIONAL_INSIGHTS_BACKGROUND": "#3b477a",
    "--PAST_ALERT_BACKGROUND_HEADER": "#3b477a",
    "--MODAL_BACKGROUND": "#2A3258",
    "--MODAL_BODY_BACKGROUND": "#2A3258",
    "--MODAL_HEADER_BACKGROUND": "#3C4888",
    "--MODAL_TEXT": "#fff",
    "--DYNAMIC_TABLE_BACKGROUND": "#fff",
    "--DYANMIC_TABLE_ROW_LIGHT": "#e5e5e5",
    "--DYANMIC_TABLE_ROW_DARK": "rgba(0, 0, 0, 0.05)",
    "--DYNAMIC_TABLE_TEXT_COLOR": "black",
    '--DYNAMIC_TABLE_HEADER_BACKGROUND': "#fff",
    "--DYNAMIC_TABLE_HEADER_BACKFROUND": "#fff",
    "--RADIO_BUTTON_COLOR": "#3d487d",
    "--RADIO_BUTTON_HOVER": "#5767b8",
    "--RADIO_BUTTON_CLICKED": "#5767b8",
    "--DYNAMIC_DROPDOWN_BACKGROUND": "#383e5f",
    "--DYNAMIC_SELECT_DROPDOWN": "#3d487d",
    "--DYNAMIC_SELECT_DROPDOWN_TEXT": "#a5b8ff",
    "--DYNAMIC_TABLE_PAGINATION_BACKGROUND": "#303964",
    "--DYNAMIC_TABLE_PAGINATION_ITEM_BACKGROUND": "#3C4888",
    "--B2B_CARD": "#3d487d",
    "--B2B_CARD_TEXT": "#a5b8ff",
    // "--DYNAMIC_TABLE_PAGINATION_ITEM": "#3c4473",
    "--DYANMIC_TABLE_ROW_SELECTION": "#f3ffe6",
    "--CLEAR_ALERT_BUTTON": "#3fb3df",
    "--COLLAPSE": "#6d7cc2",
    "--COLLAPSE_COLOR": "#3f4b81",
    "--ACTIVE_TAB": "#fcc84a",
    "--DATE_PICKER_BACKGROUND": "#3d487d",
    "--DATE_PICKER_TEXT": "#a5b8ff",
    "--DATE_PICKER_CONTENT_HEADER": "#c28e2b",
    "--DATE_PICKER_FOOTER_TEXT": "#1890ff",
    "--DATE_PICKER_PANEL_BACKGROUND": "#3d487d",
    "--CUSTOM_DASHBOARD_CARD": "#3D4980",
    "--B2B_DROPDOWN_SELECT_ARROW": "#a5b8ff",
    '--HOME_CONTENT_SCREEN_WIDTH': "calc(100vw - 40px)",
    '--HOME_LEFT_CONTENT_WIDTH': "75%",
    '--HOME_RIGHT_CONTENT_WIDTH': "25%",
    '--HOME_CONTENT_TO_LEFT': "50px",
    '--LEFT_PANEL_BORDER_RADIUS': "0px",
    '--LEFT_PANEL_ICON_WIDTH': "47px",
    '--LEFT_PANEL_MARGIN_LEFT': "0px",
    '--LEFT_PANEL_BUTTON_MARGIN': "0px 0px",
    '--MIS_CARD_BODY_BACKGROUND': "#3b477a",
    '--MIS_CARD_TITLE_TEXT': "#a5b8ff",
    '--DYNAMIC_COMPONENT_WIDTH': "calc(100vw - 50px)",
    '--DYNAMIC_COMPONENT_CONTENT_LEFT': "48px",
    '--B2B_MAP_WIDTH': "100%",
    '--B2B_RIGHT_INFO_WIDTH': "26vw",
    '--ALERT_DETAIL_CONTAINER_WIDTH': "auto",
    '--MIS_WIDTH': "calc(100vw - 45px)",
    "--GREY_1": "rgba(0, 0, 0, 0.07)",
    "--ACTIVE_LEFT_PANEL_TAB": "2px solid #fcc84a",
    '--ZOOM_BUTTON': "2%",
    '--ALERT_HEADER_WIDTH': "22%",
    '--ALERT_HEADER_WIDTH_LEFT': "74%",
    '--DYNAMIC_TABLE_HEADER_TEXT': '#3f4b81',
    '--DYNAMIC_TABLE_ROW_BORDER': "1px solid #f0f0f0",
    '--DYNAMIC_TABLE_ROW_HEADER_BORDER': "1px solid #f0f0f0",
    '--DYNAMIC_TABLE_ROW_HEIGHT': "38px",
    '--DYNAMIC_COMPONENT_MARGIN': "5px 0px 0px 0px",
    '--DYNAMIC_COMPONENT_SUBHEADER_MARGIN': "0px",
    '--MODULE_DROPDOWN_MARGIN': "5px 1px 0px 0px",
    '--RADIO_BUTTON_WIDTH': "60px",
    '--RADIO_BUTTON_DIVIDER_BACKGROUND_COLOR': "#1890ff",
    '--RADIO_BUTTON_DIVIDER_HEIGHT': "0px",
    '--RADIO_BUTTON_DIVIDER_TOP_MARGIN': "0px",
    '--DYNAMIC_SELECT_TOP': "5px",
    '--BUTTON_MARGIN_LEFT': "-7px",
    '--BUTTON_HEIGHT': "30px",
    '--DYNAMIC_SELECT_HEIGHT': "32px",
    '--INPUT_TOP': "5px",
    '--INPUT_WIDTH': "200px",
    '--BUTTON_TOP': "5px",
    '--INPUT_HEIGHT': "",
    '--DYNAMIC_COMPONENT_MARGIN_TOP': "0px",
    '--INPUT_BACKGROUND_COLOR': "rgba(0, 0, 0, 0.3)",
    '--B2B_CONTAINER_PADDING': "0px",
    '--B2B_HEADER_TOP': "1px",
    '--B2B_INPUT_TOP': "10px",
    '--B2B_MAP_HEIGHT': "80vh",
    '--B2B_RADIO_BUTTON_WIDTH': "60px",
    '--B2B_RADIO_BUTTON_HEIGHT': "100%",
    '--B2B_TABLE_WIDTH': "100%",
    '--LEFT_PANEL_TEXT_COLOR': "#fff",
    '--DATE_FILTER_HEIGHT': "",
    '--GRID_CONTAINER_BACKGROUND': "#07080b",
    '--DYNAMIC_TABLE_HEIGHT': "calc(100vh - 140px)",
    '--SCROLLBAR_COLOR': "#fcc84a",
    '--B2B_TABLE_HEIGHT': "calc(100vh - 140px)",
    '--B2B_TABLE_WIDTH_CUSTOME': "calc(99vw - 40px)",
    '--B2B_TABLE_PAGINATION': "#303964",
    '--DYNAMIC_SELECT_BACKGROUND': "rgb(31, 31, 31)",
    '--DYNAMIC_SELECT_TOP1': "3px",
    '--DYNAMIC_SELECT_WIDTH': "150px",
    '--DYNAMIC_SELECT_PADDING': "",
    '--DYNAMIC_SEARCH_BACKGROUND_COLOR': "rgba(0, 0, 0, 0)",
    '--POLICY_SEARCH_BACKGROUND_COLOR': "rgba(0, 0, 0, 0.3)",
    '--LOADING_COLOR': "#ffae00",
    '--REFRESH_BUTTON_HEIGHT': "30px",
    '--WEBHEADER_MARGIN_LEFT': "0px",
    '--DATE_PICKER_TOP': "3px",
    '--ADD_BUTTON_TOP': "3px",
    '--ADD_BUTTON_MARGIN_LEFT': "1px",
    '--ADD_BUTTON_BACKGROUND_COLOR': "#fff",
    '--FILTER_BUTTON_TOP': "3px",
    '--FILTER_BUTTON_RIGHT': "2px",
    '--EDIT_BUTTON_MARGIN_LEFT': "1px",
    '--FILTER_DYNAMIC_BUTTON_TOP': "3px",
    '--APPLY_BUTTON_HEADER_COLOR': "#2A3258",
    '--RESET_BUTTON_HEADER_COLOR': "#2A3258",
    '--SHOWING_BUTTON_HEADER_COLOR': "#fff",
    '--APPLY_BUTTON_BORDER': "1px solid gray",
    '--CLEAR_BUTTON_BORDER': "none",
    '--BUTTON_TEXT_COLOR': "#595959",
    '--SHOWING_BUTTON_TEXT_COLOR': "#595959",
    '--TREND_DROPDOWN_ACTIVE': "1px solid #fcc84a",
    '--DYNAMIC_TABLE_HEADER_FONT_FAMILY': "Roboto, sans-serif",
    '--DYNAMIC_TABLE_BODY_FONT_FAMILY': "Roboto, sans-serif",
    '--QUICKSIGHT_COMPONENT_MARGIN': "0px",
    '--QUICKSIGHT_COMPONENT_WIDTH': "calc(100vw - 50px)",
    '--QUICKSIGHT_COMPONENT_MIN_HEIGHT': "100vh", 
    '--SIDE_COLLAPSE_PANEL_BACKGROUND': "#3C4888",
    '--SELECT_BORDER': "none",
    '--PAGINATION_TEXT_COLOR': "#fff",
    '--FORM_SELECT_COLOR': "#3d487d",
    '--FORM_SELECT_TEXT': "#fff",
    '--FORM_SELECT_ARROW': "#fff",
    '--SEARCH_ICON': "#fff",
    '--TRIP_RANGE_PICKER': "",
    '--B2B_DRAWER_CARD_BACKGROUND': "rgb(60, 72, 136)",
    '--SEARCH_PLACEHOLDER': "#fff",
    '--LOADING_TEXT': "#fff",
    '--ALERT_DETAIL_TEXT': "#fff",
    '--SELECT_FILTER_AFTER_SELECTION_BORDER': "none",
    '--B2B_SELECT_SEARCH_COLOR': "#fff",
    '--DYNAMIC_DRAWER_BACKGROUND': "#303964",
    '--DYNAMIC_FORM_SELECT_BACKGROUND': "#3d487d",
    '--THEME_TYPE': "T001",
    '--B2B_SEARCH_BACKGROUND': "rgba(0, 0, 0, 0.3)",
    '--RADIO_BUTTON_BORDER': "none",
    '--B2B_BUTTON_HEIGHT': "32px",
    '--B2B_ARROW_BUTTON_HEIGHT':"32px",
    '--COLLAPSE_LIST_BOTTOM_BORDER': "1px solid #f0f0f02e",
    '--DRAWER_HEADER_FONT_WEIGHT': "500",
    '--TRIP_DETAILS_BUTTON_HEIGHT': "30px",
    '--B2B_CONTENT_MARGIN_LEFT': "0px",
    '--TRIP_INSIGHTS_DRAWER_WIDTH': "100%",
    '--ALERT_PAGINATION_ACTIVE': "#C7C7C7",
    '--ALERT_PAGINATION_INACTIVE': "gray",
    // '--INPUT_MEDIA_QUERY_TOP': "5px",
    // '--$INPUT_MEDIA_QUERY_RIGHT': "0px",
  },
  T003: {
    "--APP_BACKGROUND": "#FFFFFF",
    "--NAV_BAR_BACKGROUND": "#e7e6e6",
    "--NAV_BAR_AVATAR": "#2B9FCF",
    "--LEFT_PANEL_BACKGROUND": "#2B9FCF",
    "--ALERT_TABLE_BACKGROUND": "#fff",
    "--LEFT_PANEL_BACKGROUND_HOVER": "#0079AC",
    "--LEFT_PANEL_BUTTON_BACKGROUND": "#2B9FCF",
    "--LEFT_PANEL_BUTTON_HOVER_COLOR": "#2B9FCF",
    "--ALERT_DETAIL_SINGLE_BACKGROUND": "#EEFBFF",
    "--TABLE_ROW_LIGHT": "#EEFBFF",
    "--TABLE_ROW_DARK": "#fff",
    "--TEXT_COLOR": "#595959",
    "--TREND_GRAPH": "#2d3456",
    "--TREND_GRAPH_BACKGROUND": "#2d3456",
    "--ACTIVE": "#595959",
    "--APPLY_BUTTON": "#3fb3df",
    "--APPLY_BUTTON_TEXT": "#0A0A0A",
    "--RESET_BUTTON_TEXT": "#595959",
    "--GRAPH_HEADER_BACKGROUND": "#EEFBFF",
    "--GRAPH_BACKGROUND": "#fff",
    "--CRITICAL": "#b73e3e",
    "--MAJOR": "#f88828",
    "--MINOR": "#ebda05",
    "--LEFT_PANEL_WIDTH": "96px",
    "--TOP_HEADER_HEIGHT": "40px",
    "--TABLE_BACKGROUND": "#2B9FCF",
    "--DROPDOWN_BACKGROUND_COLOR": "#2B9FCF",
    "--ROW_HOVER": "#ADD8E6",
    "--MIS_BACKGROUND": "#2b3254",
    "--MIS_CONTAINER_BACKGROUND": "#363e69",
    "--MIS_ANALYSER_BUTTON": "#2B9FCF",
    "--LOGIN_FORM": "#EEFBFF",
    "--MIS_CARD_BACKGROUND": "#00000033",
    "--ADDITIONAL_INSIGHTS_BACKGROUND": "#EEFBFF",
    "--PAST_ALERT_BACKGROUND_HEADER": "#EEFBFF",
    "--MODAL_BACKGROUND": "#2B9FCF",
    "--MODAL_BODY_BACKGROUND": "#079AC0",
    "--MODAL_HEADER_BACKGROUND": "#079AC0",
    "--MODAL_TEXT": "#fff",
    "--DYNAMIC_TABLE_BACKGROUND": "#fff",
    "--DYANMIC_TABLE_ROW_LIGHT": "#fff",
    "--DYANMIC_TABLE_ROW_DARK": "#EEFBFF",
    "--DYNAMIC_TABLE_TEXT_COLOR": "#595959",
    '--DYNAMIC_TABLE_HEADER_BACKGROUND': "#EEFBFF",
    "--DYNAMIC_TABLE_HEADER_BACKFROUND": "#fff",
    "--RADIO_BUTTON_COLOR": "#fff",
    "--RADIO_BUTTON_HOVER": "#e7e6e6",
    "--RADIO_BUTTON_CLICKED": "#e7e6e6",
    "--DYNAMIC_DROPDOWN_BACKGROUND": "#2B9FCF",
    "--DYNAMIC_SELECT_DROPDOWN": "#fff",
    "--DYNAMIC_SELECT_DROPDOWN_TEXT": "#595959",
    "--DYNAMIC_TABLE_PAGINATION_BACKGROUND": "#fff",
    "--DYNAMIC_TABLE_PAGINATION_ITEM_BACKGROUND": "#fff",
    "--B2B_CARD": "#0079AC",
    "--B2B_CARD_TEXT": "#fff",
    // "--DYNAMIC_TABLE_PAGINATION_ITEM": "#3c4473",
    "--DYANMIC_TABLE_ROW_SELECTION": "#f3ffe6",
    "--CLEAR_ALERT_BUTTON": "#fff",
    "--COLLAPSE": "#fff",
    "--COLLAPSE_COLOR": "#3f4b81",
    "--ACTIVE_TAB": "#fff",
    "--DATE_PICKER_BACKGROUND": "#fff",
    "--DATE_PICKER_TEXT": "#595959",
    "--DATE_PICKER_CONTENT_HEADER": "#fff",
    "--DATE_PICKER_FOOTER_TEXT": "#1890ff",
    "--DATE_PICKER_PANEL_BACKGROUND": "#2B9FCF",
    "--CUSTOM_DASHBOARD_CARD": "#3D4980",
    "--B2B_DROPDOWN_SELECT_ARROW": "#595959",
    '--HOME_CONTENT_SCREEN_WIDTH': "calc(100vw - 40px)",
    '--HOME_LEFT_CONTENT_WIDTH': "75%",
    '--HOME_RIGHT_CONTENT_WIDTH': "25%",
    '--HOME_CONTENT_TO_LEFT': "50px",
    '--LEFT_PANEL_BORDER_RADIUS': "0px",
    '--LEFT_PANEL_ICON_WIDTH': "47px",
    '--LEFT_PANEL_MARGIN_LEFT': "0px",
    '--LEFT_PANEL_BUTTON_MARGIN': "0px 0px",
    '--MIS_CARD_BODY_BACKGROUND': "#2B9FCF",
    '--MIS_CARD_TITLE_TEXT': "#fff",
    '--DYNAMIC_COMPONENT_WIDTH': "calc(100vw - 50px)",
    '--DYNAMIC_COMPONENT_CONTENT_LEFT': "48px",
    '--B2B_MAP_WIDTH': "100%",
    '--B2B_RIGHT_INFO_WIDTH': "26vw",
    '--ALERT_DETAIL_CONTAINER_WIDTH': "auto",
    '--MIS_WIDTH': "calc(100vw - 45px)",
    "--GREY_1": "rgba(0, 0, 0, 0.07)",
    "--ACTIVE_LEFT_PANEL_TAB": "2px solid #595959",
    '--ZOOM_BUTTON': "2%",
    '--ALERT_HEADER_WIDTH': "22%",
    '--ALERT_HEADER_WIDTH_LEFT': "74%",
    '--DYNAMIC_TABLE_HEADER_TEXT': '#595959',
    '--DYNAMIC_TABLE_ROW_BORDER': "1px solid #f0f0f0",
    '--DYNAMIC_TABLE_ROW_HEADER_BORDER': "1px solid #f0f0f0",
    '--DYNAMIC_TABLE_ROW_HEIGHT': "38px",
    '--DYNAMIC_COMPONENT_MARGIN': "5px 0px 0px 0px",
    '--DYNAMIC_COMPONENT_SUBHEADER_MARGIN': "0px",
    '--MODULE_DROPDOWN_MARGIN': "6px 1px 0px 1px",
    '--RADIO_BUTTON_WIDTH': "60px",
    '--RADIO_BUTTON_DIVIDER_BACKGROUND_COLOR': "#1890ff",
    '--RADIO_BUTTON_DIVIDER_HEIGHT': "0px",
    '--RADIO_BUTTON_DIVIDER_TOP_MARGIN': "0px",
    '--DYNAMIC_SELECT_TOP': "5px",
    '--BUTTON_MARGIN_LEFT': "-7px",
    '--BUTTON_HEIGHT': "32px",
    '--DYNAMIC_SELECT_HEIGHT': "34px",
    '--INPUT_TOP': "5px",
    '--INPUT_WIDTH': "200px",
    '--BUTTON_TOP': "5px",
    '--INPUT_HEIGHT': "",
    '--DYNAMIC_COMPONENT_MARGIN_TOP': "0px",
    '--INPUT_BACKGROUND_COLOR': "#fff",
    '--B2B_CONTAINER_PADDING': "0px",
    '--B2B_HEADER_TOP': "1px",
    '--B2B_INPUT_TOP': "10px",
    '--B2B_MAP_HEIGHT': "80vh",
    '--B2B_RADIO_BUTTON_WIDTH': "60px",
    '--B2B_RADIO_BUTTON_HEIGHT': "100%",
    '--B2B_TABLE_WIDTH': "100%",
    '--LEFT_PANEL_TEXT_COLOR': "#fff",
    '--DATE_FILTER_HEIGHT': "32px",
    '--GRID_CONTAINER_BACKGROUND': "#07080b",
    '--DYNAMIC_TABLE_HEIGHT': "calc(100vh - 140px)",
    '--SCROLLBAR_COLOR': "#e0d5d5",
    '--B2B_TABLE_HEIGHT': "calc(100vh - 140px)",
    '--B2B_TABLE_WIDTH_CUSTOME': "calc(99vw - 40px)",
    '--B2B_TABLE_PAGINATION': "#303964",
    '--DYNAMIC_SELECT_BACKGROUND': "#fff",
    '--DYNAMIC_SELECT_TOP1': "3px",
    '--DYNAMIC_SELECT_WIDTH': "150px",
    '--DYNAMIC_SELECT_PADDING': "0px",
    '--DYNAMIC_SEARCH_BACKGROUND_COLOR': "rgba(0, 0, 0, 0)",
    '--POLICY_SEARCH_BACKGROUND_COLOR': "#fff",
    '--LOADING_COLOR': "#303964",
    '--REFRESH_BUTTON_HEIGHT': "32px",
    '--WEBHEADER_MARGIN_LEFT': "0px",
    '--DATE_PICKER_TOP': "3px",
    '--ADD_BUTTON_TOP': "3px",
    '--ADD_BUTTON_MARGIN_LEFT': "1px",
    '--ADD_BUTTON_BACKGROUND_COLOR': "#fff",
    '--FILTER_BUTTON_TOP': "3px",
    '--FILTER_BUTTON_RIGHT': "2px",
    '--EDIT_BUTTON_MARGIN_LEFT': "1px",
    '--FILTER_DYNAMIC_BUTTON_TOP': "3px",
    '--APPLY_BUTTON_HEADER_COLOR': "#fff",
    '--RESET_BUTTON_HEADER_COLOR': "#fff",
    '--SHOWING_BUTTON_HEADER_COLOR': "#fff",
    '--APPLY_BUTTON_BORDER': "1px solid gray",
    '--CLEAR_BUTTON_BORDER': "1px solid gray",
    '--BUTTON_TEXT_COLOR': "#595959",
    '--SHOWING_BUTTON_TEXT_COLOR': "#595959",
    '--TREND_DROPDOWN_ACTIVE': "1px solid #fcc84a",
    '--DYNAMIC_TABLE_HEADER_FONT_FAMILY': "Roboto, sans-serif",
    '--DYNAMIC_TABLE_BODY_FONT_FAMILY': "Roboto, sans-serif",
    '--QUICKSIGHT_COMPONENT_MARGIN': "0px",
    '--QUICKSIGHT_COMPONENT_WIDTH': "calc(100vw - 50px)",
    '--QUICKSIGHT_COMPONENT_MIN_HEIGHT': "100vh", 
    '--SIDE_COLLAPSE_PANEL_BACKGROUND': "#EEFBFF",
    '--SELECT_BORDER': "1px solid #e0d5d5",
    '--PAGINATION_TEXT_COLOR': "#595959",
    '--FORM_SELECT_COLOR': "#0079ac",
    '--FORM_SELECT_TEXT': "#fff",
    '--FORM_SELECT_ARROW': "#fff",
    '--SEARCH_ICON': "#e0d5d5",
    '--TRIP_RANGE_PICKER': "#fff",
    '--B2B_DRAWER_CARD_BACKGROUND': "#EEFBFF",
    '--SEARCH_PLACEHOLDER': "#595959",
    '--LOADING_TEXT': "#595959",
    '--ALERT_DETAIL_TEXT': "#595959",
    '--SELECT_FILTER_AFTER_SELECTION_BORDER': "1px solid #595959",
    '--B2B_SELECT_SEARCH_COLOR': "#595959",
    '--DYNAMIC_DRAWER_BACKGROUND': "#fff",
    '--DYNAMIC_FORM_SELECT_BACKGROUND': "#fff",
    '--THEME_TYPE': "T003",
    '--B2B_SEARCH_BACKGROUND': "#fff",
    '--RADIO_BUTTON_BORDER': "1px solid #e0d5d5",
    '--B2B_BUTTON_HEIGHT': "32px",
    '--B2B_ARROW_BUTTON_HEIGHT':"33px",
    '--COLLAPSE_LIST_BOTTOM_BORDER': "1px solid #e0d5d5",
    '--DRAWER_HEADER_FONT_WEIGHT': "900",
    '--TRIP_DETAILS_BUTTON_HEIGHT': "30px",
    '--B2B_CONTENT_MARGIN_LEFT': "0px",
    '--TRIP_INSIGHTS_DRAWER_WIDTH': "100%",
    '--ALERT_PAGINATION_ACTIVE': "gray",
    '--ALERT_PAGINATION_INACTIVE': "#C7C7C7",

    // '--INPUT_MEDIA_QUERY_TOP': "5px",
    // '--$INPUT_MEDIA_QUERY_RIGHT': "0px",
  },
  T002: {
     '--TOP_MARGIN': "-60px",
    '--APP_BACKGROUND': "#0A0A0A",
    '--NAV_BAR_BACKGROUND': "#1F1F1F",
    '--NAV_BAR_AVATAR': "#0A0A0A",
    '--LEFT_PANEL_BACKGROUND': "rgba(16, 16, 16, 0.9)",
    '--ALERT_TABLE_BACKGROUND': "#020202",
    '--LEFT_PANEL_BACKGROUND_HOVER': "#00EAFF",
    '--LEFT_PANEL_BUTTON_BACKGROUND': "rgba(16, 16, 16, 0.9)",
    "--LEFT_PANEL_BUTTON_HOVER_COLOR": "#1F1F1F",
    '--ALERT_DETAIL_SINGLE_BACKGROUND': "#1F1F1F",
    '--TABLE_ROW_LIGHT': "#1F1F1F",
    '--TABLE_ROW_DARK': "#0A0A0A",
    "--TEXT_COLOR": "#fff",
    '--TREND_GRAPH': "black",
    '--TREND_GRAPH_BACKGROUND': "#1F1F1F",
    '--ACTIVE': "#fff",
    '--APPLY_BUTTON': "#1F1F1F",
    '--APPLY_BUTTON_TEXT': "#fff",
    "--RESET_BUTTON_TEXT": "#fff",
    "--GRAPH_HEADER_BACKGROUND": "#0A0A0A",
    '--GRAPH_BACKGROUND': "#1F1F1F",
    '--CRITICAL': "#0A0A0A",
    '--MAJOR': "#fff",
    '--MINOR': "#fff",
    '--LEFT_PANEL_WIDTH': "96px",
    '--TOP_HEADER_HEIGHT': "40px",
    '--TABLE_BACKGROUND': "#1F1F1F",
    '--DROPDOWN_BACKGROUND_COLOR': "#0A0A0A",
    '--ROW_HOVER': "#00EAFF",
    '--MIS_BACKGROUND': "#0A0A0A",
    '--MIS_CONTAINER_BACKGROUND': "#0A0A0A",
    '--MIS_ANALYSER_BUTTON': "#0A0A0A",
    '--MIS_CARD_BACKGROUND': "#0A0A0A",
    '--ADDITIONAL_INSIGHTS_BACKGROUND': "#1F1F1F",
    '--PAST_ALERT_BACKGROUND_HEADER': "#1F1F1F",
    '--MODAL_BACKGROUND': "#1F1F1F",
    '--MODAL_BODY_BACKGROUND': "#0A0A0A",
    "--MODAL_HEADER_BACKGROUND": "#1F1F1F",
    "--MODAL_TEXT": "#fff",
    '--DYNAMIC_TABLE_BACKGROUND': "#0A0A0A",
    '--DYANMIC_TABLE_ROW_LIGHT': "#0A0A0A",
    '--DYANMIC_TABLE_ROW_DARK': "#0A0A0A",
    '--DYNAMIC_TABLE_TEXT_COLOR': "#fff",
    '--DYNAMIC_TABLE_HEADER_BACKGROUND': "#0A0A0A",
    '--DYNAMIC_TABLE_HEADER_BACKFROUND': "#1F1F1F",
    '--RADIO_BUTTON_COLOR': "#1F1F1F",
    '--RADIO_BUTTON_HOVER': "#00EAFF",
    '--RADIO_BUTTON_CLICKED': "#1F1F1F",
    '--DYNAMIC_DROPDOWN_BACKGROUND': "#1F1F1F",
    '--DYNAMIC_SELECT_DROPDOWN': "#1F1F1F",
    '--DYNAMIC_SELECT_DROPDOWN_TEXT': "#fff",
    '--DYNAMIC_TABLE_PAGINATION_BACKGROUND': "rgba(16, 16, 16, 0.9)",
    '--DYNAMIC_TABLE_PAGINATION_ITEM_BACKGROUND': "#0A0A0A",
    '--B2B_CARD': "#1F1F1F",
    '--B2B_CARD_TEXT': "#fff",
    // '--DYNAMIC_TABLE_PAGINATION_ITEM':"#fff",
    '--DYANMIC_TABLE_ROW_SELECTION': "rgba(16, 16, 16, 0.9)",
    '--CLEAR_ALERT_BUTTON': "#0A0A0A",
    '--COLLAPSE': "#0A0A0A",
    '--COLLAPSE_COLOR': "#fff",
    '--ACTIVE_TAB': "#fff",
    '--DATE_PICKER_BACKGROUND': "#1F1F1F",
    '--DATE_PICKER_TEXT': "#fff",
    '--DATE_PICKER_CONTENT_HEADER': "#fff",
    '--DATE_PICKER_FOOTER_TEXT': "#fff",
    "--DATE_PICKER_PANEL_BACKGROUND": "#1F1F1F",
    '--CUSTOM_DASHBOARD_CARD': "#1F1F1F",
    '--B2B_DROPDOWN_SELECT_ARROW': "#595959",
    '--HOME_CONTENT_SCREEN_WIDTH': "calc(96vw - (96px + 48px))",
    '--HOME_LEFT_CONTENT_WIDTH': "75%",
    '--HOME_RIGHT_CONTENT_WIDTH': "25%",
    '--HOME_CONTENT_TO_LEFT': "200px",
    '--LEFT_PANEL_BORDER_RADIUS': "16px",
    '--LEFT_PANEL_ICON_WIDTH': "200px",
    '--LEFT_PANEL_MARGIN_LEFT': "10px",
    '--LEFT_PANEL_BUTTON_MARGIN': "0px 10px",
    '--MIS_CARD_BODY_BACKGROUND': "#1F1F1F",
    '--MIS_CARD_TITLE_TEXT': "#fff",
    '--DYNAMIC_COMPONENT_WIDTH': "calc(96vw - (96px + 48px))",
    '--DYNAMIC_COMPONENT_CONTENT_LEFT': "200px",
    '--B2B_MAP_WIDTH': "80%",
    '--B2B_RIGHT_INFO_WIDTH': "21vw",
    '--ALERT_DETAIL_CONTAINER_WIDTH': "calc(94vw - (96px + 48px))",
    '--MIS_WIDTH': "calc(96vw - (96px + 48px))",
    '--GREY_1': "#0A0A0A",
    "--ACTIVE_LEFT_PANEL_TAB": "#00EAFF",
    '--ZOOM_BUTTON': "20%",
    '--ALERT_HEADER_WIDTH': "20%",
    '--ALERT_HEADER_WIDTH_LEFT': "70%",
    '--DYNAMIC_TABLE_HEADER_TEXT': '#fff',
    '--DYNAMIC_TABLE_ROW_BORDER': "0.5px solid rgba(255, 255, 255, 0.5)",
    '--DYNAMIC_TABLE_ROW_HEADER_BORDER': "none",
    '--DYNAMIC_TABLE_ROW_HEIGHT': "56px",
    '--DYNAMIC_COMPONENT_MARGIN': "-5px 50px 10px 50px",
    '--DYNAMIC_COMPONENT_SUBHEADER_MARGIN': "-30px 40px 10px 60px",
    '--MODULE_DROPDOWN_MARGIN': "3px 0px 0px 0px",
    '--RADIO_BUTTON_WIDTH': "100px",
    '--RADIO_BUTTON_DIVIDER_BACKGROUND_COLOR': "rgba(255, 255, 255, 0.5)",
    '--RADIO_BUTTON_DIVIDER_HEIGHT': "18px",
    '--RADIO_BUTTON_DIVIDER_TOP_MARGIN': "9px",
    '--DYNAMIC_SELECT_TOP': "2px",
    '--BUTTON_MARGIN_LEFT': "-6px",
    '--BUTTON_HEIGHT': "35px",
    '--DYNAMIC_SELECT_HEIGHT': "100%",
    '--INPUT_TOP': "-40px",
    '--INPUT_WIDTH': "900px",
    '--BUTTON_TOP': "-40px",
    '--INPUT_HEIGHT': "40px",
    '--DYNAMIC_COMPONENT_MARGIN_TOP': "50px",
    '--INPUT_BACKGROUND_COLOR': "#1F1F1F",
    '--B2B_CONTAINER_PADDING': "10px 50px 10px 50px",
    '--B2B_HEADER_TOP': "-40px",
       
    '--B2B_INPUT_TOP': "-10px",
    '--B2B_MAP_HEIGHT': "73vh",
    '--B2B_RADIO_BUTTON_WIDTH': "60px",
    '--B2B_RADIO_BUTTON_HEIGHT': "40px",
    '--B2B_TABLE_WIDTH': "80%",
    '--LEFT_PANEL_TEXT_COLOR': "#87888C",
    '--DATE_FILTER_HEIGHT': "40px",
    '--GRID_CONTAINER_BACKGROUND': "#0A0A0A",
    '--DYNAMIC_TABLE_HEIGHT': "calc(88vh - 140px)",
    '--SCROLLBAR_COLOR': "#fff",
    '--B2B_TABLE_HEIGHT': "calc(96vh - 140px)",
    '--B2B_TABLE_WIDTH_CUSTOME': "90%",
    '--B2B_TABLE_PAGINATION': "#1F1F1F",
    '--DYNAMIC_SELECT_BACKGROUND': "rgb(31, 31, 31)",
    '--DYNAMIC_SELECT_TOP1': "1.5px",
    '--DYNAMIC_SELECT_WIDTH': "fit-content",
    '--DYNAMIC_SELECT_PADDING': "5px",
    '--DYNAMIC_SEARCH_BACKGROUND_COLOR': "#0000",
    '--POLICY_SEARCH_BACKGROUND_COLOR': "#1F1F1F",
    '--LOADING_COLOR': "#fff",
    '--REFRESH_BUTTON_HEIGHT': "35px",
    '--WEBHEADER_MARGIN_LEFT': "0px",
    '--DATE_PICKER_TOP': "1px",
    '--ADD_BUTTON_TOP': "2px",
    '--ADD_BUTTON_MARGIN_LEFT': "2px",
    '--ADD_BUTTON_BACKGROUND_COLOR': "#fff",
    '--FILTER_BUTTON_TOP': "0px",
    '--FILTER_BUTTON_RIGHT': "0px",
    '--EDIT_BUTTON_MARGIN_LEFT': "0px",
    '--FILTER_DYNAMIC_BUTTON_TOP': "2px",
    '--APPLY_BUTTON_HEADER_COLOR': "#0A0A0A",
    '--RESET_BUTTON_HEADER_COLOR': "#0A0A0A",
    '--SHOWING_BUTTON_HEADER_COLOR': "#fff",
    '--APPLY_BUTTON_BORDER': "1px solid white",
    '--CLEAR_BUTTON_BORDER': "1px solid white",
    '--BUTTON_TEXT_COLOR': "#595959",
    '--SHOWING_BUTTON_TEXT_COLOR': "#0A0A0A",
    '--TREND_DROPDOWN_ACTIVE': "1px solid #ffffff",
    '--DYNAMIC_TABLE_HEADER_FONT_FAMILY': "Raleway",
    '--DYNAMIC_TABLE_BODY_FONT_FAMILY': "Lato",
    '--QUICKSIGHT_COMPONENT_MARGIN': "5px 50px 10px 50px",
    '--QUICKSIGHT_COMPONENT_WIDTH': "calc(96vw - (190px + 48px))",
    '--QUICKSIGHT_COMPONENT_MIN_HEIGHT': "72vh",
    '--SIDE_COLLAPSE_PANEL_BACKGROUND': "#1F1F1F",
    '--SELECT_BORDER': "none",
    '--PAGINATION_TEXT_COLOR': "#fff",
    '--FORM_SELECT_COLOR': "#079AC0",
    '--FORM_SELECT_TEXT': "#fff",
    '--FORM_SELECT_ARROW': "#fff",
    '--SEARCH_ICON': "#fff",
    '--TRIP_RANGE_PICKER': "#1f1f1f",
    '--B2B_DRAWER_CARD_BACKGROUND': "#0A0A0A",
    '--SEARCH_PLACEHOLDER': "#000000",
    '--LOADING_TEXT': "#fff",
    '--ALERT_DETAIL_TEXT': "#fff",
    '--SELECT_FILTER_AFTER_SELECTION_BORDER': "none",
    '--B2B_SELECT_SEARCH_COLOR': "#fff",
    '--DYNAMIC_DRAWER_BACKGROUND': "rgba(16, 16, 16, 0.9)",
    '--DYNAMIC_FORM_SELECT_BACKGROUND': "#1F1F1F",
    '--THEME_TYPE': "T002",
    '--B2B_SEARCH_BACKGROUND': "#fff",
    '--RADIO_BUTTON_BORDER': "none",
    '--B2B_BUTTON_HEIGHT': "32px",
    '--B2B_ARROW_BUTTON_HEIGHT':"35px",
    '--COLLAPSE_LIST_BOTTOM_BORDER': "1px solid #e0d5d5",
    '--DRAWER_HEADER_FONT_WEIGHT': "500",
    '--TRIP_DETAILS_BUTTON_HEIGHT': "30px",
    '--B2B_CONTENT_MARGIN_LEFT': "45px",
    '--TRIP_INSIGHTS_DRAWER_WIDTH': "88%",
    '--ALERT_PAGINATION_ACTIVE': "#C7C7C7",
    '--ALERT_PAGINATION_INACTIVE': "gray",
    // '--INPUT_MEDIA_QUERY_TOP': "-54px",
    // '--INPUT_MEDIA_QUERY_RIGHT': "420px",
  },
}