/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOCPPTag = /* GraphQL */ `
  mutation CreateOCPPTag(
    $input: CreateOCPPTagInput!
    $condition: ModelOCPPTagConditionInput
  ) {
    createOCPPTag(input: $input, condition: $condition) {
      id
      idTag
      parentIdTag
      expiryDateTime
      inTransaction
      isBlocked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOCPPTag = /* GraphQL */ `
  mutation UpdateOCPPTag(
    $input: UpdateOCPPTagInput!
    $condition: ModelOCPPTagConditionInput
  ) {
    updateOCPPTag(input: $input, condition: $condition) {
      id
      idTag
      parentIdTag
      expiryDateTime
      inTransaction
      isBlocked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOCPPTag = /* GraphQL */ `
  mutation DeleteOCPPTag(
    $input: DeleteOCPPTagInput!
    $condition: ModelOCPPTagConditionInput
  ) {
    deleteOCPPTag(input: $input, condition: $condition) {
      id
      idTag
      parentIdTag
      expiryDateTime
      inTransaction
      isBlocked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomers = /* GraphQL */ `
  mutation CreateCustomers(
    $input: CreateCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    createCustomers(input: $input, condition: $condition) {
      id
      customer_name
      customer_id
      mobile_number
      email_id
      date_created
      end_of_contract
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomers = /* GraphQL */ `
  mutation UpdateCustomers(
    $input: UpdateCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    updateCustomers(input: $input, condition: $condition) {
      id
      customer_name
      customer_id
      mobile_number
      email_id
      date_created
      end_of_contract
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomers = /* GraphQL */ `
  mutation DeleteCustomers(
    $input: DeleteCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    deleteCustomers(input: $input, condition: $condition) {
      id
      customer_name
      customer_id
      mobile_number
      email_id
      date_created
      end_of_contract
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWebComponents = /* GraphQL */ `
  mutation CreateWebComponents(
    $input: CreateWebComponentsInput!
    $condition: ModelWebComponentsConditionInput
  ) {
    createWebComponents(input: $input, condition: $condition) {
      id
      comp_name
      comp_type
      comp_id
      parent_id
      comp_image
      display_name
      trigger_command
      module_id
      data_source
      data_key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWebComponents = /* GraphQL */ `
  mutation UpdateWebComponents(
    $input: UpdateWebComponentsInput!
    $condition: ModelWebComponentsConditionInput
  ) {
    updateWebComponents(input: $input, condition: $condition) {
      id
      comp_name
      comp_type
      comp_id
      parent_id
      comp_image
      display_name
      trigger_command
      module_id
      data_source
      data_key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWebComponents = /* GraphQL */ `
  mutation DeleteWebComponents(
    $input: DeleteWebComponentsInput!
    $condition: ModelWebComponentsConditionInput
  ) {
    deleteWebComponents(input: $input, condition: $condition) {
      id
      comp_name
      comp_type
      comp_id
      parent_id
      comp_image
      display_name
      trigger_command
      module_id
      data_source
      data_key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSubModule = /* GraphQL */ `
  mutation CreateSubModule(
    $input: CreateSubModuleInput!
    $condition: ModelSubModuleConditionInput
  ) {
    createSubModule(input: $input, condition: $condition) {
      id
      module_id
      comp_type
      data_source
      onClick
      isRequired
      ignoreColumns
      comp_order
      fromAmplify
      ignoreDataSource
      label
      comp_name
      editable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSubModule = /* GraphQL */ `
  mutation UpdateSubModule(
    $input: UpdateSubModuleInput!
    $condition: ModelSubModuleConditionInput
  ) {
    updateSubModule(input: $input, condition: $condition) {
      id
      module_id
      comp_type
      data_source
      onClick
      isRequired
      ignoreColumns
      comp_order
      fromAmplify
      ignoreDataSource
      label
      comp_name
      editable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSubModule = /* GraphQL */ `
  mutation DeleteSubModule(
    $input: DeleteSubModuleInput!
    $condition: ModelSubModuleConditionInput
  ) {
    deleteSubModule(input: $input, condition: $condition) {
      id
      module_id
      comp_type
      data_source
      onClick
      isRequired
      ignoreColumns
      comp_order
      fromAmplify
      ignoreDataSource
      label
      comp_name
      editable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createModule = /* GraphQL */ `
  mutation CreateModule(
    $input: CreateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    createModule(input: $input, condition: $condition) {
      id
      module_name
      module_id
      module_icon
      comp_order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateModule = /* GraphQL */ `
  mutation UpdateModule(
    $input: UpdateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    updateModule(input: $input, condition: $condition) {
      id
      module_name
      module_id
      module_icon
      comp_order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteModule = /* GraphQL */ `
  mutation DeleteModule(
    $input: DeleteModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    deleteModule(input: $input, condition: $condition) {
      id
      module_name
      module_id
      module_icon
      comp_order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAddStationForm = /* GraphQL */ `
  mutation CreateAddStationForm(
    $input: CreateAddStationFormInput!
    $condition: ModelAddStationFormConditionInput
  ) {
    createAddStationForm(input: $input, condition: $condition) {
      id
      comp_label
      comp_name
      comp_align
      comp_type
      display_name
      editable
      required
      is_child
      parent_name
      comp_order
      data_event
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAddStationForm = /* GraphQL */ `
  mutation UpdateAddStationForm(
    $input: UpdateAddStationFormInput!
    $condition: ModelAddStationFormConditionInput
  ) {
    updateAddStationForm(input: $input, condition: $condition) {
      id
      comp_label
      comp_name
      comp_align
      comp_type
      display_name
      editable
      required
      is_child
      parent_name
      comp_order
      data_event
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAddStationForm = /* GraphQL */ `
  mutation DeleteAddStationForm(
    $input: DeleteAddStationFormInput!
    $condition: ModelAddStationFormConditionInput
  ) {
    deleteAddStationForm(input: $input, condition: $condition) {
      id
      comp_label
      comp_name
      comp_align
      comp_type
      display_name
      editable
      required
      is_child
      parent_name
      comp_order
      data_event
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCSPayment = /* GraphQL */ `
  mutation CreateCSPayment(
    $input: CreateCSPaymentInput!
    $condition: ModelCSPaymentConditionInput
  ) {
    createCSPayment(input: $input, condition: $condition) {
      id
      cognito_user_id
      chargind_station_id
      socket_id
      reservation_id
      payment_amount
      payment_status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCSPayment = /* GraphQL */ `
  mutation UpdateCSPayment(
    $input: UpdateCSPaymentInput!
    $condition: ModelCSPaymentConditionInput
  ) {
    updateCSPayment(input: $input, condition: $condition) {
      id
      cognito_user_id
      chargind_station_id
      socket_id
      reservation_id
      payment_amount
      payment_status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCSPayment = /* GraphQL */ `
  mutation DeleteCSPayment(
    $input: DeleteCSPaymentInput!
    $condition: ModelCSPaymentConditionInput
  ) {
    deleteCSPayment(input: $input, condition: $condition) {
      id
      cognito_user_id
      chargind_station_id
      socket_id
      reservation_id
      payment_amount
      payment_status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChargingSockets = /* GraphQL */ `
  mutation CreateChargingSockets(
    $input: CreateChargingSocketsInput!
    $condition: ModelChargingSocketsConditionInput
  ) {
    createChargingSockets(input: $input, condition: $condition) {
      id
      scoket_type
      is_available
      chargingstationsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChargingSockets = /* GraphQL */ `
  mutation UpdateChargingSockets(
    $input: UpdateChargingSocketsInput!
    $condition: ModelChargingSocketsConditionInput
  ) {
    updateChargingSockets(input: $input, condition: $condition) {
      id
      scoket_type
      is_available
      chargingstationsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChargingSockets = /* GraphQL */ `
  mutation DeleteChargingSockets(
    $input: DeleteChargingSocketsInput!
    $condition: ModelChargingSocketsConditionInput
  ) {
    deleteChargingSockets(input: $input, condition: $condition) {
      id
      scoket_type
      is_available
      chargingstationsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserVehicle = /* GraphQL */ `
  mutation CreateUserVehicle(
    $input: CreateUserVehicleInput!
    $condition: ModelUserVehicleConditionInput
  ) {
    createUserVehicle(input: $input, condition: $condition) {
      id
      vehicle_manufacturer
      vehicle_modal_name
      vehicle_type
      socket_type
      charger_capacity
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserVehicle = /* GraphQL */ `
  mutation UpdateUserVehicle(
    $input: UpdateUserVehicleInput!
    $condition: ModelUserVehicleConditionInput
  ) {
    updateUserVehicle(input: $input, condition: $condition) {
      id
      vehicle_manufacturer
      vehicle_modal_name
      vehicle_type
      socket_type
      charger_capacity
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserVehicle = /* GraphQL */ `
  mutation DeleteUserVehicle(
    $input: DeleteUserVehicleInput!
    $condition: ModelUserVehicleConditionInput
  ) {
    deleteUserVehicle(input: $input, condition: $condition) {
      id
      vehicle_manufacturer
      vehicle_modal_name
      vehicle_type
      socket_type
      charger_capacity
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserWallet = /* GraphQL */ `
  mutation CreateUserWallet(
    $input: CreateUserWalletInput!
    $condition: ModelUserWalletConditionInput
  ) {
    createUserWallet(input: $input, condition: $condition) {
      id
      balance_amount
      status
      last_transaction
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserWallet = /* GraphQL */ `
  mutation UpdateUserWallet(
    $input: UpdateUserWalletInput!
    $condition: ModelUserWalletConditionInput
  ) {
    updateUserWallet(input: $input, condition: $condition) {
      id
      balance_amount
      status
      last_transaction
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserWallet = /* GraphQL */ `
  mutation DeleteUserWallet(
    $input: DeleteUserWalletInput!
    $condition: ModelUserWalletConditionInput
  ) {
    deleteUserWallet(input: $input, condition: $condition) {
      id
      balance_amount
      status
      last_transaction
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      start_time
      end_time
      reserve_time
      status
      user_cognito_id
      charging_station_id
      cs_socket_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      start_time
      end_time
      reserve_time
      status
      user_cognito_id
      charging_station_id
      cs_socket_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      start_time
      end_time
      reserve_time
      status
      user_cognito_id
      charging_station_id
      cs_socket_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserDetails = /* GraphQL */ `
  mutation CreateUserDetails(
    $input: CreateUserDetailsInput!
    $condition: ModelUserDetailsConditionInput
  ) {
    createUserDetails(input: $input, condition: $condition) {
      id
      user_name
      user_email
      user_mobile
      UserWallet {
        id
        balance_amount
        status
        last_transaction
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userDetailsUserWalletId
    }
  }
`;
export const updateUserDetails = /* GraphQL */ `
  mutation UpdateUserDetails(
    $input: UpdateUserDetailsInput!
    $condition: ModelUserDetailsConditionInput
  ) {
    updateUserDetails(input: $input, condition: $condition) {
      id
      user_name
      user_email
      user_mobile
      UserWallet {
        id
        balance_amount
        status
        last_transaction
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userDetailsUserWalletId
    }
  }
`;
export const deleteUserDetails = /* GraphQL */ `
  mutation DeleteUserDetails(
    $input: DeleteUserDetailsInput!
    $condition: ModelUserDetailsConditionInput
  ) {
    deleteUserDetails(input: $input, condition: $condition) {
      id
      user_name
      user_email
      user_mobile
      UserWallet {
        id
        balance_amount
        status
        last_transaction
        cognito_user_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cognito_user_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userDetailsUserWalletId
    }
  }
`;
export const createChargingStations = /* GraphQL */ `
  mutation CreateChargingStations(
    $input: CreateChargingStationsInput!
    $condition: ModelChargingStationsConditionInput
  ) {
    createChargingStations(input: $input, condition: $condition) {
      id
      cs_name
      cs_location {
        latitude
        longitude
        address
        pin
        city
      }
      cs_info {
        type
        communication
        is_available
        next_availability
      }
      qr_code
      mac_address
      ChargingSockets {
        nextToken
        startedAt
      }
      organizationsID
      cs_vehicle_type
      price_per_unit
      station_status
      customer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChargingStations = /* GraphQL */ `
  mutation UpdateChargingStations(
    $input: UpdateChargingStationsInput!
    $condition: ModelChargingStationsConditionInput
  ) {
    updateChargingStations(input: $input, condition: $condition) {
      id
      cs_name
      cs_location {
        latitude
        longitude
        address
        pin
        city
      }
      cs_info {
        type
        communication
        is_available
        next_availability
      }
      qr_code
      mac_address
      ChargingSockets {
        nextToken
        startedAt
      }
      organizationsID
      cs_vehicle_type
      price_per_unit
      station_status
      customer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChargingStations = /* GraphQL */ `
  mutation DeleteChargingStations(
    $input: DeleteChargingStationsInput!
    $condition: ModelChargingStationsConditionInput
  ) {
    deleteChargingStations(input: $input, condition: $condition) {
      id
      cs_name
      cs_location {
        latitude
        longitude
        address
        pin
        city
      }
      cs_info {
        type
        communication
        is_available
        next_availability
      }
      qr_code
      mac_address
      ChargingSockets {
        nextToken
        startedAt
      }
      organizationsID
      cs_vehicle_type
      price_per_unit
      station_status
      customer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOrganizations = /* GraphQL */ `
  mutation CreateOrganizations(
    $input: CreateOrganizationsInput!
    $condition: ModelOrganizationsConditionInput
  ) {
    createOrganizations(input: $input, condition: $condition) {
      id
      org_name
      org_type
      ChargingStations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOrganizations = /* GraphQL */ `
  mutation UpdateOrganizations(
    $input: UpdateOrganizationsInput!
    $condition: ModelOrganizationsConditionInput
  ) {
    updateOrganizations(input: $input, condition: $condition) {
      id
      org_name
      org_type
      ChargingStations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOrganizations = /* GraphQL */ `
  mutation DeleteOrganizations(
    $input: DeleteOrganizationsInput!
    $condition: ModelOrganizationsConditionInput
  ) {
    deleteOrganizations(input: $input, condition: $condition) {
      id
      org_name
      org_type
      ChargingStations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
