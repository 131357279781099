import Auth from '@aws-amplify/auth';
import * as AWS from 'aws-sdk'
import { AnalyzeExpenseRequest } from 'aws-sdk/clients/textract';
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";

const getDocClient = () => {
    const configuration = {
      region: "us-east-1",
      secretAccessKey: process.env.REACT_APP_SA,
      accessKeyId: process.env.REACT_APP_A,
    };
    AWS.config.update(configuration);
    const docClient = new AWS.DynamoDB.DocumentClient();
    return docClient;
  };


export async function getData(q: any, searchQuery: any, org_id: any) {
    if (q.query.length > 1) {
        return getDynamoDataMultipleSource(q, searchQuery, org_id)
    } else {
        try {
            return getDynamoDataFromSingleSource(q, searchQuery, org_id)
        } catch (err) {
            return err
        }
    }
}

export async function getDynamoDataFromSingleSource(tableData: any, searchQuery: any, org_id: any) {
    var params = {
        TableName: tableData.query[0]["table"]
    }
    const docClient = await getDocClient()
    const data = await docClient.scan(params).promise()
    let mdata = modifyAmplifyData(data, tableData.columns, searchQuery, false, null, tableData, org_id)
    return mdata
}

export async function getDynamoDataMultipleSource(tableData: any, searchQuery: any, org_id: any) {
    var params1 = {
        TableName: tableData.query[0]["table"]
    }
    const docClient = await getDocClient()
    const data1 = await docClient.scan(params1).promise()
    var params2 = {
        TableName: tableData.query[1]["table"]
    }
    const data2 = await docClient.scan(params2).promise()
    let mdata = modifyAmplifyData(data1, tableData.columns, searchQuery, true, data2, tableData, org_id)
    return mdata
}



export function modifyAmplifyData(data: any, col: any, searchQuery: any, multipleSource: any, data2: any, tableData: any, org_id: any) {
    let mdata = {
        code: 200,
        comp_label: "RSA_GRID_EVT",
        comp_name: "RSA_GRID",
        comp_order: 5,
        data: { columns: [], data_source: [] },
        data_event: "AMP_EVT",
        display_name: "Amplify Event",
        keys: null,
        message: null,
        pageNo: 0,
        pageSize: 100,
        status: true,
        totalNoOfElements: 0,
        totalPages: 6
    }
    mdata.data.columns = TableColumnSerializer(data, col)
    if (multipleSource) {
        mdata.data.data_source = TableMultipleDataSourceSerializer(data, data2, searchQuery, tableData, org_id)
    } else {
        mdata.data.data_source = TableDataSourceSerializer(data, searchQuery, org_id, tableData)
    }
    mdata.totalNoOfElements = mdata.data.data_source.length
    return mdata;
}

function TableColumnSerializer(data: any, col: any) {
    if (data && data.Items && data.Items.length != 0) {
        let finalcolumndata: any = []
        let originalDataKeys = Object.keys(data.Items[0])
        col.map((item:any) => {
            if (originalDataKeys.includes(item)) {
                    let modItem: any = item.replace("_", " ").split(" ").map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                    finalcolumndata.push({ "title": modItem, "dataindex": item, "key": item })
            }
        })
        return finalcolumndata
    } else {
        return []
    }
}

function TableDataSourceSerializer(data: any, searchQuery: any, org_id: any, tableData: any) {
    if (data && data.Items.length != 0) {
        let finalDataSource: any = []
        let filterKeyIndex: any = Object.keys(tableData.filter)[0]
        let originalData = data.Items;
        if (Object.keys(tableData.filter).length == 0) {
            if (searchQuery === undefined || searchQuery.length === 0 || searchQuery.length === undefined) {
                originalData.map((item: any) => {
                    finalDataSource.push(item)

                })
            } else if (searchQuery.length != 0) {
                originalData.map((item: any) => {
                    if (Object.values(item)?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        finalDataSource.push(item)
                    }
                })
            }
        } else {
            if (searchQuery === undefined || searchQuery.length === 0 || searchQuery.length === undefined) {
                originalData.map((item: any) => {
                    if (item[filterKeyIndex] == org_id) {
                        finalDataSource.push(item)
                    }
                })
            } else if (searchQuery.length != 0) {
                originalData.map((item: any) => {
                    if (item[filterKeyIndex] == org_id) {
                        if (Object.values(item)?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                            finalDataSource.push(item)
                        }
                    }
                })
            }
        }
        if(Object.keys(tableData?.query[0]).includes("default_sort_key") && tableData?.query[0]?.["default_sort_key"].length != 0){
            return sortArrayBasedOnKey(finalDataSource,tableData?.query[0]?.["default_sort_key"])
        }else{
            return finalDataSource
        }
    } else {
        return []
    }
}


function TableMultipleDataSourceSerializer(data: any, data2: any, searchQuery: any, tableData: any, org_id: any) {
    if (data && data.Items.length != 0) {
        let finalDataSource: any = []
        let filterKeyIndex: any = Object.keys(tableData.filter)[0]
        let originalData = data.Items;

        if (Object.keys(tableData.filter).length == 0) {
            if (searchQuery === undefined || searchQuery.length === 0 || searchQuery.length === undefined) {
                originalData.map((item: any) => {
                    item["array_widget_data"] = filterArrayWidgetData(item, data2, tableData)
                    finalDataSource.push(item)
                })
            } else if (searchQuery.length != 0) {
                originalData.map((item: any) => {
                    if (Object.values(item)?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        finalDataSource.push(item)
                    }
                })
            }
        } else {
            if (searchQuery === undefined || searchQuery.length === 0 || searchQuery.length === undefined) {
                originalData.map((item: any) => {
                    if (item[filterKeyIndex] == org_id) {
                        item["array_widget_data"] = filterArrayWidgetData(item, data2, tableData)
                        finalDataSource.push(item)
                    }
                })
            } else if (searchQuery.length != 0) {
                originalData.map((item: any) => {
                    if (item[filterKeyIndex] == org_id) {
                        if (Object.values(item)?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                            finalDataSource.push(item)
                        }
                    }
                })
            }
        }
        return finalDataSource
    } else {
        return []
    }
}

function filterArrayWidgetData(data: any, data2: any, tableData: any) {
    let returnBatteryData: any = []
    data2.Items.map((item: any) => {
        if (data[tableData.query[0]["join_key"]] == item[tableData.query[1]["join_key"]]) {
            let objData: any = {}
            tableData["include_arrays"].map((mapItem: any) => {
                objData[mapItem["alias"]] = item[mapItem["name"]]
            })
            returnBatteryData.push(objData)
        }
    })
    return returnBatteryData
}

export async function updateDynamoDB(expNames: any, expValues: any, keyId: any, tableName: any, updateExp: any,table_data:any) {
    let filtedData : any  = table_data?.data?.data_source?.filter((item:any) => item.id == keyId)[0]
    expNames["#UpdatedAt"] = "updatedAt"
    expValues[":updatedAt"] = moment.utc().format().toString()
    expNames["#_Version"] = "_version"
    expValues[":_version"] = filtedData?.["_version"] ? filtedData?.["_version"] + 1 : 1
    expNames["#_LastChangedAt"] = "_lastChangedAt"
    expValues[":_lastChangedAt"] = moment.utc().valueOf()
    var params = {
        ExpressionAttributeNames: expNames,
        ExpressionAttributeValues: expValues,
        Key: {
            id: keyId
        },
        TableName: tableName,
        UpdateExpression: updateExp + ",#UpdatedAt = :updatedAt,#_Version = :_version,#_LastChangedAt = :_lastChangedAt"
    };
    const docClient = await getDocClient()
    return await docClient.update(params).promise()
}

export async function deleteDynamoDBItem(idNum: any, tableName: any) {
    var params = {
        Key: {
            id: idNum
        },
        TableName: tableName
    };
    const docClient = await getDocClient()
    return await docClient.delete(params).promise()
}

export async function addDynamoDBItem(values: any, tableName: any,table_data:any) {
    let tableN : any = JSON.parse(table_data?.group_3?.filter((item:any) => item.comp_type == "GRID")[0]["data_event"])["query"][0]["table"].split("-")[0]
    values["updatedAt"] = moment.utc().format().toString()
    values["createdAt"] = moment.utc().format().toString()
    values["_version"] = 1
    values["__typename"] = tableN
    values["_lastChangedAt"] = moment.utc().valueOf()
    var params = {
        Item: values,
        TableName: tableName
    };
    const docClient = await getDocClient()
    return await docClient.put(params).promise()

}

export function sortArrayBasedOnKey(dataArray:any,sort_key:string){
    dataArray.sort(function(a:any, b:any) {
        var keyA = new Date(a[sort_key]),
          keyB = new Date(b[sort_key]);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    return dataArray.reverse()
}