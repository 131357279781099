// define the table name of dynamo db here...
export const TABLE_SWAPPING_MASTER = "EVSwappingMaster-j3e2bgqzpjfebagtukm5o4vjoa-staging";
export const TABLE_SWAPPING_BATTERY = "EVBatterySlots-j3e2bgqzpjfebagtukm5o4vjoa-staging";
export const TABLE_SWAPPING_SWAPS = "EVSwapping-j3e2bgqzpjfebagtukm5o4vjoa-staging";
export const TABLE_SWAPPING_BOOKING = "EVSwapBooking-j3e2bgqzpjfebagtukm5o4vjoa-staging";
export const TABLE_RIDER_DETAILS = "EVConsumer-j3e2bgqzpjfebagtukm5o4vjoa-staging";
export const TABLE_CONSUMER_DETAILS = "EVConsumer-j3e2bgqzpjfebagtukm5o4vjoa-staging";

// GET STATES
// stations header
export const SWAPPING_STATION_SHOW_MAP_VIEW = "SWAPPING_STATION_SHOW_MAP_VIEW"
// overview actions
export const GET_OVERVIEW_COUNT_STATIONS = "OVERVIEW_COUNT_STATIONS";
export const GET_OVERVIEW_COUNT_BATTERIES = "GET_OVERVIEW_COUNT_BATTERIES";
export const GET_OVERVIEW_COUNT_CONSUMERS = "GET_OVERVIEW_COUNT_CONSUMERS";
export const GET_OVERVIEW_COUNT_SWAPS = "GET_OVERVIEW_COUNT_SWAPS";
// overview charts actions
export const GET_USAGE_OVERVIEW = "GET_USAGE_OVERVIEW"
export const GET_SLOT_DISTRIBUTION = "GET_SLOT_DISTRIBUTION"
// swapping station search action
export const SWAPPING_STATION_SEARCH_KEYWORD = "SWAPPING_STATION_SEARCH_KEYWORD"
// get usage for past 7 days
export const GET_SWAP_USAGE = "GET_SWAP_USAGE"
// get stations data by ID
export const GET_STATION_DETAILS_BY_ID = "GET_STATION_DETAILS_BY_ID"
export const GET_SWAP_HISTORY_BY_STATION_ID = "GET_SWAP_HISTORY_BY_STATION_ID"
export const GET_BOOKING_DETAILS_BY_STATION_ID = "GET_BOOKING_DETAILS_BY_STATION_ID"

// get Battery list from station ID
export const GET_BATTERY_LIST_BY_STATION_ID = "GET_BATTERY_LIST_BY_STATION_ID"

// station lists actions
export const GET_SWAPPING_STATION_LISTS = "GET_SWAPPING_STATION_LISTS"

// customer issues list
export const GET_RIDERS_LIST = "GET_RIDERS_LIST"

// get swap history by user id
export const GET_SWAP_HISTORY_BY_USER_ID = "GET_SWAP_HISTORY_BY_USER_ID"
export const USER_RIDE_HISTORY_LOADING = "USER_RIDE_HISTORY_LOADING"


// STORE STATES
export const STORE_OVERVIEW_COUNT_STATIONS = "STORE_OVERVIEW_COUNT_STATIONS";
export const STORE_OVERVIEW_COUNT_BATTERIES = "STORE_OVERVIEW_COUNT_BATTERIES";
export const STORE_OVERVIEW_COUNT_CONSUMERS = "STORE_OVERVIEW_COUNT_CONSUMERS";
export const STORE_OVERVIEW_COUNT_SWAPS = "STORE_OVERVIEW_COUNT_SWAPS";
// overview charts actions
export const STORE_USAGE_OVERVIEW = "STORE_USAGE_OVERVIEW"
export const STORE_SLOT_DISTRIBUTION = "STORE_SLOT_DISTRIBUTION"
// station lists actions
export const STORE_SWAPPING_STATION_LISTS = "STORE_SWAPPING_STATION_LISTS"
// store station details from ID
export const STORE_STATION_DETAILS_BY_ID = "STORE_STATION_DETAILS_BY_ID"
export const STORE_SWAP_HISTORY_BY_STATION_ID = "STORE_SWAP_HISTORY_BY_STATION_ID"
export const STORE_BOOKING_DETAILS_BY_STATION_ID = "STORE_BOOKING_DETAILS_BY_STATION_ID"
// store Battery list from station ID
export const STORE_BATTERY_LIST_BY_STATION_ID = "STORE_BATTERY_LIST_BY_STATION_ID"
// store swap update
export const STORE_SWAP_USAGE = "STORE_SWAP_USAGE"
// store riders list
export const STORE_GET_RIDERS_LIST = "STORE_GET_RIDERS_LIST"


// interface for getters

export interface ISwappingStationShowMapView{
    type:"SWAPPING_STATION_SHOW_MAP_VIEW",
    payload:boolean
}

export interface IGetRidersList{
    type:"GET_RIDERS_LIST",
    payload:any
}

export interface IGetSwapHistoryByUserId{
    type:"GET_SWAP_HISTORY_BY_USER_ID",
    payload:{
        user_id:String
    }
}

export interface ISwappingStationSearchKeyword{
    type:"SWAPPING_STATION_SEARCH_KEYWORD",
    payload:string
}

export interface IGetOverviewCountStations {
    type:"GET_OVERVIEW_COUNT_STATIONS",
    payload:any
}

export interface IGetOverviewCountBatteries {
    type:"GET_OVERVIEW_COUNT_BATTERIES",
    payload:any
}

export interface IGetOverviewCountSwaps {
    type:"GET_OVERVIEW_COUNT_SWAPS",
    payload:any
}

export interface IGetSwappingStationLists{
    type:"GET_SWAPPING_STATION_LISTS",
    payload:any
}
export interface IGetUsageOverview{
    type:"GET_USAGE_OVERVIEW",
    payload:any
}

export interface IGetSlotDistribution{
    type:"GET_SLOT_DISTRIBUTION",
    payload:any
}
export interface IGetStationDetailsById{
    type:"GET_STATION_DETAILS_BY_ID",
    payload:{
        id:string
    }
}

export interface IGetBatteryListBYStationId{
    type:"GET_BATTERY_LIST_BY_STATION_ID",
    payload:{
        station_id:string
    }
}
export interface IGetSwapHistoryByStationId{
    type:"GET_SWAP_HISTORY_BY_STATION_ID",
    payload:{
        station_id:string
    }
}
export interface IGetBookingDetailsByStationId{
    type:"GET_BOOKING_DETAILS_BY_STATION_ID",
    payload:{
        station_id:string
    }
}

export interface IGetSwapUsageFor7days{
    type:"GET_SWAP_USAGE",
    payload:any
}

export interface IGetRidersHistoryLoading{
    type:"USER_RIDE_HISTORY_LOADING",
    payload:boolean
}

export interface IGetOverviewCountConsumers{
    type:"GET_OVERVIEW_COUNT_CONSUMERS",
    payload:any
}

// interface for setters
export interface IStoreOverviewCountStations{
    type:"STORE_OVERVIEW_COUNT_STATIONS",
    payload:any
}
export interface IStoreOverviewCountConsumers{
    type:"STORE_OVERVIEW_COUNT_CONSUMERS",
    payload:any
}
export interface IStoreOverviewCountBatteries{
    type:"STORE_OVERVIEW_COUNT_BATTERIES",
    payload:any
}
export interface IStoreOverviewCountSwaps{
    type:"STORE_OVERVIEW_COUNT_SWAPS",
    payload:any
}

export interface IStoreSwappingStationLists{
    type:"STORE_SWAPPING_STATION_LISTS",
    payload:any
}

export interface IStoreUsageOverview{
    type:"STORE_USAGE_OVERVIEW",
    payload:any
}

export interface IStoreSlotDistribution{
    type:"STORE_SLOT_DISTRIBUTION",
    payload:any
}

export interface IStoreStationDetailsById{
    type:"STORE_STATION_DETAILS_BY_ID",
    payload:any
}
export interface IStoreBatteryListBYStationId{
    type:"STORE_BATTERY_LIST_BY_STATION_ID",
    payload:any
}

export interface IStoreSwapHistoryByStationId{
    type:"STORE_SWAP_HISTORY_BY_STATION_ID",
    payload:any
}
export interface IStoreBookingDetailsByStationId{
    type:"STORE_BOOKING_DETAILS_BY_STATION_ID",
    payload:{
        station_id:string
    }
}

export interface IStoreSwapUsageForPast7days{
    type:"STORE_SWAP_USAGE",
    payload:any
}

export interface IStoreGetRidersList{
    type:"STORE_GET_RIDERS_LIST",
    payload:any
}

export interface IStoreSwapHistoryByUserId{
    type:"STORE_SWAP_HISTORY_BY_USER_ID",
    payload:any
}


export function updateSwapMapStatus(params:any){
    return {
        type:params.type,
        payload: params.payload
    }
}

export function swappingStationSearchKeyword(params:ISwappingStationSearchKeyword|any){
    return{
        type:params.type,
        payload:params.payload
    }
}

export function updateRiderHistoryLoading(params:IGetRidersHistoryLoading){
    return{
        type:params.type,
        payload:params.payload
    }
}