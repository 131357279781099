import {
  IDeleteSingleAlertActions,
  IAlertDetailActions,
  AlertDetailActions,
  IPastAlertDetailActions,
  ISingleAlertDetailAction,
  IClearGraphActions,
  ClearSingleReducer,
} from "../actions/alert-detail";
import {
  Alert,
  TAlerts,
  TAlertId,
  TAlertInsights,
  TSort,
  TPagination,
  TPastAlert,
  AlertData,
  TAlertType,
} from "../redux/models";
import axios from "axios";
import { put } from "redux-saga/effects";
import { getAlertTypeId } from "../util/alert-graph";
import { IAlertGraphActions } from "../actions/graph";
import { getAlertGraphData } from "./graph";
import { getToken } from "../authentication";
export type ClearSingleAlert = "CLEAR_SINGLE_ALERT";

export type Store_AlertInsights = {
  type: AlertDetailActions;
  payload: {
    alertInsight: TAlertInsights;
  };
};

export type Store_AlertClearReducer = {
  type: ClearSingleReducer;
  payload: {
    didAlertClear: string;
    alertId: number | string;
  };
};

export type TAlertsTableData = {
  smart: Alert;
  bms: Alert;
  mc: Alert;
  vcu: Alert;
  charger: Alert;
};

export type Store_ClearSingleAlert = {
  type: ClearSingleAlert;
  payload: {
    alertId: TAlertId;
    alerts: any;
  };
};

export type Store_PastAlert = {
  type: AlertDetailActions;
  payload: {
    pastAlert: TPastAlert;
    sort: TSort;
    pagination: TPagination;
  };
};

export type Store_UpdatePastAlert = {
  type: "STORE_UPDATE_PAST_ALERTS";
  payload: {
    pastAlert: TPastAlert;
    sort: TSort;
    pagination: TPagination;
    alertId: number;
  };
};

export type Store_UpdateSingleAlert = {
  type: "STORE_UPDATE_SINGLE_ALERT";
  payload: {
    alertType: TAlertType;
    alertId: string;
    alertData: AlertData;
  };
};

export async function getAlertInsight(params: IAlertDetailActions) {
  const alertInsight = await getAdditionalInsights(params);
  return alertInsight;
}

export async function postAlertClearanceComment(params: IAlertDetailActions) {
  try {
    const postAlertComment = await alertClearanceComment(params);
    return postAlertComment;
    //handle error
  } catch (error) {
    console.log("post clearance alert error", error);
  }
}

export async function postClearAlertGraph(params: IClearGraphActions) {
  const alertName = params.payload
    .alertName!.replace(/[^a-zA-Z0-9]/g, "")
    .toLocaleLowerCase();
  const clearGraphAlertTypeId = getAlertTypeId(alertName);
  return clearGraphAlertTypeId;
}

export async function getPastAlertData(params: IAlertDetailActions) {
  const pastAlertData = await getPastAlerts(params);
  return pastAlertData;
}

export function* updatePastAlertData(params: IPastAlertDetailActions) {
  yield put({
    type: "STORE_UPDATE_PAST_ALERTS",
    payload: {
      pastAlert: params.payload.pastAlerts,
      pagination: params.payload.pagination,
      sort: params.payload.sort,
      alertId: params.payload.alertId,
    },
  } as Store_UpdatePastAlert);
}

export async function getSingleAlertDetail(params: ISingleAlertDetailAction) {
  const data = await getSingleAlert(params);
  return data;
}

//get graph data on past alert row click
export async function getPastAlertGraphData(params: IAlertGraphActions) {
  const data = await getAlertGraphData(params);
  return data;
}

// async function getAdditionalInsights(params: IAlertDetailActions) {
//     const response = await axios.post(process.env.REACT_APP_WEBAPIURL + '/additionalInsight',
//         {
//             vehicleId: params.payload.vehicleID,
//             alertId: params.payload.alertId,
//             alertName: params.payload.alertName,
//             customerId: params.payload.customerId
//         }, { headers: { 'Content-Type': 'application/json' } }
//     )
//     return response.data.body as TAlertInsights
// }

async function getAdditionalInsights(params: IAlertDetailActions) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/additinsts",
    {
      vehicleID: localStorage.getItem("grpvehicleId"),
      alertId: localStorage.getItem("grpalertId"),
      deviceId: localStorage.getItem("grpdeviceId"),
      alertName: localStorage.getItem("grpalertName"),
      customerId: localStorage.getItem("grpcustomerId"),
      org_id: params.payload.org_id,
    },

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
      },
    }
  );

  return response.data as TAlertInsights;
}

async function alertClearanceComment(params: IAlertDetailActions) {
  const tokenRes = await getToken();
  console.log("PAYLOADCLEARALert", params.payload);
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/clearOpenAlertV2",
    {
      vehicleId: localStorage.getItem("grpvehicleId"),
      alertName: params.payload.alertName,
      comment: params.payload.comment,
      alertId: localStorage.getItem("grpalertId"),
      // alertName: params.payload.alertName,
      alertTime: localStorage.getItem("grpalerteventutc"),
      deviceId: localStorage.getItem("grpdeviceId"),
      alertCode: localStorage.getItem("grpalertcode"),
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
      },
    }
  );
  return response.data;
}

// async function getPastAlerts(params: IAlertDetailActions) {
//     const response = await axios.post(process.env.REACT_APP_WEBAPIURL + '/pastAlerts',
//         {
//             vehicleId: params.payload.vehicleID,
//             alertId: params.payload.alertId,
//             alertName: params.payload.alertName,
//             customerId: params.payload.customerId,
//             pageSize: params.payload.pagination.pageSize,
//             pageNo: params.payload.pagination.pageNumber,
//             alertCode: params.payload.alertCode
//         }, { headers: { 'Content-Type': 'application/json' } }
//     )
//     return response.data.body
// }

// async function getPastAlerts(params: IAlertDetailActions) {
//     console.log("pastalertactions",params)
//     const response = await axios.post(
//       process.env.REACT_APP_WEBAPIURL + "/pastAlerts",
//       {
//         vehicleId: localStorage.getItem('grpvehicleId'),
//         deviceId: localStorage.getItem('grpdeviceId'),
//         alertId: localStorage.getItem('grpalertId'),
//         alertName: localStorage.getItem('grpalertName'),
//         customerId: localStorage.getItem('grpcustomerId'),
//         pageSize: params.payload.pagination.pageSize,
//         pageNo: params.payload.pagination.pageNumber,
//         alertCode: localStorage.getItem('grpalertCode'),
//         org_id:params.payload.org_id
//       },
//       { headers: { "Content-Type": "application/json" } }
//     );
//     return response.data.body;
//   }

async function getPastAlerts(params: IAlertDetailActions) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getclosedalerts",
    {
      frameId: localStorage.getItem("grpvehicleId"),
      deviceId: localStorage.getItem("grpdeviceId"),
      alertId: localStorage.getItem("grpalertId"),
      alertName: localStorage.getItem("grpalertName"),
      customerId: localStorage.getItem("grpcustomerId"),
      pageSize: params.payload.pagination.pageSize,
      pageNo: params.payload.pagination.pageNumber,
      alertCode: localStorage.getItem("grpalertCode"),
      org_id: params.payload.org_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  if (response.data.data.content) {
    const body = { data: response.data.data.content };
    return body;
  } else {
    const body = { data: [] };
    return body;
  }
}

//need to change
///alertDetails/
// async function getSingleAlert(params: ISingleAlertDetailAction) {
//     const response = await axios.get(process.env.REACT_APP_WEBAPIURL + '/alertDetails/' + params.payload.alertId)
//     return response.data.body as AlertData
// }
async function getSingleAlert(params: ISingleAlertDetailAction) {
  //const response = await axios.get(process.env.REACT_APP_WEBAPIURL + '/alertDetails/' + params.payload.alertId)
  let data = {
    status: "OK",
    body: {
      alertTypeId: localStorage.getItem("grpalertTypeId"),
      alertName: localStorage.getItem("grpalertName"),
    },
  };
  return data.body;
}

// function pastAlertDataGenerator(params: IAlertDetailActions) {
//     let datas: TPastAlertData[] = []
//     for (var i = 110; i < 130; i++) {
//         datas.push({
//             alertId: String(i),
//             alertTime: i + "-May-2020 10:05AM",
//             tat: "24 hrs " + i + "0 min",
//             vehicleId: "BDS" + i,
//             location: "Bangalore " + i,
//             alertGraph: false
//         })
//     }
//     const pastAlert: TPastAlert = {
//         dataCount: 130 - 110,
//         data: datas
//     }
//     return pastAlert
// }
