import { Dispatch } from "redux";
import { State } from "../redux/connectm-state";
import { IAlertActions, RnDAlerts } from "./alerts";

type UserActions = "RECEIVED_USER" | "GET_USER" | "UPDATE_USER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE"


export interface UserPayload {
  authenticated: boolean,
  user: any
}
export interface IUsersAction {
  type: UserActions,
  payload: UserPayload
}

export interface IStoreGetServices {
  type: 'STORE_GET_SERVICES',
  payload: any
}

export interface IUpdateUserProfile {
  type: "UPDATE_USER_PROFILE",
  payload:any
}

export function UsersAction(params: IUsersAction) {
  return {
    type: params.type,
    payload: params.payload
  }
}

export function StoreGetServices(params:any){
  return{
    type:params.type,
    payload:params.payload
  }
}

export function updateUserProfileFunc(params:IUpdateUserProfile){
  return{
    type:params.type,
    payload:params.payload
  }
}

export interface ReduxUserAction {
  usersAction: (params: IUsersAction) => IUsersAction,
  alertTabChanged: (params: IAlertActions) => IAlertActions,
  storeGetServices: (params: IStoreGetServices) => IStoreGetServices,
  updateUserProfile: (params: IUpdateUserProfile) => IUpdateUserProfile,
}

export function mapDispatchToProps(dispatch: Dispatch): ReduxUserAction {
  return {
    usersAction: (params: IUsersAction) => dispatch(UsersAction(params)),
    alertTabChanged: (params: IAlertActions) => dispatch(RnDAlerts(params)),
    storeGetServices : (params:IStoreGetServices) => dispatch(StoreGetServices(params)),
    updateUserProfile: (params:IUpdateUserProfile) => dispatch(updateUserProfileFunc(params))
  }
}
export interface ReduxUserState {
  user: State["user"],
  alerts: State["alerts"]
}

export function mapStateToProps(state: State): ReduxUserState {
  return {
    user: state.user,
    alerts: state.alerts
  }
}