import { IAlertActions, IDropdownFilterActions } from "../actions/alerts";
import {
  TAlertType,
  TSort,
  TPagination,
  TFilter,
  Alert,
  TDropdownFilters,
  TSearchFilter,
  TAlertPagination,
} from "../redux/models";
import { call, put } from "redux-saga/effects";
import moment from "moment";
import axios from "axios";
import * as dotenv from "dotenv";
import { searchKeyField } from "../util/search";
import { getToken } from "../authentication";
dotenv.config();
type FilterAlertRequest = {
  vehicleID?: string;
  alertName?: string;
  model?: string;
  subModel?: string;
  location?: string;
  subLocation?: string;
  startDate?: string;
  endDate?: string;
  batteryId?: string;
  customerId?: string;
  timeFrame?: string;
  alertType: TAlertType;
  pageNo: number;
  pageSize: number;
  sortDirection: "asc" | "desc";
  sortKey: string;
  org_id: any;
  customer_id: any;
  timeZone: any;
};

export type Store_AlertUpdate = {
  type: "STORE_ALERT_UPDATE";
  payload: {
    alertType: TAlertType;
    alerts: TAlertsTableData;
    pagination: TPagination;
    sort: TSort;
    alertPagination: TAlertPagination;
  };
};

export type Store_AlertUpdateCount = {
  type: "STORE_ALERT_COUNT_UPDATE";
  payload: {
    smartCount: any;
    bmsCount: any;
    mcCount: any;
    vcuCount: any;
    chargerCount: any;
  };
};

export type StoreFilteredAlertCount = {
  type: "STORE_FILTERED_ALERT_COUNT_UPDATE";
  payload: any;
};

export type Store_AlertTabChange = {
  type: "STORE_ALERT_TAB_CHANGE";
  payload: {
    alertType: TAlertType;
    pagination: TPagination;
    alertPagination: TAlertPagination;
  };
};

export type Store_AlertUpdate_Download = {
  type: "STORE_ALERT_UPDATE_DOWNLOAD";
  payload: {
    alertType: TAlertType;
    alerts: TAlertsTableData;
    pagination: TPagination;
    sort: TSort;
    alertPagination: TAlertPagination;
  };
};

export type Store_AlertFilterChange = {
  type: "STORE_UPDATE_FILTER";
  payload: {
    alertType: TAlertType;
    pagination: TPagination;
    filter: TFilter;
    locationFilter: TFilter;
    vehicleFilter: TFilter;
    timeFrameFilter: TFilter;
    searchFilter: TSearchFilter;
    alertPagination: TAlertPagination;
  };
};

export type Store_DropdownFilters = {
  type: "STORE_DROPDOWN_FILTERS";
  payload: TDropdownFilters;
};

export type TAlertsTableData = {
  smart: Alert;
  bms: Alert;
  mc: Alert;
  vcu: Alert;
  charger: Alert;
};

/////////////////////GET FILTER OPTIONS IN SUBHEADER//////////////////////
export function* getDropdownFilterOptions(params: IDropdownFilterActions) {
  try {
    const data: TDropdownFilters = yield call(fetchDropdownFilters, params);
    yield put({
      type: "STORE_DROPDOWN_FILTERS",
      payload: {
        location: data.location,
        vehicle: data.vehicle,
      },
    } as Store_DropdownFilters);
  } catch (error) {
    console.log("get filters error", error);
  }
}
async function fetchDropdownFilters(params: IDropdownFilterActions) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI +
      "/dropdownfilters?org_id=" +
      params.payload.org_id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );

  return response.data.data as TDropdownFilters;
}

//////////////////////////////////GET ALERT TABLE DATA ///////////////////////////
export function* getAlertData(params: IAlertActions) {
  try {
    const data: any = yield call(getAlerts, params);
    yield put({
      type: "STORE_ALERT_UPDATE",
      payload: {
        alertType: params.payload.alertType,
        alerts: data,
        pagination: params.payload.pagination,
        sort: params.payload.sort,
        alertPagination: params.payload.alertPagination,
      },
    } as Store_AlertUpdate);
  } catch (error) {
    console.log("get Alerts error", error);
  }
}

export function* getAlertDataDownload(params: IAlertActions) {
  try {
    const data: any = yield call(getAlerts, params);
    yield put({
      type: "STORE_ALERT_UPDATE_DOWNLOAD",
      payload: {
        alertType: params.payload.alertType,
        alerts: data,
        pagination: params.payload.pagination,
        sort: params.payload.sort,
        alertPagination: params.payload.alertPagination,
      },
    } as Store_AlertUpdate_Download);
  } catch (error) {
    console.log("get Alerts error", error);
  }
}

//getAlertDataCount
export function* getAlertDataCount(params: IAlertActions) {
  try {
    const data: any = yield call(getAlertsCount, params);
    yield put({
      type: "STORE_ALERT_COUNT_UPDATE",
      payload: {
        smartCount: data[0].smart,
        bmsCount: data[0].bms,
        mcCount: data[0].mc,
        vcuCount: data[0].vcu,
        chargerCount: data[0].charger,
      },
    } as any);
  } catch (error) {
    console.log("get Alerts error", error);
  }
}

async function getAlertsCount(params: IAlertActions) {
  let response = [];
  response = await Promise.all([getAllAlertsCount(params)]);
  return response;
}

async function getAllAlertsCount(params: IAlertActions) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getAllOpenAlerts",
    {
      pageable: true,
      org_id: params.payload.org_id,
      customer_id:params.payload.customer_id
      // timeZone:"EST"
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data.data as Alert;
}

// filtered alerts count
export function* getFilteredAlertDataCount(params: IAlertActions) {
  try {
    const data: any = yield call(getFilteredAlertsCount, params);
    yield put({
      type: "STORE_FILTERED_ALERT_COUNT_UPDATE",
      payload: {
        smartCount: data[0].smart,
        bmsCount: data[0].bms,
        mcCount: data[0].mc,
        vcuCount: data[0].vcu,
        chargerCount: data[0].charger,
      },
    } as any);
  } catch (error) {
    console.log("get Alerts error", error);
  }
}

async function getFilteredAlertsCount(params: IAlertActions) {
  let response = [];
  response = await Promise.all([getAllFilteredAlertsCount(params)]);
  return response;
}

async function getAllFilteredAlertsCount(params: IAlertActions) {
  const request = await getFilteredAlertDetailsRequest(params);
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getfilteralertdata",
    {
      ...request,
      pageable: true,
      org_id: params.payload.org_id,
      customer_id:params.payload.customer_id
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data.data as Alert;
}

async function getAlerts(params: IAlertActions) {
  let response: any = [];
  if (params.payload.filter.fieldName !== "all") {
    const request = await getFilteredAlertDetailsRequest(params);
    if (params.payload.alertType == "smart") {
      response = await Promise.all([getFilteredSmartAlert(request!, params)]);
    }
    if (params.payload.alertType == "bms") {
      response = await Promise.all([getFilteredBmsAlert(request!, params)]);
    }
    if (params.payload.alertType == "mc") {
      response = await Promise.all([getFilteredMcAlert(request!, params)]);
    }
    if (params.payload.alertType == "vcu") {
      response = await Promise.all([getFilteredVcuAlert(request!, params)]);
    }
    if (params.payload.alertType == "charger") {
      response = await Promise.all([getFilteredChargerAlert(request!, params)]);
    }
    // response = await Promise.all([
    //   getFilteredSmartAlert(request!, params),
    //   getFilteredBmsAlert(request!, params),
    //   getFilteredMcAlert(request!, params)
    // ])
  } else {
    if (params.payload.alertType == "smart") {
      response = await Promise.all([getSmartAlert(params)]);
    }
    if (params.payload.alertType == "bms") {
      response = await Promise.all([getBmsAlert(params)]);
    }
    if (params.payload.alertType == "mc") {
      response = await Promise.all([getMcAlert(params)]);
    }
    if (params.payload.alertType == "vcu") {
      response = await Promise.all([getVcuAlert(params)]);
    }
    if (params.payload.alertType == "charger") {
      response = await Promise.all([getChargerAlert(params)]);
    }
  }
  if (params.payload.filter.fieldName == "all") {
    const data: any = {
      smart: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
      bms: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
      mc: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
      vcu: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
      charger: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
    };
    return data;
  } else {
    const data: any = {
      smart: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
      bms: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
      mc: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
      vcu: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
      charger: Object.assign({ dataCount: 0, data: [] }, response[0].data, {
        status: response[0].code,
      }),
    };
    return data;
  }
}

///////////////////////////change tabs to sms, bms, mc////////////////////////
export function* updateAlertTabChange(params: IAlertActions) {
  try {
    yield put({
      type: "STORE_ALERT_TAB_CHANGE",
      payload: params.payload,
    } as Store_AlertTabChange);
  } catch (error) {
    console.log("error", error);
  }
}
//////////////////////////updates filters///////////////////////////////////
export function* updateAlertFilterChange(params: IAlertActions) {
  try {
    yield put({
      type: "STORE_UPDATE_FILTER",
      payload: params.payload,
    } as Store_AlertFilterChange);
  } catch (error) {
    console.log("error", error);
  }
}

async function getFilteredAlertDetailsRequest(params: IAlertActions) {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let request: FilterAlertRequest = {
    alertType: params.payload.alertType,
    pageNo: params.payload.pagination.pageNumber,
    pageSize: params.payload.pagination.pageSize,
    sortDirection: params.payload.sort.direction === "descend" ? "desc" : "asc",
    sortKey: params.payload.sort.fieldName,
    org_id: params.payload.org_id,
    customer_id: params.payload.customer_id,
    timeZone: browserTimeZone,
  };
  if (params.payload.vehicleFilter.fieldName === "model") {
    const key =
      params.payload.vehicleFilter.value === "Classic" ||
      params.payload.vehicleFilter.value === "Cargo"
        ? "model"
        : "subModel";
    request = Object.assign(request, {
      [key]: params.payload.vehicleFilter.value,
    });
  }
  if (params.payload.locationFilter.fieldName === "location") {
    const key =
      params.payload.locationFilter.value === "North" ||
      params.payload.locationFilter.value === "South" ||
      params.payload.locationFilter.value === "East" ||
      params.payload.locationFilter.value === "West"
        ? "location"
        : "subLocation";
    request = Object.assign(request, {
      [key]: params.payload.locationFilter.value,
    });
  }
  if (params.payload.timeFrameFilter.fieldName === "timeFrame") {
    request = Object.assign(request, {
      timeFrame: params.payload.timeFrameFilter.value,
    });
  }
  if (params.payload.timeFrameFilter.fieldName === "DateRange") {
    const splitDate = params.payload.timeFrameFilter.value.split(" to ");
    const startDate = moment(splitDate[0].trim(), "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    const endDate = moment(splitDate[1].trim(), "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    request = Object.assign(request, {
      startDate: startDate,
      endDate: endDate,
    });
  }
  if (params.payload.searchFilter.fieldName === "search") {
    const key = params.payload.searchFilter.isVehicle
      ? "vehicleID"
      : "alertName";
    request = Object.assign(request, {
      [key]: params.payload.searchFilter.value,
    });
  }
  return request;
}

async function getSmartAlert(params: IAlertActions) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getAllOpenAlerts",
    {
      alertType: "smart",
      pageNo: params.payload.alertPagination?.smart.pageNumber - 1,
      pageSize: params.payload.alertPagination?.smart.pageSize,
      sortDirection:
        params.payload.sort.direction === "descend" ? "desc" : "asc",
      sortKey: params.payload.sort.fieldName,
      pageable: false,
      org_id: params.payload.org_id,
      customer_id: params.payload.customer_id,
      timeZone: browserTimeZone,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as any;
}

async function getBmsAlert(params: IAlertActions) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getAllOpenAlerts",
    {
      alertType: "bms",
      pageNo: params.payload.alertPagination?.bms.pageNumber - 1,
      pageSize: params.payload.alertPagination?.bms.pageSize,
      sortDirection:
        params.payload.sort.direction === "descend" ? "desc" : "asc",
      sortKey: params.payload.sort.fieldName,
      pageable: false,
      org_id: params.payload.org_id,
      customer_id: params.payload.customer_id,
      timeZone: browserTimeZone,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as any;
}

async function getMcAlert(params: IAlertActions) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getAllOpenAlerts",
    {
      alertType: "mc",
      pageNo: params.payload.alertPagination?.mc.pageNumber - 1,
      pageSize: params.payload.alertPagination?.mc.pageSize,
      sortDirection:
        params.payload.sort.direction === "descend" ? "desc" : "asc",
      sortKey: params.payload.sort.fieldName,
      pageable: false,
      org_id: params.payload.org_id,
      customer_id: params.payload.customer_id,
      timeZone: browserTimeZone,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as any;
}

async function getVcuAlert(params: IAlertActions) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getAllOpenAlerts",
    {
      alertType: "vcu",
      pageNo: params.payload.alertPagination?.vcu.pageNumber - 1,
      pageSize: params.payload.alertPagination?.vcu.pageSize,
      sortDirection:
        params.payload.sort.direction === "descend" ? "desc" : "asc",
      sortKey: params.payload.sort.fieldName,
      pageable: false,
      org_id: params.payload.org_id,
      customer_id: params.payload.customer_id,
      timeZone: browserTimeZone,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as any;
}
async function getChargerAlert(params: IAlertActions) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getAllOpenAlerts",
    {
      alertType: "charger",
      pageNo: params.payload.alertPagination?.charger.pageNumber - 1,
      pageSize: params.payload.alertPagination?.charger.pageSize,
      sortDirection:
        params.payload.sort.direction === "descend" ? "desc" : "asc",
      sortKey: params.payload.sort.fieldName,
      pageable: false,
      org_id: params.payload.org_id,
      customer_id: params.payload.customer_id,
      timeZone: browserTimeZone,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as any;
}

async function getFilteredSmartAlert(
  requestPayload: FilterAlertRequest,
  params: IAlertActions
) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const smartFilter: FilterAlertRequest = {
    ...requestPayload,
    alertType: "smart",
    pageNo: params.payload.alertPagination.smart.pageNumber - 1,
    pageSize: params.payload.alertPagination.smart.pageSize,
    org_id: params.payload.org_id,
    customer_id: params.payload.customer_id,
    timeZone: browserTimeZone,
  };
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getfilteralertdata",
    smartFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as Alert;
}

async function getFilteredBmsAlert(
  requestPayload: FilterAlertRequest,
  params: IAlertActions
) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const bmsFilter: FilterAlertRequest = {
    ...requestPayload,
    alertType: "bms",
    pageNo: params.payload.alertPagination.bms.pageNumber - 1,
    pageSize: params.payload.alertPagination.bms.pageSize,
    customer_id: params.payload.customer_id,
    timeZone: browserTimeZone,
  };
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getfilteralertdata",
    bmsFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as Alert;
}

async function getFilteredMcAlert(
  requestPayload: FilterAlertRequest,
  params: IAlertActions
) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mcFilter: FilterAlertRequest = {
    ...requestPayload,
    alertType: "mc",
    pageNo: params.payload.alertPagination.mc.pageNumber - 1,
    pageSize: params.payload.alertPagination.mc.pageSize,
    customer_id: params.payload.customer_id,
    timeZone: browserTimeZone,
  };
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getfilteralertdata",
    mcFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as Alert;
}

async function getFilteredVcuAlert(
  requestPayload: FilterAlertRequest,
  params: IAlertActions
) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mcFilter: FilterAlertRequest = {
    ...requestPayload,
    alertType: "vcu",
    pageNo: params.payload.alertPagination.vcu.pageNumber - 1,
    pageSize: params.payload.alertPagination.vcu.pageSize,
    customer_id: params.payload.customer_id,
    timeZone: browserTimeZone,
  };
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getfilteralertdata",
    mcFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as Alert;
}

async function getFilteredChargerAlert(
  requestPayload: FilterAlertRequest,
  params: IAlertActions
) {
  const tokenRes = await getToken();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mcFilter: FilterAlertRequest = {
    ...requestPayload,
    alertType: "charger",
    customer_id: params.payload.customer_id,
    timeZone: browserTimeZone,
    pageNo: params.payload.alertPagination.charger.pageNumber - 1,
    pageSize: params.payload.alertPagination.charger.pageSize,
  };
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getfilteralertdata",
    mcFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data as Alert;
}

// function pastAlertDataGenerator() {
//     let datas: any = []
//     for (var i = 110; i < 202; i++) {
//         datas.push({
//             alertId: String(i),
//             time: i + "-May-2020 10:05AM",
//             openSince: "24 hrs " + i + "0 min",
//             vehicleId: "BDS" + i,
//             location: "Bangalore " + i,
//             Severity: i % 3,
//             alertName: 'my alert',
//             model: 'Classic',
//             mfgDate: i + "-May-2020 10:05AM",
//             batteryId: i,
//             customerId: i,
//             frameId: '111111111',
//             alertTime: i + "-May-2020 10:05AM",
//         })
//     }
//     const pastAlert: any = {
//         dataCount: 202 - 130,
//         data: datas
//     }
//     return pastAlert
// }
