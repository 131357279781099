import axios from "axios";
import { call, put } from "redux-saga/effects";
import { getToken } from "../authentication";

export const GET_VEHICLE_INSIGHTS_CHARTS = "GET_VEHICLE_INSIGHTS_CHARTS";
export const STORE_VEHICLE_INSIGHTS_CHARTS = "STORE_VEHICLE_INSIGHTS_CHARTS";
export const LOADING_VEHICLE_INSIGHTS_CHARTS =
  "LOADING_VEHICLE_INSIGHTS_CHARTS";
export const STORE_VEHICLE_INSIGHTS_CHARTS_ERROR =
  "STORE_VEHICLE_INSIGHTS_CHARTS_ERROR";

export const GET_TRIP_INSIGHTS_CHARTS = "GET_TRIP_INSIGHTS_CHARTS";
export const STORE_TRIP_INSIGHTS_CHARTS = "STORE_TRIP_INSIGHTS_CHARTS";

export const GET_BATTERY_INSIGHTS_CHARTS = "GET_BATTERY_INSIGHTS_CHARTS";
export const STORE_BATTERY_INSIGHTS_CHARTS = "STORE_BATTERY_INSIGHTS_CHARTS";
export const LOADING_BATTERY_INSIGHTS_CHARTS =
  "LOADING_BATTERY_INSIGHTS_CHARTS";
export const STORE_BATTERY_INSIGHTS_CHARTS_ERROR =
  "STORE_BATTERY_INSIGHTS_CHARTS_ERROR";

export interface ILoadingVehicleInsightsChart {
  type: "LOADING_VEHICLE_INSIGHTS_CHARTS";
  payload: any;
}
export interface ILoadingBatteryInsightsChart {
  type: "LOADING_BATTERY_INSIGHTS_CHARTS";
  payload: any;
}
export interface IStoreVehicleInsightsError {
  type: "STORE_VEHICLE_INSIGHTS_CHARTS_ERROR";
  payload: any;
}
export interface IStoreBatteryInsightsError {
  type: "STORE_BATTERY_INSIGHTS_CHARTS_ERROR";
  payload: any;
}

export function updateVehicleInsightErrorStatus(params:IStoreVehicleInsightsError){
  return {
    type:params.type,
    payload:params.payload
  }
}
export function updateBatteryInsightErrorStatus(params:IStoreBatteryInsightsError){
  return {
    type:params.type,
    payload:params.payload
  }
}

export function updateLoadingVehicleInsightsStatus(
  params: ILoadingVehicleInsightsChart
) {
  return {
    type: params.type,
    payload: params.payload,
  };
}

export function updateBatteryVehicleInsightsStatus(
  params: ILoadingBatteryInsightsChart
) {
  return {
    type: params.type,
    payload: params.payload,
  };
}

export interface IgetVehicleInsightsCharts {
  type: "STORE_VEHICLE_INSIGHTS_CHARTS";
  payload: any;
}

export interface IgetTripInsightsCharts {
  type: "STORE_TRIP_INSIGHTS_CHARTS";
  payload: any;
}

export interface IgetBatteryInsightsCharts {
  type: "STORE_BATTERY_INSIGHTS_CHARTS";
  payload: any;
}

export function* getVehicleDetailsChartApi(params: any) {
  try {
    let { data } = yield call(getVehicleDetails, params);
    if (data === undefined) {
      yield put({
        type: "STORE_VEHICLE_INSIGHTS_CHARTS_ERROR",
        payload: true,
      });
    }
    console.log("getVehicleDetailsChartApi", data);
    yield put({
      type: "STORE_VEHICLE_INSIGHTS_CHARTS",
      payload: data,
    });
  } catch (error) {
    console.error("VehicleInsightError", error);
  }
}

export function* getTripDetailsChartApi(params: any) {
  let { data } = yield call(getTripDetails, params);
  yield put({
    type: "STORE_TRIP_INSIGHTS_CHARTS",
    payload: data,
  });
}

export function* getBatteryDetailsChartApi(params: any) {
  try {
    let { data } = yield call(getBatteryDetails, params);
    if (data === undefined) {
      yield put({
        type: "STORE_BATTERY_INSIGHTS_CHARTS_ERROR",
        payload: true,
      });
    }
    yield put({
      type: "STORE_BATTERY_INSIGHTS_CHARTS",
      payload: data,
    }); 
  } catch (error) {
    console.error("GetBatteryDeatilsError",error)
  }
}

async function getVehicleDetails(params: any) {
  try {
    const tokenRes = await getToken();
    const response = await axios.post(
      process.env.REACT_APP_WEBAPPAPI + "/getComponentData",
      [
        {
          comp_name: "MAP_POPUP",
          data_event: params.payload.data_event,
          comp_order: 1,
          keys: {
            org_id: params.payload.org_id,
            customer_id: params.payload.customer_id,
            filter_type: params.payload.filter_type,
            loc_type:params.payload.loc_type,
            loc_name:params.payload.loc_name,
            
          },
          values: {},
        },
      ],
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token!,
        },
      }
    );
    return response;
  } catch (error) {
    // yield put({
    //   type:"STORE_VEHICLE_INSIGHTS_CHARTS_ERROR",
    //   payload:true
    // })
    return "error";
  }
}

async function getTripDetails(params: any) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getComponentData",
    [
      {
        comp_name: "MAP_POPUP",
        data_event: params.payload.data_event,
        comp_order: 1,
        keys: {
          org_id: params.payload.org_id,
          customer_id: params.payload.customer_id,
          filter_type: params.payload.filter_type,
        },
        values: {},
      },
    ],
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response;
}

async function getBatteryDetails(params: any) {
  try {
    const tokenRes = await getToken();
    const response = await axios.post(
      process.env.REACT_APP_WEBAPPAPI + "/getComponentData",
      [
        {
          comp_name: "MAP_POPUP",
          data_event: params.payload.data_event,
          comp_order: 1,
          keys: {
            org_id: params.payload.org_id,
            customer_id: params.payload.customer_id,
            filter_type: params.payload.filter_type,
            loc_type: params.payload.loc_type,
            loc_name: params.payload.loc_name,
           
          },
          values: {},
        },
      ],
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token!,
        },
      }
    );
    return response;
  } catch (error) {
    return "error";
  }
}
