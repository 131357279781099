import Amplify, { Auth } from "aws-amplify";
import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_WEBAPPUSERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_WEBAPPUSERPOOLWEBCLIENTID,
  },
});

export async function signout() {
  await Auth.signOut().then(async () => {});
}

export function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      return {
        user,
        success: true,
        message: null,
      };
    })
    .catch((err) => {
      return {
        message: err.message,
        success: false,
        user: null,
      };
    });
}

export async function getToken() {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken();

    return {
      success: true,
      token: token.getJwtToken(),
      message: "Success",
    };
  } catch (error) {
    return {
      success: false,
      token: null,
      message: "Error getting token",
    };
  }
}

export function signIn(username: string, password: string) {
  return Auth.signIn({ username, password })
    .then(async (user) => {
      console.log("Logged in :", user);
      console.log(await getToken());
      return {
        user,
        success: true,
        message: "User signed in",
      };
    })
    .catch((err) => {
      console.log("Error", err);
      return {
        message: err.message,
        success: false,
        user: null,
      };
    });
}
export function forgotPasswordSubmit(
  username: string,
  code: string,
  password: string
) {
  return Auth.forgotPasswordSubmit(username, code, password)
    .then(async () => {
      return {
        success: true,
        message: "Password Reset Successfull",
      };
    })
    .catch((err) => {
      return {
        success: false,
        message: err.message,
      };
    });
}
export async function createPasswordSubmit(
  this: any,
  username: string,
  org_id: string,
  password: string
) {
  try {
    const tokenRes = await getToken();
  const batterydata = await axios.post(
    "https://prod-evapi-web.connectm.com/verifyUser",
    
        {
            orgId:org_id,
            emailId:username,
            identity_id:"us-east-1_XtSzTbktc", 
            password:password
        }
    ,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    })
    return batterydata 
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export function initiateForgotPassword(username: string) {
  console.log("kfv", username);
  console.log("kvf1");
  return Auth.forgotPassword(username)
    .then((data) => {
      console.log(data, "asdg");
      return {
        success: true,
        message: "Forgot password initiated successfully",
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        success: false,
        message: err.message,
      };
    });
}
