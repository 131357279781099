import { call, put, select } from "redux-saga/effects";
import { IGetBatteryListBYStationId, IGetBookingDetailsByStationId, IGetOverviewCountBatteries, IGetOverviewCountConsumers, IGetOverviewCountStations, IGetOverviewCountSwaps, IGetRidersList, IGetSlotDistribution, IGetStationDetailsById, IGetSwapHistoryByStationId, IGetSwapHistoryByUserId, IGetSwapUsageFor7days, IGetSwappingStationLists, IGetUsageOverview } from "../../actions/swappingStation/swapping-station";
import { getBatteryListByStationIdFromDynamo, getBookingDetailsByStationIdFromDynamo, getRidersListByNameFromApi, getRidersListFromApi, getSlotDistributionOverviewFromApi, getStationDetailsByIdFromDynamo, getSwapHistoryByStationIdFromDynamo, getSwapHistoryByUserIdFromDynamo, getSwappingStationsListByNameFromApi, getSwappingStationsListFromApi, getTotalBatteriesCountFromApi, getTotalConsumersCountFromApi, getTotalStationsCountFromApi, getTotalSwapsCountFromApi, getUsageOverviewFromApi } from "./dynamocalls";

export function* getTotalStationsCountWithParams(params:IGetOverviewCountStations){
    try {
        const user: any = yield select((state) => state.user);
        let {data} = yield call(getTotalStationsCountFromApi,params,user)
        yield put({
            type:"STORE_OVERVIEW_COUNT_STATIONS",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getTotalBatteriesCountWithParams(params:IGetOverviewCountBatteries){
    try {
        let {data} = yield call(getTotalBatteriesCountFromApi,params)
        yield put({
            type:"STORE_OVERVIEW_COUNT_BATTERIES",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getTotalConsumersCountWithParams(params:IGetOverviewCountConsumers){
    try {
        const user: any = yield select((state) => state.user);
        let {data} = yield call(getTotalConsumersCountFromApi,params,user)
        yield put({
            type:"STORE_OVERVIEW_COUNT_CONSUMERS",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getTotalSwapsCountWithParams(params:IGetOverviewCountSwaps){
    try {
        let {data} = yield call(getTotalSwapsCountFromApi,params)
        yield put({
            type:"STORE_OVERVIEW_COUNT_SWAPS",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getSwappingStationList(params:IGetSwappingStationLists){
    try {
        const user: any = yield select((state) => state.user);
        let {data} = yield call(getSwappingStationsListFromApi,params,user)
        yield put({
            type:"STORE_SWAPPING_STATION_LISTS",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getSwappingStationListByName(params:any){
    try {
        const user: any = yield select((state) => state.user);
        let {data} = yield call(getSwappingStationsListByNameFromApi,params,user)
        yield put({
            type:"STORE_SWAPPING_STATION_LISTS",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getRidersListByName(params:any){
    try {
        const user: any = yield select((state) => state.user);
        let {data} = yield call(getRidersListByNameFromApi,params,user)
        yield put({
            type:"STORE_GET_RIDERS_LIST",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getUsageOveriew(params:IGetUsageOverview){
    try {
        let {data} = yield call(getUsageOverviewFromApi,params)
        yield put({
            type:"STORE_USAGE_OVERVIEW",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getSlotDistributionOveriew(params:IGetSlotDistribution){
    try {
        let {data} = yield call(getSlotDistributionOverviewFromApi,params)
        yield put({
            type:"STORE_SLOT_DISTRIBUTION",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getStationDetailsByIdFromPayload(params:IGetStationDetailsById){
    try {
        let {data} = yield call(getStationDetailsByIdFromDynamo,params)
        yield put({
            type:"STORE_STATION_DETAILS_BY_ID",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getBatteryListByStationIdFromPayload(params:IGetBatteryListBYStationId){
    try {
        let {data} = yield call(getBatteryListByStationIdFromDynamo,params)
        yield put({
            type:"STORE_BATTERY_LIST_BY_STATION_ID",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getSwapByStationIdFromPayload(params:IGetSwapHistoryByStationId){
    try {
        let {data} = yield call(getSwapHistoryByStationIdFromDynamo,params)
        yield put({
            type:"STORE_SWAP_HISTORY_BY_STATION_ID",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getBookingDeatilsIdFromPayload(params:IGetBookingDetailsByStationId){
    try {
        let {data} = yield call(getBookingDetailsByStationIdFromDynamo,params)
        yield put({
            type:"STORE_BOOKING_DETAILS_BY_STATION_ID",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}

export function* getRidersListFromPayload(params:IGetRidersList){
    try {
        const user: any = yield select((state) => state.user);
        let {data} = yield call(getRidersListFromApi,params,user)
        yield put({
            type:"STORE_GET_RIDERS_LIST",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}



export function* getSwapByUserIdFromPayload(params:IGetSwapHistoryByUserId){
    try {
        let {data} = yield call(getSwapHistoryByUserIdFromDynamo,params)
        yield put({
            type:"STORE_SWAP_HISTORY_BY_USER_ID",
            payload:data
        })
    } catch (error) {
        console.error(error)
    }
}