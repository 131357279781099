import axios from "axios";
import { call, put } from "redux-saga/effects";
import {
  IAction,
  IComponentAction,
  ISubModuleAction,
} from "../actions/cmd-details-action";
import { getToken } from "../authentication";
import { TModule, TSubModule, TCustomerComponentData } from "../redux/models";
import { getData } from "./../../connectm-client/saga/cmd-details-dynamo";
import connectmState, { State } from "../redux/connectm-state";
import { message } from "antd";
import { userInfo } from "os";
import { select } from "redux-saga/effects";
import { useDispatch } from "react-redux";
const state: State = connectmState;

export type Store_CmdService = {
  type: "STORE_MODULES";
  payload: {
    module: TModule[];
  };
};
export type Store_CmdSubModule = {
  type: "STORE_SUB_MODULES";
  payload: {
    subModule: TSubModule;
  };
};
export type Store_ComponentData = {
  type: "STORE_COMPONENT_DATA";
  payload: {
    componentData: TCustomerComponentData;
  };
};
export type Store_Submit_Response = {
  type: "STORE_SUBMIT_RESPONSE";
  payload: {
    submitResponse: any;
  };
};
export type Store_widget_ComponentData = {
  type: "STORE_WIDGET_COMPONENT_DATA";
  payload: {
    WidgetcomponentData: TCustomerComponentData[];
  };
};

export type Store_dropdown_ComponentData = {
  type: "STORE_DROPDOWN_COMPONENT_DATA";
  payload: {
    dropdownComponentData: TCustomerComponentData[];
  };
};

export type Store_status_widget_ComponentData = {
  type: "STORE_STATUS_WIDGET_COMPONENT_DATA";
  payload: {
    StatuswidgetcomponentData: TCustomerComponentData[];
  };
};
export type Store_qs_DashboardData = {
  type: "STORE_QS_DASHBOARD_DATA";
  payload: {
    qsDashboardData: any;
  };
};

export type Store_description_widget_ComponentData = {
  type: "STORE_DESCRIPTION_WIDGET_COMPONENT_DATA";
  payload: {
    DescriptionwidgetcomponentData: TCustomerComponentData[];
  };
};

export type clear_module_ComponentData = {
  type: "CLEAR_MODULE_COMPONENT_DATA";
};

export function* getModules(params: IAction) {
  const user: any = yield select((state) => state.user);
  try {
    const data: TModule[] = yield call(
      getModulesApi,
      params,
      user.user.attributes["custom:role"],
      user.user.attributes["custom:orgId"]
    );
    yield put({
      type: "STORE_MODULES",
      payload: {
        module: data,
      },
    } as Store_CmdService);
  } catch (error) {
    console.log("get dashboardlist error", error);
  }
}

export function* getSubModules(params: ISubModuleAction) {
  const user: any = yield select((state) => state.user);
  // console.log(user.user.attributes["custom:role"]);
  try {
    const data: TSubModule = yield call(
      getSubModulesApi,
      params,
      user.user.attributes["custom:role"],
      user.user.attributes["custom:orgId"]
    );
    yield put({
      type: "STORE_SUB_MODULES",
      payload: {
        subModule: data,
      },
    } as Store_CmdSubModule);
    yield put({
      type:"MODULE_IS_LOADING",
      payload:false
    })
  } catch (error) {
    console.log("get dashboardlist error", error);
  }
}

export function* getComponentData(params: IComponentAction) {
  console.log("getCompParams",params)
  const user: any = yield select((state) => state.user);
  try {
    const data: TCustomerComponentData = yield call(
      getComponentDataApi,
      params,
      user.user.attributes
    );
    yield put({
      type: "STORE_COMPONENT_DATA",
      payload: {
        componentData: data,
      },
    } as Store_ComponentData);
  } catch (error) {
    console.log("get component data error", error);
  }
}

export function* submitData(params: any) {
  try {
    const data: any = yield call(submitComponentDataApi, params);
    yield put({
      type: "STORE_SUBMIT_RESPONSE",
      payload: {
        submitResponse: data,
      },
    } as Store_Submit_Response);
  } catch (error) {
    console.log("Submit data error", error);
  }
}

export function pushWidgetData(data: any, params: any) {
  // console.log(data, params);
  data.label = params.payload.label;
  // console.log(data);
  connectmState.WidgetcomponentData.push(data);
  //console.log(connectmState.dropdownComponentData,"dropdownaftersuccess")
  return connectmState.WidgetcomponentData;
}
export function* getWidgetComponentData(params: IComponentAction) {
  const user: any = yield select((state) => state.user);
  try {
    const data: TCustomerComponentData = yield call(
      getComponentDataApi,
      params,
      user.user.attributes
    );
    const info = pushWidgetData(data, params);
    yield put({
      type: "STORE_WIDGET_COMPONENT_DATA",
      payload: {
        WidgetcomponentData: info,
      },
    } as Store_widget_ComponentData);
  } catch (error) {
    console.log("get component data error", error);
  }
}

export function pushDropdownData(data: any) {
  connectmState.dropdownComponentData = [
    ...connectmState.dropdownComponentData,
    data,
  ];
  return connectmState.dropdownComponentData;
}
export function* getDropdownComponentData(params: IComponentAction) {
  const user: any = yield select((state) => state.user);
  try {
    const data: TCustomerComponentData = yield call(
      getComponentDataApi,
      params,
      user.user.attributes
    );
    const info = pushDropdownData(data);
    yield put({
      type: "STORE_DROPDOWN_COMPONENT_DATA",
      payload: {
        dropdownComponentData: info,
      },
    } as Store_dropdown_ComponentData);
  } catch (error) {
    console.log("get component data error", error);
  }
}

export function pushstatuswidgetData(data: any, params: any) {
  data.label = params.payload.label;
  connectmState.StatuswidgetcomponentData.push(data);
  return connectmState.StatuswidgetcomponentData;
}
export function* getStatusWidgetComponentData(params: IComponentAction) {
  const user: any = yield select((state) => state.user);
  try {
    const data: TCustomerComponentData = yield call(
      getComponentDataApi,
      params,
      user.user.attributes
    );
    const info = pushstatuswidgetData(data, params);
    yield put({
      type: "STORE_STATUS_WIDGET_COMPONENT_DATA",
      payload: {
        StatuswidgetcomponentData: info,
      },
    } as Store_status_widget_ComponentData);
  } catch (error) {
    console.log("get component data error", error);
  }
}

export function pushdescriptionwidgetData(data: any, params: any) {
  data.label = params.payload.label;
  connectmState.DescriptionwidgetcomponentData.push(data);
  return connectmState.DescriptionwidgetcomponentData;
}
export function* getDescriptionWidgetComponentData(params: IComponentAction) {
  const user: any = yield select((state) => state.user);
  try {
    const data: TCustomerComponentData = yield call(
      getComponentDataApi,
      params,
      user.user.attributes
    );
    const info = pushdescriptionwidgetData(data, params);
    yield put({
      type: "STORE_DESCRIPTION_WIDGET_COMPONENT_DATA",
      payload: {
        DescriptionwidgetcomponentData: info,
      },
    } as Store_description_widget_ComponentData);
  } catch (error) {
    console.log("get component data error", error);
  }
}

export function pushDashboardData(data: any) {
  connectmState.qsDashboardData = data;
  return connectmState.qsDashboardData;
}

export function* getQsDashboardData(params: IComponentAction) {
  const user: any = yield select((state) => state.user);
  try {
    const data: TCustomerComponentData = yield call(
      getComponentDataApi,
      params,
      user.user.attributes
    );
    const info = pushDashboardData(data);
    yield put({
      type: "STORE_QS_DASHBOARD_DATA",
      payload: {
        qsDashboardData: info,
      },
    } as Store_qs_DashboardData);
  } catch (error) {
    console.log("get component data error", error);
  }
}

export function* clearmoduleComponentData(params: IComponentAction) {
  yield put({
    type: "CLEAR_MODULE_COMPONENT_DATA",
  } as clear_module_ComponentData);
}

///////////////data payload and headers and api calls information //////////////////////////////////////////////////////////////
export async function getModulesApi(params: IAction, userRole: any, org_id: any) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI +
    "/getModule?app_id=" +
    params.payload.appId +
    "&service_id=" +
    params.payload.serviceId +
    "&role=" +
    userRole +
    "&org_id=" +
    org_id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data.data as TModule[];
}

export async function getSubModulesApi(
  params: ISubModuleAction,
  userRole: any,
  orgId: any
) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI +
    "/getSubModule?module_id=" +
    params.payload.moduleId +
    "&role=" +
    userRole +
    "&org_id=" +
    orgId,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data.data as TSubModule;
}

export async function getComponentDataApi(params: any, user: any) {
  console.log(params , "paramstest")
  console.log("gridCompgridComp", params.payload.key)
  let compKeys: any = params.payload.key
  compKeys.org_id = user["custom:orgId"]
  compKeys.customer_id = user["custom:customerId"]
  let userInfo = {
    role: user["custom:role"],
    email_verified: user["email_verified"],
    group: user["custom:role"],
    user_name: user["sub"],
    org_id: user["custom:orgId"]
  };
  const tokenRes = await getToken();
  if (params.type == "GRID" && isJson(params.payload.dataEvent)) {
    
    console.log("if")
    let amplifyQuery: any = JSON.parse(JSON.stringify({
      "query": JSON.parse(params.payload.dataEvent)["query"],
      "filter": JSON.parse(params.payload.dataEvent)["filter"],
      "columns": JSON.parse(params.payload.dataEvent)["columns"],
      "include_arrays": JSON.parse(params.payload.dataEvent)["include_params"],
    }))
    let a1: any = await getData(amplifyQuery, params.payload.searchValue, user["custom:orgId"])
    if (params?.payload.sortingField && params?.payload?.sortingField.length != 0) {
      if (params?.payload?.sortingDirection == "asc") {
        let o_data = a1
        let return_data = sortByKey(o_data.data.data_source, params?.payload.sortingField)
        o_data.data.data_source = return_data
        if (Object.keys(params.payload.key).length <= 2) {
          return o_data
        } else {
          return filterBasedOnKeys(o_data, params.payload.key)
        }
      } else {
        let o_data = a1
        let return_data = sortByKey(o_data.data.data_source, params?.payload.sortingField)
        o_data.data.data_source = return_data.reverse()
        if (Object.keys(params.payload.key).length <= 2) {
          return o_data
        } else {
          return filterBasedOnKeys(o_data, params.payload.key)
        }
      }
    } else {
      if (Object.keys(params.payload.key).length <= 2) {
        return a1
      } else {
        return filterBasedOnKeys(a1, params.payload.key)
      }
    }
  } 

  else if( params.payload.dataEvent === "DYNAMO_RIDE_EVT"){
    console.log(compKeys , "compkeys")
    console.log(params.payload, "paramspay")
    const response = await axios.post(
      "https://f75axjfo03.execute-api.us-east-1.amazonaws.com/yalla/ridesdetailV2",
      
        {
          comp_name: params.payload.componentName,
          data_event: params.payload.dataEvent,
          comp_label: params.payload.comp_label,
          display_name: params.payload.displayName,
          comp_order: params.payload.order,
          keys: compKeys,
          values: params.payload.values,
          user_info: userInfo,
          pageNo: params.payload.pageNo - 1 || 0,
          pageSize: params.payload.dataEvent === "DYNAMO_RIDE_EVT" ? 50 : (params.payload.pageSize || 100),
          searchValue: params.payload.searchValue || "",
          sortingDirection: params.payload.sortingDirection || "",
          sortingField: params.payload.sortingField || "",
          lastEvaluatedKey : (compKeys.hasOwnProperty("org_id") && compKeys.payment_status === undefined && compKeys.ride_status === undefined && params.payload.searchValue !== "") 
          ? null 
          : params.payload.lastEvaluatedKey,
          pageable:false
          // lastEvaluatedKey: params.payload.lastEvaluatedKey||null
        },
      
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token!,
        },
      }
    );
    return response.data as TCustomerComponentData;

  }

  else if( params.payload.dataEvent === "DYNAMO_PAYMENT_EVT"){
    console.log(compKeys , "compkeys")
    console.log(params.payload, "paramspay")
    const response = await axios.post(
      "https://f75axjfo03.execute-api.us-east-1.amazonaws.com/yalla/paymentsdetailV2",
      
        {
          comp_name: params.payload.componentName,
          data_event: params.payload.dataEvent,
          comp_label: params.payload.comp_label,
          display_name: params.payload.displayName,
          comp_order: params.payload.order,
          keys: compKeys,
          values: params.payload.values,
          user_info: userInfo,
          pageNo: params.payload.pageNo - 1 || 0,
          pageSize: params.payload.dataEvent === "DYNAMO_PAYMENT_EVT" ? 50 : (params.payload.pageSize || 100),
          searchValue: params.payload.searchValue || "",
          sortingDirection: params.payload.sortingDirection || "",
          sortingField: params.payload.sortingField || "",
          lastEvaluatedKey : (compKeys.hasOwnProperty("org_id") && compKeys.mobile === undefined && compKeys.narration === undefined && params.payload.searchValue !== "") 
          ? null 
          : params.payload.lastEvaluatedKey,
          pageable:false
        },
      
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token!,
        },
      }
    );
    return response.data as TCustomerComponentData;

  }
  
  else {
  
    const response = await axios.post(
      process.env.REACT_APP_WEBAPPAPI + "/getComponentData",
      [
        {
          comp_name: params.payload.componentName,
          data_event: params.payload.dataEvent,
          comp_label: params.payload.label,
          display_name: params.payload.displayName,
          comp_order: params.payload.order,
          keys: compKeys,
          values: params.payload.values,
          user_info: userInfo,
          pageNo: params.payload.pageNo - 1 || 0,
          pageSize: params.payload.dataEvent === "DYNAMO_RIDE_EVT" ? 50 : (params.payload.pageSize || 100),
          searchValue: params.payload.searchValue || "",
          sortingDirection: params.payload.sortingDirection || "",
          sortingField: params.payload.sortingField || "",
          lastEvaluatedKey:params.payload.lastEvaluatedKey||null,
          pageable:false
        },
      ],
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token!,
        },
      }
    );

    return response.data as TCustomerComponentData;
  }
}
export async function submitComponentDataApi(params: any) {
  const tokenRes = await getToken();
  if (!Object.keys(params.payload).includes("checked_items") || params.payload.checked_items.length <= 1) {
    const response = await axios.post(
      process.env.REACT_APP_WEBAPPAPI + "/submitComponentData",
      [
        {
          comp_name: params.payload.comp_name,
          data_event: params.payload.data_event,
          comp_label: params.payload.comp_label,
          values: params.payload.values,
          keys: params.payload.keys,
        },
      ],
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token!,
        },
      }
    );
    if (response.data.code == 200) {
      message.success(response.data.message);
    } else {
      message.warning("Something went Wrong !! Please Try Again . ");
    }
    return response.data as any;
  } else {
    const newValues = new Array()
    const newKeys = new Array()
    const keyObjects = Object.keys(params.payload.keys)
    newValues.push(params.payload.values)
    params.payload.checked_items.map((item: any) => {
      let data: any = {}
      keyObjects.map((item1: any) => {
        data[item1] = item[item1]
      })
      newKeys.push(data)
    })

    const response = await axios.post(
      process.env.REACT_APP_WEBAPPAPI + "/submitComponentMultipleData",
      [
        {
          comp_name: params.payload.comp_name,
          data_event: params.payload.data_event,
          comp_label: params.payload.comp_label,
          values: newValues,
          keys: newKeys,
        },
      ],
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token!,
        },
      }
    );
    if (response.data.code == 200) {
      message.success(response.data.message);
    } else {
      message.warning("Something went Wrong !! Please Try Again . ");
    }
    return response.data as any;
  }
}
export function isJson(a: any) {
  try {
    JSON.parse(a);
    return true;
  } catch (e) {
    return false;
  }
}

export function sortByKey(array: any, key: any) {
  return array.sort(function (a: any, b: any) {
    var x = a[key]; var y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

export function filterBasedOnKeys(data_source: any, keyz: any) {
  let prop_keys = JSON.parse(JSON.stringify(keyz));
  for(var iKey in prop_keys){
    if(prop_keys[iKey] == "All"){
      delete prop_keys[iKey]
    }
  }
  delete prop_keys["customer_id"]
  let grid_data = data_source
  let new_array: any = []

  if (data_source.data.data_source.length != 0) {
    new_array = data_source.data.data_source.filter((item: any) => {
      for (let key in prop_keys) {
        if (item[key] === undefined || item[key] != prop_keys[key])
          return false;
      }
      return true;
    });
    grid_data.data.data_source = new_array
    grid_data.totalNoOfElements = new_array.length

    return grid_data
  } else {
    return data_source
  }
}