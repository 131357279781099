import { message } from 'antd';
import axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IAddNewIotDevice, IAddThingToThingGroup, IAssignFirmwareToOrg, IBulkDeviceRegistration, ICreateThingGroup, ICreateDeviceGroup, IDeleteFirmware, IDeleteIotDevices, IDeleteIotDeviceGroups, IDeleteIotJobs, IFirmwareLIstDropDown, IGetAllAssignedFirmWare, IGetAllDevices, IGetAllFirmWare, IgetAllDeviceGroups, IGetJobDetailsByJobId,IgetAllIotDevice, IgetAllJobs, IGetAllThingGroups, IGetDeviceGroupDetailsByDeviceGroupId, IgetOrgs, IGetThingGroupDetailsByName, IinitiateOta, IinitiateOtaBulk, ISearchAllThingGroup, ISearchIotDevices, IUnAssignedFirmWareFromOrg, IUploadFirmWare, IgetAllFilterDevices, IgetAllFilterDeviceDropdownList, IAddDevicesToGroup, IGetDevicesfromGroup, IDeleteDevice } from '../actions/device-control';
import { getToken } from '../authentication';

export function* getAllFirmWare(params: IGetAllFirmWare) {
  try {
    let { data } = yield call(getAllFirmWareApi, params)
    yield put({
      type: "STORE_ALL_FIRMWARE",
      payload: data
    })
  } catch (error) {
    console.error("getAllFirmWare", error)
  }
}

export async function getAllFirmWareApi(params: IGetAllFirmWare) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getAllFirmware`,
    {
      pageNo: params.payload.pageNo,
      pageSize: params.payload.pageSize,
      searchValue: params.payload.searchValue,
      sortingField: params.payload.sortingField,
      sortingOrder: params.payload.sortingOrder
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response
}

export function* uploadFirmware(params: IUploadFirmWare) {
  try {
    let { data } = yield call(uploadFirmwareApi, params)
    // yield put({
    //     type:"STORE_ALL_FIRMWARE",
    //     payload:data
    // })
  } catch (error) {
    console.error("uploadFirmware", error)
  }
}

export async function uploadFirmwareApi(params: IUploadFirmWare) {
  const formData = new FormData();
  formData.append('firmware_file', params.payload?.firmware_file);
  formData.append('body', JSON.stringify(params.payload?.body));
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/uploadFirmware`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: tokenRes.token!,
      },
    },
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response
}

export function* deleteFirmware(params: IDeleteFirmware) {
  try {
    let { data } = yield call(deleteFirmwareApi, params)
    // yield put({
    //     type:"STORE_ALL_ROLES",
    //     payload:data
    // })
  } catch (error) {
    console.error("deleteFirmware", error)
  }
}

export async function deleteFirmwareApi(params: IDeleteFirmware) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI + `/deleteFirmware?firmware_id=${params.payload.firmware_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response?.data?.code === 400 ) {
    message.error(response?.data?.message)
  }
  else if(response?.data?.message){
    message.success(response?.data?.message)
  }
  return response.data
}


export function* dropdownOrg(params: IgetOrgs) {
  try {
    let { data } = yield call(dropdownOrgApi, params)
    yield put({
      type: "STORE_ORG_DROPDOWN",
      payload: data
    })
  } catch (error) {
    console.error("dropdownOrg", error)
  }
}

export async function dropdownOrgApi(params: IgetOrgs) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI + `/getOrgs`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}


export function* assignFirmwareToOrg(params: IAssignFirmwareToOrg) {
  try {
    let { data } = yield call(assignFirmwareToOrgApi, params)
    // yield put({
    //     type:"STORE_ALL_FIRMWARE",
    //     payload:data
    // })
  } catch (error) {
    console.error("assignFirmwareToOrg", error)
  }
}

export async function assignFirmwareToOrgApi(params: IAssignFirmwareToOrg | any) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/assignFirmware`,
    {
      org_id: parseInt(params.payload.org_id),
      firmware_id: params.payload.firmware_id,
      description: params.payload.description,
      catalogId: params.payload.catalog_id,
      abbreviation: params.payload.abbreviation
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response
}


export function* getAllAssignedFirmware(params: IGetAllAssignedFirmWare) {
  try {
    let { data } = yield call(getAllAssignedFirmwareApi, params)
    yield put({
      type: "STORE_ALL_ASSIGNED_FIRMWARE",
      payload: data
    })
  } catch (error) {
    console.error("getAllAssignedFirmware", error)
  }
}

export async function getAllAssignedFirmwareApi(params: IGetAllAssignedFirmWare) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getAllAssignedFirmware`,
    {
      pageNo: params.payload.pageNo,
      pageSize: params.payload.pageSize,
      searchValue: params.payload.searchValue,
      sortingField: params.payload.sortingField,
      sortingOrder: params.payload.sortingOrder,
      org_id: parseInt(params.payload.org_id)
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response
}


export function* unAssignFirmwareToOrg(params: IUnAssignedFirmWareFromOrg) {
  try {
    let { data } = yield call(unAssignFirmwareToOrgApi, params)
    yield put({
      type: "STORE_UNASSIGNED_FIRMWARE",
      payload: data
    })
  } catch (error) {
    console.error("unAssignFirmwareToOrg", error)
  }
}

export async function unAssignFirmwareToOrgApi(params: IUnAssignedFirmWareFromOrg) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI + `/unassignFirmware?firmware_id=${params.payload.firmware_id}&org_id=${params.payload.org_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response.data
}

export function* getAllIotDevice(params: any) {
  try {
    const user: any = yield select((state) => state.user);
    params.orgId = user.user.attributes["custom:orgId"]
    let { data } = yield call(getAllIotDeviceApi, params)
    yield put({
      type: "STORE_ALL_IOT_DEVICE",
      payload: data
    })
    yield put({
      type: "IS_THING_LOADING",
      payload: false
    })
  } catch (error) {
    console.error("getAllIotDevice", error)
  }
}

export async function getAllIotDeviceApi(params: any) {
  console.log("getAllIotDevices", params)
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getAllIotDevices`,
    {
      itemsPerPage: params.payload.itemsPerPage,
      nextToken: params.payload.nextToken,
      org_id: parseInt(params.orgId)
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}


export function* searchIotDevice(params: ISearchIotDevices | any) {
  try {
    const user: any = yield select((state) => state.user);
    params.org_id = user.user.attributes["custom:orgId"]
    let { data } = yield call(searchIotDeviceApi, params)
    yield put({
      type: "STORE_SEARCH_IOT_DEVICE",
      payload: data
    })
  } catch (error) {
    console.error("searchIotDevice", error)
  }
}

///searchIotDeviceByFrameIdAndOrgId?frame_id=EVH001534&org_id=2

export async function searchIotDeviceApi(params: ISearchIotDevices | any) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI + `/searchIotDevice?keyword=${params.payload.keyword}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}


export function* addNewIotDevice(params: IAddNewIotDevice) {
  try {
    let { data } = yield call(addNewIotDeviceApi, params)
    // yield put({
    //   type: "STORE_ALL_IOT_DEVICE",
    //   payload: data
    // })
  } catch (error) {
    console.error("addNewIotDevice", error)
  }
}

export async function addNewIotDeviceApi(params: IAddNewIotDevice) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/addNewIotDeviceToAWS`,
    {
      thingName: params.payload.thingName,
      SIMNo: params.payload.SIMNo,
      VCUID: params.payload.VCUID,
      thingShadow: params.payload.thingShadow ? params.payload.thingShadow : "",
      orgId:params.payload.orgId
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response.data
}


export async function updateIotDeviceAPI(params: any) {
  console.log("inputParams", params)
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/updateIotDevice`,
    {
      thingName: params.thingName,
      SIMNo: params.SIMNo,
      VCUID: params.VCUID,
      thingShadow: params.thingShadow ? params.thingShadow : "",
      orgId: params.orgId
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}


export function* firmwareListDropdown(params: IFirmwareLIstDropDown | any) {
  try {
    const user: any = yield select((state) => state.user);
    params.org_id = user.user.attributes["custom:orgId"]
    let { data } = yield call(firmwareListDropdownApi, params)
    yield put({
      type: "STORE_FIRMWARE_LIST_DROPDOWN",
      payload: data
    })
  } catch (error) {
    console.error("firmwareListDropdown", error)
  }
}

export async function firmwareListDropdownApi(params: IFirmwareLIstDropDown | any) {
  console.log("inputParams", params)
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI + `/firmwareList?org_id=${params.org_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}

export function* initiateOta(params: IinitiateOta) {
  try {
    let { data } = yield call(initiateOtaApi, params)
    // yield put({
    //   type: "STORE_ALL_IOT_DEVICE",
    //   payload: data
    // })
  } catch (error) {
    console.error("initiateOta", error)
  }
}

export async function initiateOtaApi(params: IinitiateOta) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/initiateOTA`,
    {
      thingName: params.payload.thingName,
      thingArn: params.payload.thingArn,
      firmwareId: params.payload.firmwareId
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response.data
}


export function* getALlJobs(params: IgetAllJobs) {
  try {
    let { data } = yield call(getALlJobsApi, params)
    console.log("getALlJobsData",data)
    yield put({
      type: "STORE_ALL_JOBS",
      payload: data
    })
  } catch (error) {
    console.error("getALlJobs", error)
  }
}

export async function getALlJobsApi(params: IgetAllJobs) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getAllOtaJobs`,
    {
      org_id:params.payload.orgId,
      pageNo:params.payload.pageNo,
      pageSize:params.payload.pageSize,
      searchValue: params.payload.searchValue ? params.payload.searchValue : ""
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response
}


export function* deleteIotDevices(params: IDeleteIotDevices) {
  try {
    let { data } = yield call(deleteIotDevicesApi, params)
    // yield put({
    //   type: "STORE_DELETE_IOT_DEVICES",
    //   payload: data
    // })
  } catch (error) {
    console.error("deleteIotDevices", error)
  }
}

export async function deleteIotDevicesApi(params: IDeleteIotDevices) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/deleteIotDevices`,
    params.payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.data) {
    message.success(response.data.data)
  }
  return response.data
}

export function* deleteIotJobs(params: IDeleteIotJobs) {
  try {
    let { data } = yield call(deleteIotJobsApi, params)
    // yield put({
    //   type: "STORE_DELETE_IOT_JOBS",
    //   payload: data
    // })
  } catch (error) {
    console.error("deleteIotJobs", error)
  }
}

export async function deleteIotJobsApi(params: IDeleteIotJobs) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/deleteIotJobs`,
    params.payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.data) {
    message.success(response.data.data)
  }
  return response.data
}


export function* getJobDetailsByJobId(params: IGetJobDetailsByJobId) {
  try {
    let { data } = yield call(getJobDetailsByJobIdApi, params)
    yield put({
      type: "STORE_GET_JOB_DETAILS_BY_JOB_ID",
      payload: data
    })
  } catch (error) {
    console.error("getJobDetailsByJobId", error)
  }
}

export async function getJobDetailsByJobIdApi(params: IGetJobDetailsByJobId) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getJobDetailsByJobId`,
    {
      itemsPerPage: params.payload.itemsPerPage,
      nextToken: params.payload.nextToken,
      jobId: params.payload.jobId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}

export function* getAllDeviceGroups(params: IgetAllDeviceGroups) {
  try {
    let { data } = yield call(getAllDeviceGroupsApi, params)
    console.log("getALlJobsData",data)
    yield put({
      type: "STORE_ALL_DEVICE_GROUPS",
      payload: data
    })
  } catch (error) {
    console.error("getALlJobs", error)
  }
}

export async function getAllDeviceGroupsApi(params: IgetAllDeviceGroups) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getAllDeviceGroup`,
    {
      org_id:params.payload.orgId,
      pageNo:params.payload.pageNo,
      pageSize:params.payload.pageSize,
      searchValue: params.payload.searchValue ? params.payload.searchValue : ""
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response
}



export function* deleteIotDeviceGroups(params: IDeleteIotDeviceGroups) {
  try {
    let { data } = yield call(deleteIotDeviceGroupsApi, params)
    // yield put({
    //   type: "STORE_DELETE_IOT_JOBS",
    //   payload: data
    // })
  } catch (error) {
    console.error("deleteIotJobs", error)
  }
}

export async function deleteIotDeviceGroupsApi(params: IDeleteIotDeviceGroups) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI + `/deleteDeviceGroup?group_id=${ params.payload}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.data) {
    message.success(response.data.data)
  }
  return response.data
}


export function* deleteDevice(params: IDeleteDevice) {
  try {
    let { data } = yield call(deleteDeviceApi, params)
    // yield put({
    //   type: "STORE_DELETE_IOT_JOBS",
    //   payload: data
    // })
  } catch (error) {
    console.error("deleteIotJobs", error)
  }
}

export async function deleteDeviceApi(params: IDeleteDevice) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/removeDevicesFromGroup`,
    {
      groupId: params.payload.groupId,
    things: params.payload.things,
        
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response
}




export function* getDeviceGroupDetailsByDeviceGroupId(params: IGetDeviceGroupDetailsByDeviceGroupId) {
  try {
    let { data } = yield call(getDeviceGroupDetailsByDeviceGroupIdApi, params)
    yield put({
      type: "STORE_GET_GROUP_DETAILS_BY_GROUP_ID",
      payload: data
    })
  } catch (error) {
    console.error("getJobDetailsByJobId", error)
  }
}

export async function getDeviceGroupDetailsByDeviceGroupIdApi(params: IGetDeviceGroupDetailsByDeviceGroupId) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getJobDetailsByJobId`,
    {
      itemsPerPage: params.payload.itemsPerPage,
      nextToken: params.payload.nextToken,
      jobId: params.payload.jobId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}

export function* getAllFilterDevices(params: IgetAllFilterDevices) {
  try {
    let { data } = yield call( getAllFilterDevicesApi, params)
    console.log("getALlJobsData",data)
    yield put({
      type: "STORE_ALL_FILTER_DEVICES",
      payload: data
    })
  } catch (error) {
    console.error("getALlJobs", error)
  }
}




export async function getAllFilterDevicesApi(params: IgetAllFilterDevices) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/filterDevice`,
    {
    comp_name: "OTA_FILERT",
    data_event: "OTA_FILERT_EVT",
    comp_order: 1,
    keys: {
     
        org_id: params.payload.org_id,
        searchKey:params.payload.searchKey,
        firmware_version:params.payload.firmware_version,
        battery_model: params.payload.battery_model,
        catalog_name: params.payload.catalog_name
    },
    values: {},
    pageNo: params.payload.pageNo,
    pageSize: params.payload.pageSize
},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response
}


export function* addDevicesToGroup(params: IAddDevicesToGroup) {
  try {
    let { data } = yield call( addDevicesToGroupApi, params)
    console.log("getALlJobsData",data)
    yield put({
      type: "STORE_ALL_FILTER_DEVICES",
      payload: data
    })
  } catch (error) {
    console.error("getALlJobs", error)
  }
}


export async function addDevicesToGroupApi(params: IAddDevicesToGroup) {
  console.log("getadddevices" , params.payload)
  console.log("ajj")
  const tokenRes = await getToken();
  if(params.payload.things)
  {
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/addDevicesToGroup`,
    {
      groupId: params.payload.groupId,
      things: params.payload.things,
        
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response
}
else {

  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/addNewIotDeviceToAWS`,
    {
      thingName:params.payload.thingName,
      SIMNo: params.payload.SIMNo,
      VCUID: params.payload.VCUID,
      thingShadow: params.payload.thingShadow,
      orgId:params.payload.orgId     
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response
}

 
}

export function* getDevicesFromGroup(params: IGetDevicesfromGroup) {
  try {
    let { data } = yield call( getDevicesFromGroupApi, params)
    console.log("getALlJobsData",data)
    yield put({
      type: "STORE_GET_ALL_DEVICES_FROM_DEVICE_GROUP",
      payload: data
    })
  } catch (error) {
    console.error("getALlJobs", error)
  }
}


export async function getDevicesFromGroupApi(params: IGetDevicesfromGroup) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getDevicesFromGroup`,
    {
      groupId: params.payload.groupId,
      itemsPerPage: params.payload.itemsPerPage,
      nextToken: params.payload.nextToken, 
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.code === 200) {
    if (response?.data?.message) {
      message.success(response.data.message)
    }
  } else {
    if (response?.data?.message) {
      message.warn(response.data.message)
    }
  }
  return response

}



export function* getAllFilterDeviceDropdownValues(params: IgetAllFilterDeviceDropdownList) {
  try {
    let { data } = yield call(getAllFilterDeviceDropdownValuesApi, params)
    console.log("getALlJobsData",data)
    yield put({
      type: "STORE_ALL_FILTER_DEVICE_VALUES",
      payload: data
    })
  } catch (error) {
    console.error("getALlJobs", error)
  }
}

export async function getAllFilterDeviceDropdownValuesApi(params: IgetAllFilterDeviceDropdownList) {
  const tokenRes = await getToken();
  const response = await axios.get(
    process.env.REACT_APP_WEBAPPAPI + `/filterDropDown?org_id=${params.payload.orgId}`,
    
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response
}


export function* initiateOtaBulk(params: IinitiateOtaBulk|any) {
  try {
    const user: any = yield select((state) => state.user);
   params.initiatedBy = user.user["attributes"]["email"];
    let { data } = yield call(initiateOtaBulkApi, params)
     yield put({
      type: "STORE_GET_JOB_DETAILS_BY_JOB_ID",
      payload: data
     })
  } catch (error) {
    console.error("initiateOtaBulk", error)
  }
}

export async function initiateOtaBulkApi(params: IinitiateOtaBulk|any ) {

  const payloadWithAdditionalKey = {
    ...params.payload,   
   initiatedBy: params.initiatedBy   
  };

  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/initiateOTABulk`,
  payloadWithAdditionalKey,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.data) {
    const jobId = response.data.data; // Assuming response.data.data contains the job ID
    const successMessage = `Job ID ${jobId} has been created successfully`
    message.success({
      content: successMessage,
      duration:5,
  });
  }
  return response.data
}


export function* bulkDeviceRegistration(params: IBulkDeviceRegistration) {
  try {
    let { data } = yield call(bulkDeviceRegistrationApi, params)
    // yield put({
    //   type: "STORE_GET_JOB_DETAILS_BY_JOB_ID",
    //   payload: data
    // })
  } catch (error) {
    console.error("bulkDeviceRegistration", error)
  }
}

export async function bulkDeviceRegistrationApi(params: IBulkDeviceRegistration) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/bulkDeviceRegistration`,
    params.payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.data) {
    message.success(response.data.data)
  }
  return response.data
}



export function* createThingGroup(params: ICreateThingGroup) {
  try {
    let { data } = yield call(createThingGroupApi, params)
    // yield put({
    //   type: "STORE_GET_JOB_DETAILS_BY_JOB_ID",
    //   payload: data
    // })
  } catch (error) {
    console.error("createThingGroup", error)
  }
}

export async function createThingGroupApi(params: ICreateThingGroup | any) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/createThingGroup`,
    {
      name: params.payload.name,
      description: params.payload.description,
      thingToThingGroupModel: {
        thingGroupArn: null,
        things: params.payload.thingsArray
      },
      org_id:params.payload.orgId
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.data) {
    message.success(response.data.data)
  }
  return response.data
}

export function* createDeviceGroup(params: ICreateDeviceGroup) {
  try {
    let { data } = yield call(createDeviceGroupApi, params)
    // yield put({
    //   type: "STORE_GET_JOB_DETAILS_BY_JOB_ID",
    //   payload: data
    // })
  } catch (error) {
    console.error("createThingGroup", error)
  }
}

export async function createDeviceGroupApi(params: ICreateDeviceGroup | any) {
  console.log(params , "paramscreate")
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/createDeviceGroup`,
    {
      name: params.payload.name,
      description: params.payload.description,
      // thingToThingGroupModel: {
      //   thingGroupArn: null,
      //   things: params.payload.thingsArray
      // },
      org_id:params.payload.orgId
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.data) {
    message.success(response.data.data)
  }
  return response.data
}


export function* addThingToThingGroup(params: IAddThingToThingGroup) {
  try {
    let { data } = yield call(addThingToThingGroupApi, params)
    // yield put({
    //   type: "STORE_GET_JOB_DETAILS_BY_JOB_ID",
    //   payload: data
    // })
  } catch (error) {
    console.error("addThingToThingGroup", error)
  }
}

export async function addThingToThingGroupApi(params: IAddThingToThingGroup) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/addThingToThingGroup`,
    {
      thingGroupArn: params.payload.thingGroupArn,
      things: params.payload.things,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  if (response.data.data) {
    message.success(response.data.data)
  }
  return response.data
}

export function* getAllThingGroups(params: IGetAllThingGroups | any) {
  try {
    const user: any = yield select((state) => state.user);
    params.orgId = user.user.attributes["custom:orgId"]
    let { data } = yield call(getAllThingGroupsApi, params)
    yield put({
      type: "STORE_GET_ALL_THING_GROUPS",
      payload: data
    })
  } catch (error) {
    console.error("getAllThingGroups", error)
  }
}

export async function getAllThingGroupsApi(params: IGetAllThingGroups | any) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getAllThingGroups`,
    {
      itemsPerPage: params.payload.itemsPerPage,
      nextToken: params.payload.nextToken,
      org_id: parseInt(params.orgId)
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}


export function* getThingGroupDetailsByName(params: IGetThingGroupDetailsByName) {
  try {
    let { data } = yield call(getThingGroupDetailsByNameApi, params)
    yield put({
      type: "STORE_GET_THING_GROUP_DETAIL_BY_NAME",
      payload: data
    })
  } catch (error) {
    console.error("getThingGroupDetailsByName", error)
  }
}

export async function getThingGroupDetailsByNameApi(params: IGetThingGroupDetailsByName) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getThingGroupDetailsByName`,
    {
      name: params.payload.name,
      nextToken: params.payload.nextToken,
      itemsPerPage: params.payload.itemsPerPage
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}

export function* searchAllThingGroup(params: ISearchAllThingGroup | any) {
  try {
    const user: any = yield select((state) => state.user);
    params.orgId = user.user.attributes["custom:orgId"]
    let { data } = yield call(searchAllThingGroupApi, params)
    yield put({
      type: "STORE_SEARCH_ALL_THING_GROUP",
      payload: data
    })
  } catch (error) {
    console.error("searchAllThingGroup", error)
  }
}

export function* getOTAHomePageDataFromParams(params: any) {
  try {
    const user: any = yield select((state) => state.user);
    params.orgId = user.user.attributes["custom:orgId"]
    let { data } = yield call(getDataForOTAHome, params)
    yield put({
      type: "STORE_OTA_HOME_PAGE_DATA",
      payload: data
    })
  } catch (error) {
    console.error("getOTAHomePageDataFromParams", error)
  }
}

export async function searchAllThingGroupApi(params: ISearchAllThingGroup | any) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getAllThingGroups`,
    {
      name: params.payload.name,
      nextToken: params.payload.nextToken,
      itemsPerPage: params.payload.itemsPerPage,
      org_id: parseInt(params.orgId)
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response.data
}

export function* getAllDevices(params: IGetAllDevices) {
  try {
    let { data } = yield call(getAllDevicesApi, params)
    yield put({
      type: "STORE_ALL_DEVICES",
      payload: data
    })
  } catch (error) {
    console.error("getAllDevices", error)
  }
}

export async function getAllDevicesApi(params: IGetAllDevices) {
  console.log("parammmmssssss", params)
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getComponentData`,
    [
      {
        comp_name: params.payload.comp_name,
        comp_order: params.payload.comp_order,
        data_event: params.payload.data_event,
        keys: params.payload.keys,
        values: {}
      }
    ]
    ,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response
}

export async function getDataForOTAHome(params: any) {
  const tokenRes = await getToken();
  const response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + `/getOtaDashboardData`,
    {
        org_id: params.payload.orgId,
        pageNo: params.payload.pageNo,
        pageSize: params.payload.pageSize,
        searchValue: params.payload.searchValue ? params.payload.searchValue : ""
        
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  )
  return response
}