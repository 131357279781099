export function getOldJsonFormatTopFive(dataInput:any){
    // filter labels
    let lines : any= {}
    let data : any = []

    dataInput.data.labels.map((item:any,j:any) => {
        if(j == 0){
            lines.alert1 = item
        }
        if(j == 1){
            lines.alert2 = item
        }
        if(j == 2){
            lines.alert3 = item
        }
        if(j == 3){
            lines.alert4 = item
        }
        if(j == 4){
            lines.alert5 = item
        }
    })
    dataInput.data.graph_data.map((item:any) => {
        let idata : any = {}
        idata.date = item.date
        item.alert_info.map((item:any) => {
            let a1 = getKeyByValue(lines,item.name)
            if(a1 == "alert1"){
                idata.alert1count = item.alert_count                
            }
            if(a1 == "alert2"){
                idata.alert2count = item.alert_count                
            }
            if(a1 == "alert3"){
                idata.alert3count = item.alert_count                
            }
            if(a1 == "alert4"){
                idata.alert4count = item.alert_count                
            }
            if(a1 == "alert5"){
                idata.alert5count = item.alert_count                
            }
        })
        data.push(idata)
    })
    return ({lines:lines,data:data})
}


export function getOldJsonFormatLocationWise(dataInput:any){
    // filter labels
    let lines : any= {}
    let data : any = []

    dataInput.data.labels.map((item:any,j:any) => {
        if(j == 0){
            lines.loc1 = item
        }
        if(j == 1){
            lines.loc2 = item
        }
        if(j == 2){
            lines.loc3 = item
        }
        if(j == 3){
            lines.loc4 = item
        }
        if(j == 4){
            lines.loc5 = item
        }
    })
    dataInput.data.graph_data.map((item:any) => {
        let idata : any = {}
        idata.date = item.date
        item.alert_info.map((item:any) => {
            let a1 = getKeyByValue(lines,item.name)
            if(a1 == "loc1"){
                idata.loc1count = item.alert_count                
            }
            if(a1 == "loc2"){
                idata.loc2count = item.alert_count                
            }
            if(a1 == "loc3"){
                idata.loc3count = item.alert_count                
            }
            if(a1 == "loc4"){
                idata.loc4count = item.alert_count                
            }
            if(a1 == "loc5"){
                idata.loc5count = item.alert_count                
            }
        })
        data.push(idata)
    })
    return ({lines:lines,data:data})
}



function getKeyByValue(object:any, value:any) {
  return Object.keys(object).find(key => object[key] === value);
}
