import { Dispatch } from "redux";
import {  TAlertType, TSort, TPagination, TFilter, TSearchFilter, TAlertPagination } from "../redux/models";
import { State} from "../redux/connectm-state"
export type AlertActions = "GET_ALERTS" | "UPDATE_ACTIVE_ALERT" | "UPDATE_FILTER" | "GET_ALERTS_COUNT"|"GET_ALERTS_DOWNLOAD"
export type SearchActions = "GET_SEARCH_OPTIONS"
export type RESET_MAIN_ALERTS_LOADING = "RESET_MAIN_ALERTS_LOADING"
export type RESET_ALL_API_ERROR = "RESET_ALL_API_ERROR"
export type RESET_ALERTS_STATUS_CODE = "RESET_ALERTS_STATUS_CODE"
export type GET_FILTERED_ALERTS_COUNT = "GET_FILTERED_ALERTS_COUNT"
export type UPDATE_CURRENT_ALERT = "UPDATE_CURRENT_ALERT"


export interface IUpdateCurrentAlert {
    type:"UPDATE_CURRENT_ALERT",
    payload:string
}
export interface AlertTypePayload {
    alertType: TAlertType
}
export interface AlertPayload extends AlertTypePayload {
    pagination: TPagination,
    sort: TSort,
    filter: TFilter,
    locationFilter: TFilter,
    vehicleFilter: TFilter,
    timeFrameFilter: TFilter,
    searchFilter: TSearchFilter,
    alertPagination:TAlertPagination,
    org_id:any,
    customer_id:any,
}

export interface IAlertActions {
    type: AlertActions,
    payload: AlertPayload
}
export interface IGetFilterAlertsCount {
    type:any,
    payload:any
}
export interface IDropdownFilterActions {
  type: "GET_DROPDOWN_FILTERS",
  payload: {org_id:any}
}

export function RnDAlerts(params: IAlertActions): IAlertActions {
    return {
        type: params.type,
        payload: params.payload
    }
}

export function getFilterAlertsCount(params:any) : any {
    return{
        type:params.type,
        payload:params.payload
    }
}

export function DropdownFilters(params: IDropdownFilterActions): IDropdownFilterActions {
  return {
      type: params.type,
      payload: params.payload
  }
}
export interface SearchPayload {
  frameId: string,
  pageNo: number,
  pageSize: number,
  org_id:any,
}
export interface ISearchAction {
  type: SearchActions,
  payload: SearchPayload
}

export interface IResetMainAlertsLoading {
    type:RESET_MAIN_ALERTS_LOADING,
    payload:boolean
}

export interface ISingleAlertsLoading{
    type:"RESET_SINGLE_ALERTS_LOADING",
    payload:boolean
}

export interface IResetAlertStatusCode{
    type:"RESET_ALERTS_STATUS_CODE",
    payload:any
}

export interface IClearSearchOptions{
    type:"CLEAR_SEARCH_OPTION",
    payload:any
}



export function SearchAction(params: ISearchAction): ISearchAction {
  return {
      type: params.type,
      payload: params.payload
  }
}


export function ResetMainAlertsLoading(params:any) :any {
    return{
        type:params.type,
        payload:params.payload
    }
}

export function ResetSingleAlertsLoading(params:any) :any {
    return{
        type:params.type,
        payload:params.payload
    }
}

export function ResetAlertStatucCode(params:any) :any {
    return{
        type:params.type,
        payload:params.payload
    }
}

export function clearSearchOptsion(params:any) :any {
    return{
        type:params.type,
        payload:params.payload
    }
}

export function updateCurrentAlert(params:IUpdateCurrentAlert) {
    return {
        type:params.type,
        payload:params.payload
    }
}

export interface ReduxAlertActions {
    getAlerts: (params: IAlertActions) => IAlertActions,
    getAlertsCount: (params: IAlertActions) => IAlertActions,
    getAlertsForDownload: (params: IAlertActions) => IAlertActions,
    alertTabChanged: (params: IAlertActions) => IAlertActions,
    alertFilterChanged: (params: IAlertActions) => IAlertActions,
    getDropdownFilters: (params: IDropdownFilterActions) => IDropdownFilterActions,
    getSearchOptions: (params: ISearchAction) => ISearchAction,
    resetMainAlertsLoading : (params:IResetMainAlertsLoading) => IResetMainAlertsLoading
    resetSingleAlertsLoading: (params:ISingleAlertsLoading) => ISingleAlertsLoading
    resetAlertStatusCode: (params:IResetAlertStatusCode) => IResetAlertStatusCode
    getFilterAlertCount : (params:IGetFilterAlertsCount) => IGetFilterAlertsCount
    clearSearchOptionsFromStore : (params:IClearSearchOptions) => IClearSearchOptions
    updateCurrentAlertProps: (params:IUpdateCurrentAlert) => IUpdateCurrentAlert
}

export function mapDispatchToProps(dispatch: Dispatch): ReduxAlertActions {
    return {
        getAlerts: (params: IAlertActions) => dispatch(RnDAlerts(params)),
        getAlertsCount: (params: IAlertActions) => dispatch(RnDAlerts(params)),
        getAlertsForDownload: (params: IAlertActions) => dispatch(RnDAlerts(params)),
        alertTabChanged: (params: IAlertActions) => dispatch(RnDAlerts(params)),
        alertFilterChanged: (params: IAlertActions) => dispatch(RnDAlerts(params)),
        getDropdownFilters: (params: IDropdownFilterActions) => dispatch(DropdownFilters(params)),
        getSearchOptions: (params: ISearchAction) => dispatch(SearchAction(params)),
        resetMainAlertsLoading : (params:IResetMainAlertsLoading) => dispatch(ResetMainAlertsLoading(params)),
        resetSingleAlertsLoading : (params:ISingleAlertsLoading) => dispatch(ResetSingleAlertsLoading(params)),
        resetAlertStatusCode : (params:IResetAlertStatusCode) => dispatch(ResetAlertStatucCode(params)),
        getFilterAlertCount : (params:IGetFilterAlertsCount) => dispatch(getFilterAlertsCount(params)),
        clearSearchOptionsFromStore : (params:IClearSearchOptions) => dispatch(clearSearchOptsion(params)),
        updateCurrentAlertProps: (params:IUpdateCurrentAlert) => dispatch(updateCurrentAlert(params))
    }
}

export interface ReduxAlertState {
    alerts: State['alerts'],
    alertsCount: State['alertsCount'],
    alertsDownload: State['alertsDownload'],
    dropdownFilters:State['dropdownFilters'],
    searchOptions: State["searchOptions"],
    alertClearedSuccess:State['alertClearedSuccess'],
    mainAlertsLoading:State['mainAlertsLoading'],
    userDetails:State['user'],
    currentAlertTab:State['currentTabName']
}

export function mapStateToProps(state: State): ReduxAlertState {
    return {
        alerts: state.alerts,
        alertsCount:state.alertsCount,
        alertsDownload:state.alertsDownload,
        dropdownFilters: state.dropdownFilters,
        searchOptions: state.searchOptions,
        alertClearedSuccess:state.alertClearedSuccess,
        mainAlertsLoading:state.mainAlertsLoading,
        userDetails:state.user,
        currentAlertTab:state.currentTabName
    }
}